import {
    Autocomplete,
    Box,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    TextField,
    TextField as MuiTextField,
    Typography,
} from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext, useEffect, useState } from 'react';
import { MetaDataLabel, MetaDataRow, MetaDataValue } from '../../theme/units';
import { Color } from '../../Color';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { TTags } from '../../types/MetaTypes';

const TagsOld = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TTags;

    if (!content || content.tags.length === 0) {
        return null;
    }

    return (
        <>
            <Grid
                item
                container
                direction="row"
                {...MetaDataRow}
                sx={{
                    py: 1,
                    px: 3,
                }}
            >
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Tags
                    </Typography>
                </Grid>
                <Grid
                    item
                    {...MetaDataValue}
                    // sx={{ p: 2 }}
                >
                    {content.tags.map((option: string, index: number) => (
                        <Chip
                            key={`${option}-${index}`}
                            variant="outlined"
                            label={option}
                            // {...getTagProps({ index })}
                            // color="primary"
                            deleteIcon={<i className="fa-solid fa-x fa-2xs"></i>}
                            sx={{
                                borderRadius: '2px',
                                borderColor: 'transparent',
                                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                marginRight: '4px',
                                marginBottom: '4px',
                                fontFamily: 'Nunito Sans',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '16px',
                                letterSpacing: '1.8px',
                                textTransform: 'uppercase',
                            }}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

const TagsFormOld = ({ metaField, onChange }: MetaFieldFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TTags;

    // const joinedTags = (content?.tags ?? ['']).join(' ');
    const [tags, setTags] = useState<string[]>(content?.tags ?? []);
    // const [joinedTags, setJoinedTags] = useState((content?.tags ?? []).join(' '));
    const [joinedTags, setJoinedTags] = useState('');
    // console.log('joinedTags', joinedTags);

    const addTag = (tag: string) => {
        const newSelectedTags = tags.concat([tag]);
        setTags(newSelectedTags);
    };

    const removeTag = (tag: string) => {
        const newSelectedTags = tags.filter(splitTag => splitTag !== tag);
        setTags(newSelectedTags);
    };

    useEffect(() => {
        onChange?.({
            tags,
        });
    }, [tags]);

    return (
        <Grid
            container
            sx={{
                py: 1,
                px: 3,
                // p: 2,
            }}
        >
            <FormControl fullWidth>
                <InputLabel>Tags</InputLabel>
                <Autocomplete
                    fullWidth
                    freeSolo
                    multiple
                    // inputValue={inputValue}
                    inputValue={joinedTags}
                    value={tags}
                    options={[]}
                    filterOptions={x => x}
                    onChange={(event, value, reason, details) => {
                        console.log('onChange event', event);
                        // console.log("onChange value", value);
                        // console.log("onChange reason", reason);
                        // console.log("onChange details", details);

                        switch (reason) {
                            case 'createOption':
                                // NOTE: `details.option` here is just a string
                                // @ts-ignore
                                details && addTag(details.option);
                                break;
                            case 'selectOption':
                                details && addTag(details.option);
                                break;
                            case 'removeOption':
                                details && removeTag(details.option);
                                break;
                        }
                    }}
                    onInputChange={(event, value, reason) => {
                        console.log('onInputChange event', event);
                        // console.log("onInputChange value", value);
                        // console.log("onInputChange reason", reason);

                        setJoinedTags(value);

                        switch (reason) {
                            case 'input':
                                if (value.slice(-1) === ' ') {
                                    // addTopic({ value: value.trim(), label: value });
                                    addTag(value.trim().toUpperCase());
                                    setJoinedTags('');
                                } else {
                                    setJoinedTags(value.trim().toUpperCase());
                                }

                                break;
                        }
                    }}
                    renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                // color="primary"
                                deleteIcon={<i className="fa-solid fa-x fa-2xs"></i>}
                                sx={{
                                    borderRadius: '2px',
                                    borderColor: 'transparent',
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                }}
                            />
                        ))
                    }
                    renderInput={params => {
                        return (
                            <TextField
                                {...params}
                                fullWidth
                                multiline
                                label=""
                                // placeholder="e.g. PAINTING, ABSTRACT"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off',
                                }}
                            />
                        );
                    }}
                />
            </FormControl>
        </Grid>
    );
};

const Tags = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TTags | undefined;

    if (!content || content.tags.length === 0) {
        return null;
    }

    return (
        <>
            <Grid
                item
                container
                direction="row"
                {...MetaDataRow}
                // sx={{
                //     py: 1,
                //     px: 3,
                // }}
            >
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            // p: 2,
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Tags
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue} sx={{}}>
                    {content.tags.map((option: string, index: number) => (
                        <Chip
                            key={`${option}-${index}`}
                            variant="outlined"
                            label={option}
                            // {...getTagProps({ index })}
                            // color="primary"
                            deleteIcon={<i className="fa-solid fa-x fa-2xs"></i>}
                            sx={{
                                borderRadius: '2px',
                                borderColor: 'transparent',
                                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                marginRight: '4px',
                                marginBottom: '4px',
                                fontFamily: 'Nunito Sans',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '16px',
                                letterSpacing: '1.8px',
                                textTransform: 'uppercase',
                            }}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

const TagsForm = ({ metaField, onChange }: MetaFieldFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TTags;
    // console.log('Tags', content);

    const [tags, setTags] = useState<string[]>(content?.tags ?? []);
    const [currentTag, setCurrentTag] = useState('');
    const [joinedTags, setJoinedTags] = useState('');

    const addTag = (tag: string) => {
        const newSelectedTags = tags.concat([tag]);
        setTags(newSelectedTags);
    };

    const removeTag = (tag: string) => {
        const newSelectedTags = tags.filter(splitTag => splitTag !== tag);
        setTags(newSelectedTags);
    };

    useEffect(() => {
        onChange?.({
            tags,
        });
    }, [tags]);

    return (
        <Grid
            container
            sx={{
                px: 2,
            }}
        >
            <Box
                sx={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}
            >
                <InputLabel
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '10px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        letterSpacing: '0.15em',
                        // TODO: Color.ts
                        color: darkMode ? '#E6E6E6' : '#12121A99',
                        textTransform: 'uppercase',
                    }}
                >
                    Tags
                </InputLabel>
                {tags.map((option: string, index: number) => (
                    <Chip
                        key={`${option}-${index}`}
                        variant="outlined"
                        label={option}
                        deleteIcon={<i className="fa-solid fa-xmark"></i>}
                        onDelete={() => removeTag(option)}
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '16px',
                            letterSpacing: '1.8px',
                            mr: 1,
                            py: 2.3,
                            borderRadius: '2px',
                            borderColor: 'transparent',
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                        }}
                    />
                ))}
                <MuiTextField
                    type="string"
                    value={currentTag}
                    placeholder="Add tag"
                    onChange={event => {
                        const value = event.target.value;
                        // todo: or enter
                        if (value.slice(-1) === ' ') {
                            addTag(value.trim().toUpperCase());
                            setCurrentTag('');
                            setJoinedTags('');
                        } else {
                            setCurrentTag(value);
                        }
                    }}
                    sx={{
                        '.MuiOutlinedInput-root': {
                            p: 0,
                        },
                        '.MuiOutlinedInput-input': {
                            p: 1,
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                        },
                    }}
                />
            </Box>
        </Grid>
    );
};

export { Tags, TagsForm };
