import { RefObject, useContext } from 'react';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import UserContext, { TUser } from 'src/contexts/UserContext';
import useBulkUploaderState from 'src/stores/useBulkUploaderState';
import FileDropArea from '../../general/FileDropArea';
import { adminIrcodeAccept, ircodeAccept } from 'src/util/reactDropzone';
import { Stack, Typography } from '@mui/material';
import { Color } from 'src/Color';

const UploaderFileDropArea = ({ fileDropRef }: { fileDropRef: RefObject<HTMLDivElement> }) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;
    const onDrop = useBulkUploaderState().use.onDrop();
    return (
        <FileDropArea
            dropzoneOptions={{
                onDrop,
                accept: !user?.internalAdmin ? ircodeAccept : adminIrcodeAccept,
            }}
            dragInvalidText={null}
            dragValidText={null}
            className="dashboard-uploader"
        >
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    p: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                }}
                ref={fileDropRef}
            >
                <i className="fa-light fa-images fa-2xl" style={{ color: Color.Purple }}></i>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '20px',
                        fontWeight: 400,
                        lineHeight: '28px',
                        letterSpacing: '0.01em',
                        textAlign: 'left',
                        color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                    }}
                >
                    Drop your images here or click to upload
                </Typography>
            </Stack>
        </FileDropArea>
    );
};

export default UploaderFileDropArea;
