import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext } from 'react';
import { MetaDataLabel, MetaDataValue } from '../../theme/units';
import TextField from '../general/TextField';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { Color } from '../../Color';
import { TGalleryName } from '../../types/MetaTypes';
import { nullUndefinedOrEmpty } from '../../util/string';

const GalleryName = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TGalleryName | undefined;

    if (!content || nullUndefinedOrEmpty(content?.galleryName)) {
        return null;
    }

    return (
        <>
            <Grid
                item
                container
                direction="row"
                sx={{
                    py: 1,
                    px: 3,
                }}
            >
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Museum
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue}>
                    <Typography variant="metaDataValue">{content.galleryName}</Typography>
                </Grid>
            </Grid>
        </>
    );
};

const GalleryNameForm = ({ metaField, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TGalleryName;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                justifyItems: 'center',
                px: 2,
            }}
        >
            <TextField
                label="Gallery / museum"
                placeholder="Gallery or museum name"
                value={content?.galleryName ?? ''}
                onChange={value => handleChange({ name: 'galleryName', value })}
                wrapperSx={{
                    flexGrow: 1,
                }}
            />
        </Stack>
    );
};

export { GalleryName, GalleryNameForm };
