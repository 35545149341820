import { FormEvent } from 'react';
import { Button, TextField, Box, Container, ThemeProvider } from '@mui/material';
import UserContext, { SignInResult, SignInStatus, TUser } from '../../contexts/UserContext';
import { useContext } from 'react';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { SignInForm } from './SignIn';

export interface Country {
    code: string;
    name: string;
    phone: string;
}

// https://dev.to/jorik/country-code-to-flag-emoji-a21
const getFlagEmoji = (country: Country) => {
    const codePoints = country.code
        .toUpperCase()
        .split('')
        .map((char: string) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
};

export const longLabelMap = (country: Country) =>
    country.code === '' ? '' : `${getFlagEmoji(country)} +${country.phone} ${country.name}`;
// export const shortLabelMap = (country: Country) => (country.code === '') ? '' : `${getFlagEmoji(country)} +${country.phone}`;
export const shortLabelMap = (country: Country) => (country.code === '' ? '' : `${getFlagEmoji(country)}`);

interface Props {
    toggleSignInForm: () => void;
    setErrorMessage: (message: string) => void;
    setSignInForm: (form: SignInForm) => void;
    onComplete: (status: SignInResult) => void;
    theme?: any;
}

export default function SignInWithVerificationCode({
    toggleSignInForm,
    setErrorMessage,
    setSignInForm,
    onComplete,
    theme,
}: Props) {
    const { setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { signInWithVerificationCode } = useContext(UserContext) as TUser;

    const submitVerificationCode = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const verificationCode = data.get('verificationCode') as string;

        try {
            setShowLoading(true);

            const result = await signInWithVerificationCode(verificationCode);
            console.log(result);
            switch (result.status) {
                case SignInStatus.Success:
                    onComplete(result);
                    break;
                case SignInStatus.Error:
                    // setErrorMessage(result.error?.message);
                    setErrorMessage('TODO: Show the error');
                    break;
                default:
                    break;
            }
        } catch (error) {
            setErrorMessage(error as string);
        } finally {
            setShowLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{
                        backgroundColor: '#3577cb',
                    }}
                    onClick={toggleSignInForm}
                >
                    Use email instead
                </Button>
                <Box component="form" onSubmit={submitVerificationCode} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="verificationCode"
                        label="Verification Code"
                        name="verificationCode"
                        autoComplete="off"
                        autoFocus
                    />
                    <Button type="submit" variant="irdbGradient">
                        Submit
                    </Button>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
