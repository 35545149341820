import { useCallback, useEffect, useRef } from 'react';
import { Product } from 'src/types/Product';
import { useLocalStorage } from '@uidotdev/usehooks';
import { readProductCsvFromFile } from 'src/util/file';

export default function useProducts(campaignID?: number) {
    const [products, setProducts] = useLocalStorage<Product[]>('products', []);
    const productsRef = useRef(products);

    useEffect(() => {
        productsRef.current = products;
    }, [products]);

    const loadProducts = async (file: File) => {
        const productsFromFile = await readProductCsvFromFile(file, campaignID);
        setProducts([...productsRef.current, ...productsFromFile]);
    };

    const clearProducts = useCallback(() => {
        setProducts(productsRef.current.filter(product => product.campaignId !== campaignID));
    }, [campaignID]);

    return {
        products: products.filter(product => product.campaignId === campaignID),
        loadProducts,
        clearProducts,
    };
}
