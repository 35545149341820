import { useContext } from 'react';
import { Box } from '@mui/material';
import { Color } from 'src/Color';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { Interval } from 'src/hooks/useAnalytics';
import { ResponsiveLine } from '@nivo/line';

export interface Datum {
    x: string | number;
    y: number;
    [key: string]: any;
}
export interface Serie {
    id: string | number;
    data: readonly Datum[];
    [key: string]: any;
}
interface Props {
    data: Serie[];
    interval: Interval;
}

export default function ResponsiveLineChart({ data, interval }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const isHourly = interval === Interval.Hourly;
    const hasData = data[0]?.data?.length > 0;
    let tickXValues, tickYValues;

    if (hasData) {
        // constrain y axis to 10 values at most
        const allYValues = data.flatMap(serie => serie.data.map(d => d.y));
        const maxYValue = Math.ceil(Math.max(...allYValues));
        tickYValues = Array.from(
            { length: Math.min(10, maxYValue) },
            (_, index) => (index + 1) * Math.ceil(maxYValue / 10),
        );
    }

    return (
        <Box
            style={{
                height: 300,
                width: '100%',
            }}
        >
            {hasData && (
                <ResponsiveLine
                    data={data}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: tickYValues ? tickYValues[tickYValues.length - 1] : 'auto',
                    }}
                    curve="monotoneX"
                    useMesh={true}
                    margin={{ top: 30, right: 20, bottom: isHourly ? 100 : 70, left: 50 }}
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                    fill: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    fontSize: 12,
                                    fontWeight: 400,
                                },
                            },
                        },
                        grid: {
                            line: {
                                stroke: darkMode ? 'rgba(235, 235, 245, 0.16)' : '#ddd',
                            },
                        },
                        crosshair: {
                            line: {
                                stroke: darkMode ? 'rgba(235, 235, 245, 0.16)' : '#ddd',
                            },
                        },
                    }}
                    axisLeft={{
                        tickValues: tickYValues,
                    }}
                    axisBottom={{
                        tickRotation: -45,
                        tickValues: tickXValues,
                        format: value => {
                            const date = new Date(value);
                            return isHourly ?
                                    date.toLocaleString('en-US', {
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        hour12: true,
                                    })
                                :   date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
                        },
                    }}
                    tooltip={({ point }) => {
                        const serie = data.find(item => item.id === point.serieId);
                        const imageUrl = serie?.image?.imageUrl;
                        const date = new Date(point.data.x);
                        const dateText =
                            isHourly ?
                                `${date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} at ${date.toLocaleString('en-US', { hour: 'numeric', hour12: true })}`
                            :   date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

                        return (
                            <div
                                style={{
                                    background: !darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    border: '1px solid',
                                    borderColor: darkMode ? 'rgba(235, 235, 245, 0.16)' : '#eee',
                                    padding: '.5em',
                                    fontSize: '.8rem',
                                    opacity: 0.9,
                                }}
                            >
                                {imageUrl && (
                                    <img
                                        src={imageUrl}
                                        alt="thumbnail"
                                        style={{
                                            width: 44,
                                            height: 40,
                                            objectFit: 'cover',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            marginRight: '1em',
                                            borderLeft: `4px solid ${point.color}`,
                                        }}
                                    />
                                )}
                                <b>
                                    {point.data.yFormatted} view{point.data.yFormatted === '1' ? '' : 's'}
                                </b>
                                &nbsp;on&nbsp;{dateText}
                            </div>
                        );
                    }}
                    colors={line => {
                        return line.color;
                    }}
                />
            )}
        </Box>
    );
}
