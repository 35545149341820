import { MetaType } from '../../types/MetaTypes';
import { Semaphore } from 'async-mutex';
import useApi, { Method } from 'src/hooks/useApi';
import { TProductLink } from '../../types/ProductLink';
import { Quality, shrink } from 'src/util/image';
import { AxiosError, CanceledError } from 'axios';
import { AbortError } from '../../util/AbortablePromise/AbortError';
import { MetaField } from '../../types/MetaField';
import { useContext } from 'react';
import FirebaseContext, { TFirebase } from 'src/contexts/FirebaseContext';
import { ImageID } from 'src/contexts/MediaContext';
import apiErrorMessages from 'src/constants/apiErrorMessages.json';

export const useSaveMeta = () => {
    const { upload: firebaseUpload } = useContext(FirebaseContext) as TFirebase;
    const { request } = useApi();

    const saveSemaphore = new Semaphore(3);
    const save = async (
        imageID: ImageID,
        data: MetaField[],
        onProgress?: (status: string) => void,
        abortController?: AbortController,
        twoFA?: string,
    ): Promise<void> => {
        onProgress?.('Waiting to save metadata...');
        const [value, release] = await saveSemaphore.acquire();
        onProgress?.('Saving metadata...');

        const uploadProductLinkImages = async (data: MetaField[]): Promise<MetaField[]> => {
            // Upload each image only once
            // TODO: Why do i need to specify undefined when an invalid index would yield undefined!?
            const uploadedImages: Record<string, Promise<string> | undefined> = {};

            return await Promise.all(
                data.map(async metaField => {
                    try {
                        if (metaField.metaType === MetaType.ProductLink) {
                            metaField.metaContent.links = await Promise.all(
                                (metaField.metaContent as TProductLink).links.map(async productLink => {
                                    if (productLink.upload) {
                                        if (uploadedImages[productLink.upload.file.name] === undefined) {
                                            uploadedImages[productLink.upload.file.name] = new Promise(
                                                async (resolve, reject) => {
                                                    const shrunkenFile = await shrink(
                                                        productLink.upload!.file,
                                                        Quality.High,
                                                    );
                                                    const downloadUrl = await firebaseUpload(
                                                        shrunkenFile.file,
                                                        'products',
                                                        progress => {
                                                            console.log(`${productLink.upload!.file.name} ${progress}`);
                                                        },
                                                        abortController,
                                                    );

                                                    resolve(downloadUrl);
                                                },
                                            );
                                        }

                                        productLink.imageUrl = await uploadedImages[productLink.upload.file.name]!;
                                        delete productLink.upload;
                                    }

                                    return productLink;
                                }),
                            );
                        }
                    } catch (error) {
                        console.warn(error);
                    }

                    return metaField;
                }),
            );
        };

        data = await uploadProductLinkImages(data);

        try {
            const metaArray: MetaField[] = data.map(m => {
                // JSON is valid JSON, the server should be able to handle this.
                const metaContent = JSON.stringify(m.metaContent);

                return {
                    key: m.key,
                    metaID: m.metaID ?? 0,
                    metaType: m.metaType,
                    metaContent,
                };
            });

            const response = await request({
                method: Method.POST,
                path: `/ImageMeta`,
                data: {
                    twoFA,
                    imageID,
                    metaArray,
                },
                abortController,
            });

            if (response.data.Results?.error) {
                throw new Error(response.data.Results.error);
            }
            // console.log("response", response);
        } catch (error) {
            if (error instanceof AxiosError) {
                const err = error.response?.data.Results?.error;
                if (err) {
                    const errorMessage = apiErrorMessages[err as keyof typeof apiErrorMessages];
                    if (errorMessage) {
                        throw new Error(errorMessage);
                    }
                }
            }
            if (error instanceof CanceledError) {
                throw new AbortError();
            }
            console.error(error);
            onProgress?.('Failed to save metadata...');
        } finally {
            release();
        }
    };

    return { save };
};
