import React, { useState } from 'react';
import { Stack, FormControl, TextField, Button, Dialog, Typography, DialogActions } from '@mui/material';
import AdminContext, { TAdmin } from '../../contexts/AdminContext';
import { Color } from '../../Color';

interface EditUserProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setOpenEditUserDialog: (open: boolean) => void;
    selectedUserId: number | null;
    selectedUserFBId: string;
    userName: string;
    fullName: string;
    email: string;
    phone: string;
    setSelectedUserId?: React.Dispatch<React.SetStateAction<number | null>>;
    setSelectedUserFBId?: React.Dispatch<React.SetStateAction<string>>;
    setSelectedUserPhone: React.Dispatch<React.SetStateAction<string>>;
    setSelectedUserFullName: React.Dispatch<React.SetStateAction<string>>;
    setSelectedUserEmail: React.Dispatch<React.SetStateAction<string>>;
    setSelectedUserName: React.Dispatch<React.SetStateAction<string>>;
}

export default function EditUser({
    setOpenEditUserDialog,
    selectedUserId,
    selectedUserFBId,
    userName: UserName,
    fullName: FullName,
    email: Email,
    phone: Phone,
}: EditUserProps) {
    const { editAdminProfile, analyticsRefresh } = React.useContext(AdminContext) as TAdmin;

    const [userName, setUserName] = useState(UserName);
    const [name, setName] = useState(FullName);
    const [email, setEmail] = useState(Email);
    const [phone, setPhone] = useState(Phone);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        switch (name) {
            case 'userName':
                setUserName(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            default:
                break;
        }
    };
    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleSubmit = async () => {
        if (selectedUserId !== null && selectedUserFBId) {
            await editAdminProfile(email, name, userName, phone, selectedUserFBId, selectedUserId.toString());
            setOpenEditUserDialog(false);
            handleCloseConfirmDialog();
            analyticsRefresh();
        } else {
            console.error('Handler in EditUser');
        }
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                m: 'auto',
                p: 4,
                borderRadius: '8px',
                backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
            }}
        >
            <FormControl component="form" onSubmit={handleSubmit}>
                <Typography variant="mainFont9" sx={{ color: Color.PrimaryLavender, paddingBottom: 4 }}>
                    Edit User Information
                </Typography>
                <TextField
                    label="User Name"
                    name="userName"
                    value={userName}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Name"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField label="Phone" name="phone" value={phone} onChange={handleInputChange} />
                <Button
                    onClick={handleOpenConfirmDialog}
                    sx={{
                        marginTop: 2,
                        color: Color.White,
                        backgroundColor: Color.PrimaryLavender,
                        '&:hover': { backgroundColor: Color.PalePurple },
                    }}
                >
                    Submit
                </Button>
            </FormControl>
            <Button
                onClick={() => setOpenEditUserDialog(false)}
                sx={{
                    marginTop: 2,
                    color: Color.White,
                    backgroundColor: Color.PrimaryLavender,
                    '&:hover': { backgroundColor: Color.PalePurple },
                }}
            >
                Close
            </Button>

            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        m: 'auto',
                        p: 4,
                        borderRadius: '8px',
                        backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                        boxShadow: 1,
                    }}
                >
                    <Typography sx={{ color: Color.PrimaryLavender, variant: 'webLable2', paddingBottom: 2 }}>
                        Confirm User Information
                    </Typography>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                            User Name: {userName}
                        </Typography>
                        <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                            Name: {name}
                        </Typography>
                        <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                            Email: {email}
                        </Typography>
                        <Typography variant="webLabel2" sx={{ color: Color.PrimaryLavender }}>
                            Phone: {phone}
                        </Typography>
                    </Stack>
                    <DialogActions>
                        <Button
                            onClick={handleCloseConfirmDialog}
                            sx={{ color: Color.White, backgroundColor: Color.PrimaryLavender }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            sx={{ color: Color.White, backgroundColor: Color.PrimaryLavender }}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
        </Stack>
    );
}
