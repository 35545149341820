import { useState, useContext } from 'react';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { Color } from '../../Color';

interface RemoveUserProps {
    selectedUser: UserSearchResults | null;
    onClose: () => void;
    setRemoveDialog: (open: boolean) => void;
}

export default function RemoveUser({ selectedUser, onClose, setRemoveDialog }: RemoveUserProps) {
    const { requestAdminTwoFactorAuth, removeUser } = useContext(AdminContext) as TAdmin;
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const { notify } = useContext(FeedbackContext) as TFeedback;

    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setRemoveDialog(false);
        onClose();
    };

    const handleConfirmRemove = async () => {
        if (selectedUser?.userID) {
            try {
                const twoFACode = await requestAdminTwoFactorAuth(
                    'Confirm User Deletion',
                    'Your phone number here',
                    'Confirm',
                );
                await removeUser(selectedUser?.userID, twoFACode);
                await notify('Account Locked', 'User account locked and marked for deletion');
                handleCloseConfirmDialog();
            } catch (error) {
                console.error('Error removing user:', error);
                alert('Failed to remove user.');
            }
        }
    };

    const userInfo = [
        { title: 'Username:', value: selectedUser?.userName },
        { title: 'Full Name:', value: selectedUser?.fullName },
        { title: 'Email:', value: selectedUser?.email },
        { title: 'Phone:', value: selectedUser?.phone },
        { title: 'Acct Type:', value: selectedUser?.userTypeDisplay },
        { title: 'User ID:', value: selectedUser?.userID },
        { title: 'Registered IRCODES:', value: selectedUser?.countIRCodes },
    ];

    return (
        <>
            <Box sx={{ p: 2, border: '1px solid', borderColor: Color.LightLavenderDarkMode, borderRadius: '8px' }}>
                <Stack direction="column" spacing={2} p={2}>
                    <Typography variant="h6" align="center">
                        Would you like to remove this account?
                    </Typography>
                    <Avatar src={selectedUser?.profileUrl} />
                    {userInfo.map(info => (
                        <Stack direction="row" sx={{ justifyContent: 'space-between' }} key={info.title}>
                            <Typography p={1} variant="webLabel2">
                                {info.title}
                            </Typography>
                            <Typography p={1} variant="webProfileName">
                                {info.value}
                            </Typography>
                        </Stack>
                    ))}
                    <Button onClick={handleOpenConfirmDialog} variant="webTextButton" sx={{ color: 'red' }}>
                        Remove User
                    </Button>
                    <Button onClick={handleCloseConfirmDialog} variant="webTextButton" color="primary">
                        Cancel
                    </Button>
                </Stack>
            </Box>

            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <DialogTitle>Confirm User Removal</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to remove this user?</Typography>
                    <Typography>After confirming 2FA code:</Typography>
                    <Typography>- Selected user is marked to be removed in 30 days</Typography>
                    <Typography>- Users IRCODES marked to be removed within 48 hours</Typography>
                    <Typography>- User account is locked</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} variant="webTextButton">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmRemove} variant="webTextButton" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
