import { useEffect } from 'react';
import { create } from 'zustand';

interface CameraPermissionsStore {
    hasPermission: boolean | null;
    setHasPermission: (hasPermission: boolean) => void;
}

const useCameraPermissionsStore = create<CameraPermissionsStore>()(set => ({
    hasPermission: null,
    setHasPermission: (hasPermission: boolean) => {
        set({ hasPermission });
    },
}));

const useCameraPermissions = () => {
    const hasPermission = useCameraPermissionsStore(state => state.hasPermission);
    const setHasPermission = useCameraPermissionsStore(state => state.setHasPermission);

    useEffect(() => {
        if (useCameraPermissionsStore.getState().hasPermission !== null) return;
        async function checkPermission() {
            try {
                const hasPermission = await navigator.permissions.query({ name: 'camera' as any });
                setHasPermission(hasPermission.state === 'granted');
                return;
            } catch {
                try {
                    await navigator.mediaDevices.getUserMedia({ video: true });
                    setHasPermission(true);
                } catch {
                    setHasPermission(false);
                }
            }
        }
        checkPermission();
    }, [setHasPermission]);

    return { hasPermission, setHasPermission };
};

export default useCameraPermissions;
