import { Button, Divider, Stack } from '@mui/material';
import Title from '../Title';
import ProfileInfoForm from '../../../shared/Profile/ProfileInfoForm';
import UserAvatarForm from '../../../shared/Profile/UserAvatarForm';
import ChangePasswordForm from '../../../shared/Profile/ChangePasswordForm';
import { useProfileActions, useProfileInfo } from '../../../../stores/useProfileState';
import { FormEvent, useEffect } from 'react';

export function AccountTop() {
    const { onSave, updateInfoFromCurrentUser, clearInfo } = useProfileActions();
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await onSave();
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
    };

    useEffect(() => {
        updateInfoFromCurrentUser();
        return clearInfo;
    }, []);

    return (
        <>
            <Stack direction="column" spacing={2}>
                <Title my={20}>Account details</Title>
                <Stack direction={{ xs: 'column', md: 'row' }} rowGap={1}>
                    <ProfileInfoForm id="edit-profile-form" onSubmit={handleSubmit} />
                    <UserAvatarForm width={{ xs: '100%', md: '50%' }} pl={{ xs: 0, md: 2 }} />
                </Stack>
                <Divider sx={{ mt: { xs: '0.5rem !important', md: '1rem !important' } }} />
                <Title>Change password</Title>
                <ChangePasswordForm width={{ xs: '100%', md: '50%' }} />

                {/* <Divider /> */}
                {/* <Title>Two-factor authentication</Title> */}
            </Stack>
        </>
    );
}

export function AccountBottom() {
    const { onDeleteUser } = useProfileActions();
    const isSaveDisabled = useProfileInfo(
        state =>
            !!(
                state.reauthenticationRequired &&
                !state.currentPassword &&
                (state.newPassword || state.confirmNewPassword)
            ),
    );
    return (
        <Stack direction="column" spacing={2}>
            <Button disabled={isSaveDisabled} variant="irdbGradient" type="submit" form="edit-profile-form">
                Save Changes
            </Button>
            <Button variant="irdbText" onClick={onDeleteUser}>
                Delete Account
            </Button>
        </Stack>
    );
}
