import { Grid, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext } from 'react';
import { MetaDataLabel, MetaDataValue } from '../../../../theme/units';
import TextField from '../../../general/TextField';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { Color } from '../../../../Color';
import { TPrice } from '../../../../types/MetaTypes';

const Price = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TPrice | undefined;

    if (!content) {
        return null;
    }

    return (
        <>
            <Grid item container direction="row">
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Price
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue}>
                    <Typography variant="metaDataValue" sx={{}}>
                        {content.price}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const PriceForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TPrice;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                justifyItems: 'center',
            }}
        >
            <TextField
                label="Price"
                value={content?.price ?? ''}
                onChange={value => handleChange({ name: 'price', value })}
                onCopy={
                    onCopy ?
                        () => {
                            onCopy(content);
                        }
                    :   undefined
                }
                wrapperSx={{
                    flexGrow: 1,
                }}
            />
        </Stack>
    );
};

export { Price, PriceForm };
