export const now = () => (performance || Date).now();

export interface TOperationsTimeEstimation {
    timeEstimation: Readonly<number | null>;
    isEstimatingTime: Readonly<boolean>;
    startEstimation: (operations: number) => void;
    advanceEstimation: () => void;
    stopEstimation: () => void;
    addOperations: (amount: number) => void;
    removeOperations: (amount: number) => void;
}

class OperationsTimeEstimation implements TOperationsTimeEstimation {
    private _timeEstimation: number | null = null;

    public get timeEstimation() {
        return this._timeEstimation;
    }

    public set timeEstimation(value) {
        if (this._timeEstimation !== value) {
            this._timeEstimation = value;
            this.onChanged(value);
        }
    }

    public isEstimatingTime = false;
    private _lastAdvanceTime: number | null = null;
    private _durations: number[] = [];
    private _operations = 0;
    private _intervalId: number | null = null;
    public minOperationsForEstimation: number;
    public onChanged: (timeEstimation: number | null) => void;

    constructor(onChanged: (timeEstimation: number | null) => void, minOperationsForEstimation = 3) {
        this.onChanged = onChanged;
        this.minOperationsForEstimation = minOperationsForEstimation;
        this.startEstimation = this.startEstimation.bind(this);
        this.advanceEstimation = this.advanceEstimation.bind(this);
        this.stopEstimation = this.stopEstimation.bind(this);
        this.addOperations = this.addOperations.bind(this);
        this.removeOperations = this.removeOperations.bind(this);
    }

    public startEstimation(operations: number) {
        this._operations = operations;
        this._lastAdvanceTime = now();
        if (this.isEstimatingTime) {
            console.warn('Attempted to start time estimation while it was already running.');
            if (this._intervalId) {
                window.clearInterval(this._intervalId);
                this._intervalId = null;
            }
        }
        this.isEstimatingTime = true;
    }

    public advanceEstimation() {
        if (this._operations === 0 || this._lastAdvanceTime === null) {
            console.warn('Attempted to advance time estimation before starting it.');
            return;
        }
        const currentTime = now();
        const elapsedTime = currentTime - this._lastAdvanceTime;

        this._lastAdvanceTime = currentTime;
        this._durations.push(elapsedTime);

        if (this._durations.length < this.minOperationsForEstimation) {
            return;
        }
        if (!this._intervalId) {
            this._intervalId = window.setInterval(() => {
                this.timeEstimation = !this.timeEstimation ? null : Math.max(0, this.timeEstimation - 1000);
            }, 1000);
        }

        this.removeOperations(1);
    }

    public stopEstimation() {
        this.timeEstimation = null;
        this._lastAdvanceTime = null;
        this._durations = [];
        this._operations = 0;
        if (this._intervalId) {
            window.clearInterval(this._intervalId);
            this._intervalId = null;
        }
        this.isEstimatingTime = false;
    }

    public addOperations(amount: number) {
        this._updateOperations(this._operations + amount);
    }

    public removeOperations(amount: number) {
        this._updateOperations(this._operations - amount);
    }

    private _updateOperations(newOperations: number) {
        const average = this._durations.reduce((acc, duration) => acc + duration, 0) / this._durations.length;
        const remainingOperations = newOperations;
        this.timeEstimation = average * remainingOperations;
        this._operations = remainingOperations;
    }
}

export default OperationsTimeEstimation;
