import { useEffect, useState } from 'react';
import useWebcamStream from './useWebcamStream';
import useCameraPermissions from './useCameraPermissions';

const SUPPORTS_MEDIA_DEVICES =
    'mediaDevices' in navigator && typeof navigator.mediaDevices?.enumerateDevices === 'function';

const useTorch = () => {
    const [isOn, setIsOn] = useState(false);
    const [supported, setSupported] = useState(false);
    const { stream } = useWebcamStream();
    const { hasPermission } = useCameraPermissions();

    const turnOn = () => {
        const track = stream?.getVideoTracks()[0];
        if (track) {
            track.applyConstraints({
                advanced: [{ torch: true }],
            });
            setIsOn(true);
        }
    };

    const turnOff = () => {
        const track = stream?.getVideoTracks()[0];
        if (track) {
            track.applyConstraints({
                advanced: [{ torch: false }],
            });
            setIsOn(false);
        }
    };

    const toggle = () => {
        if (isOn) {
            turnOff();
        } else {
            turnOn();
        }
    };

    useEffect(() => {
        if (!SUPPORTS_MEDIA_DEVICES || !stream || !hasPermission) return;
        try {
            const track = stream.getVideoTracks()[0];
            if (!track || !track.enabled || track.readyState !== 'live' || track.muted) return;
            // Only way that I could find that seems like it's telling if torch is supported - tested on my pc and phone
            const supported = track.getCapabilities().torch !== undefined;
            setSupported(supported);
        } catch (error) {
            console.error(error);
        }
    }, [stream, hasPermission]);

    return { isTorchSupported: supported, isTorchOn: isOn, turnOn, turnOff, toggle };
};

export default useTorch;
