import { useEffect, useRef } from 'react';

const useExpiringAction = <T extends (...args: any[]) => void>(expiry: number, action: T, onExpired: () => void) => {
    const handle = useRef<number | null>();

    useEffect(
        () => () => {
            if (handle.current) {
                window.clearTimeout(handle.current);
            }
        },
        [],
    );

    return (...args: Parameters<T>) => {
        if (handle.current) {
            window.clearTimeout(handle.current);
            handle.current = null;
        }

        action(...args);

        handle.current = window.setTimeout(() => {
            onExpired();
            handle.current = null;
        }, expiry);
    };
};

export default useExpiringAction;
