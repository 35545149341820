import TextField, { Props as TextFieldProps } from '../../general/TextField';
import React from 'react';

export type ProfileTextFieldProps = TextFieldProps & {
    label: string;
    mobileView?: boolean;
    onChange?: TextFieldProps['onChange'];
};
const mobileTextFieldSx = {
    '.MuiOutlinedInput-input': {
        py: 1.5,
    },
};

const ProfileTextField = ({ mobileView, ...rest }: ProfileTextFieldProps) => {
    return <TextField {...rest} sx={{ ...(mobileView && mobileTextFieldSx), ...rest.sx }} />;
};

export default ProfileTextField;
