import { Crop, Image, ImageOperation, Upload } from 'src/contexts/MediaContext';
import { Type } from 'src/contexts/Operation';
import useApi, { Method } from './useApi';

export interface SaveCroppedResponse {
    NextOffset: number;
    Count: number;
    Pages: number;
    Results: Crop;
}

const useSaveCropped = () => {
    const { request } = useApi();
    const saveCropped = (imageOperation: ImageOperation<Upload>, image: Image) => {
        const abortController = new AbortController();
        const promise = new Promise<ImageOperation<Crop>>(async (resolve, reject) => {
            try {
                if (!image || !imageOperation.cropped) return;
                const cropped = imageOperation.cropped;
                const body = {
                    originalImageID: image.imageID,
                    imageUrl: cropped.url,
                    imageHeight: cropped.height,
                    imageWidth: cropped.width,
                    imageSize: cropped.file.size,
                };
                const response = await request({
                    method: Method.POST,
                    path: '/IRCode/crop',
                    data: body,
                    abortController,
                });
                const data = response.data as SaveCroppedResponse;
                const operation: ImageOperation<Crop> = {
                    ...imageOperation,
                    operation: {
                        status: 'complete',
                        type: Type.Crop,
                        Pending: false,
                        Results: data.Results,
                        Completed: true,
                    },
                };
                resolve(operation);
            } catch (error) {
                reject(error);
            }
        });
        return {
            id: imageOperation.id,
            promise,
            cancel: () => abortController.abort(),
        };
    };
    return saveCropped;
};

export default useSaveCropped;
