import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../../Color';
import MediaContext, { Image, TMedia } from '../../../contexts/MediaContext';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import UserContext, { TUser } from '../../../contexts/UserContext';
import useAuth from '../../../hooks/useAuth';
import Pagination from '../../general/Pagination';
import PageHeader from './PageHeader';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import usePageTitle from 'src/hooks/usePageTitle';

const formatTime = (unixTimestamp: number) => {
    if (unixTimestamp === undefined) {
        return 'Invalid date';
    }
    const notificationDate = new Date(unixTimestamp * 1000);
    const currentDate = new Date();
    notificationDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    if (notificationDate.getTime() === currentDate.getTime()) {
        return 'TODAY';
    }
    const isCurrentYear = notificationDate.getFullYear() === currentDate.getFullYear();
    const dateOptions: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
    const formattedDate = notificationDate.toLocaleDateString(undefined, dateOptions);

    return isCurrentYear ? `${formattedDate}, ${notificationDate.getFullYear()}` : formattedDate;
};

function NoTrashDisplay() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '20px',
            }}
        >
            <Stack direction="row" spacing={2} alignItems="center">
                <Typography sx={{ fontSize: '40px' }}>
                    <i className="fa-light fa-trash" />
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '24px',
                        fontWeight: 400,
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    You have no deleted IRCODES
                </Typography>
            </Stack>
        </Box>
    );
}

interface ListItemProps {
    item: Image;
    darkMode: boolean;
    onRestore: (imageID: string) => void;
}

function ListItem({ item, darkMode, onRestore }: ListItemProps) {
    return (
        <Box
            key={item.imageID}
            sx={{
                height: { xs: 'unset', md: 100 },
                flexGrow: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: 2,
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                margin: 2,
            }}
        >
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    height: '100%',
                    alignItems: { xs: 'flex-start', md: 'center' },
                    paddingBottom: { xs: 2, md: 0 },
                    borderRadius: 2,
                }}
            >
                <Box
                    component="img"
                    sx={{
                        flexShrink: 0,
                        width: { xs: '100%', md: 140 },
                        height: { xs: 200, md: 100 },
                        borderTopLeftRadius: { xs: 0, md: '8px' },
                        borderBottomLeftRadius: { xs: 0, md: '8px' },
                        objectFit: { xs: 'contain', md: 'cover' },
                    }}
                    src={item.imageUrl}
                    alt=""
                />
                <Typography
                    sx={{
                        whiteSpace: 'pre-line',
                        paddingRight: 2,
                        paddingLeft: { xs: 2, md: 2, lg: 4 },
                        py: 2,
                    }}
                    noWrap
                    flexGrow={1}
                    maxWidth="100%"
                    maxHeight="100%"
                    variant="webHistoryTitle"
                >
                    {item.metaContent?.title || 'Untitled'}
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    maxWidth="100%"
                    spacing={4}
                    rowGap={1}
                    sx={{
                        pl: { xs: 2, md: 0 },
                        pr: { md: 4, lg: 6 },
                        flexWrap: { xs: 'wrap', md: 'nowrap' },
                    }}
                >
                    <Typography
                        whiteSpace="nowrap"
                        color={darkMode ? Color.White : Color.PrimaryDarkGrayBlue}
                        variant="webTimeNotification"
                    >
                        {formatTime(item.timeArchived)}
                    </Typography>
                    <IconButton
                        sx={{
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            onRestore(item.imageID);
                        }}
                    >
                        <i className="fa-light fa-trash-undo"></i>
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    );
}

export default function Trash() {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { fetchRemoved, restore } = useContext(MediaContext) as TMedia;
    const { user } = useContext(UserContext) as TUser;
    const [trash, setTrash] = useState<Image[]>();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { confirm, notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;

    useAuth(() => {
        if (user?.userID === undefined) {
            return;
        }
        fetchRemoved(currentPage - 1)
            .then(response => {
                setTrash(response.Results);
                setPageCount(response.Pages);
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsLoading(false);
            });
    }, [user, currentPage]);

    const handleRestore = useCallback(
        (imageID: string) => {
            confirm({
                title: 'Restore IRCODE',
                message: 'Are you sure you want to restore this IRCODE?',
                yes: 'Restore',
                no: 'Cancel',
            })
                .then(confirmed => {
                    if (confirmed) {
                        setShowLoading(true);
                        restore(imageID)
                            .then(() => {
                                setTrash(prevTrash => {
                                    if (!prevTrash) return prevTrash;
                                    return prevTrash.filter(item => item.imageID !== imageID);
                                });
                                notify('Image restored successfully');
                            })
                            .catch(error => {
                                console.error(error);
                            })
                            .finally(() => {
                                setShowLoading(false);
                            });
                    }
                })
                .catch(error => console.error(error));
        },
        [restore, confirm, notify, setShowLoading],
    );

    usePageTitle('Trash');

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                flexGrow: 1,
                p: 4,
                height: '100%',
            }}
        >
            <Grid item xs={12}>
                <PageHeader title="Trash" />
            </Grid>

            <Box
                sx={{
                    px: 2,
                    pt: 4,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                    height: '100%',
                }}
            >
                {isLoading ?
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress
                            sx={{
                                m: 4,
                                color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            }}
                        />
                    </Box>
                : trash?.length === 0 ?
                    <NoTrashDisplay />
                :   trash?.map(item => (
                        <ListItem key={item.imageID} item={item} darkMode={darkMode} onRestore={handleRestore} />
                    ))
                }
            </Box>

            {pageCount > 1 && (
                <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(event, page) => {
                        setCurrentPage(page);
                        window.scrollTo(0, 0);
                    }}
                />
            )}
        </Stack>
    );
}
