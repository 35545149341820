import { Stack, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Chip, Type } from '../../general/Chip';
import { Image } from '../../../contexts/MediaContext';
import { IconButton } from '../../general/IconButton';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { useContext } from 'react';
import { Color } from '../../../Color';
import { transferInProcess } from '../../../util/irdbImage';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';

interface Props {
    image: Image;
    onClick?: () => void;
}

export default function IrcodeRow({ image, onClick }: Props) {
    const navigate = useNavigate();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { darkMode } = useContext(ThemeContext) as TTheme;

    return (
        <Stack
            key={image.imageID}
            direction="row"
            spacing={2}
            sx={{
                alignItems: 'center',

                // maxHeight: {
                //     // xs: 500,
                //     xs: 'auto',
                //     sm: 140,
                // },
            }}
            onClick={onClick}
        >
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{
                    flexGrow: 1,
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    paddingBottom: { xs: 2, sm: 0 },
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        flexShrink: 0,
                        width: { xs: '100%', sm: 140 },
                        height: { xs: 200, sm: 140 },
                        borderTopLeftRadius: { xs: '8px', sm: '8px' },
                        borderTopRightRadius: { xs: '8px', sm: 0 },
                        borderBottomLeftRadius: { xs: 0, sm: '8px' },
                        borderBottomRightRadius: { xs: 0, sm: 0 },
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: { xs: '100%', sm: 140 },
                            height: { xs: 200, sm: 140 },
                            // objectFit: 'cover',
                            objectFit: 'contain',
                            ...(image.imageStatus === 'draft' ? { filter: 'saturate(50%) brightness(70%)' } : {}),
                        }}
                        src={image.imageUrl}
                        alt=""
                    />
                    {image.imageStatus === 'draft' && (
                        <Chip
                            label="Draft"
                            type={Type.Warning}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                // TODO: This is not a warning, make a type for this?
                                borderWidth: 1,
                                borderColor: '#fff',
                                backgroundColor: '#838083',
                            }}
                        />
                    )}
                    {transferInProcess(image) && (
                        <Chip
                            label="Pending transfer"
                            type={Type.Warning}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                // borderWidth: 1,
                                // borderColor: '#fff',
                                // backgroundColor: '#838083',
                            }}
                        />
                    )}
                </Box>

                <Stack
                    direction="column"
                    sx={{
                        flexGrow: 1,
                        alignItems: 'flex-start',
                        paddingX: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '20px',
                            fontWeight: 400,
                            lineHeight: '28px',
                            letterSpacing: '0.01em',
                            wordBreak: 'break-word',
                            // lineClamp: 3,
                            // TODO: No ellipsis?
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            maxHeight: '84px',
                        }}
                    >
                        {image.metaContent?.title ?? 'Untitled'}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            letterSpacing: '0.15em',
                        }}
                    >
                        {image.countViews} view{image.countViews === 1 ? '' : 's'}
                    </Typography>
                    {/* <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: '16px',
                            letterSpacing: '0.15em',

                            color: '#CB0062',
                        }}
                    >Secure & Verify renewing in 4 days</Typography>                                                 */}
                </Stack>

                <Box
                    sx={{
                        paddingX: 2,
                    }}
                >
                    {/* <Chip
                        label="Verify"
                        type={Type.Warning}
                        icon = {<i style={{color:Color.White}} className="fa-regular fa-triangle-exclamation"/>}
                    />
                    <Chip
                        label="NFT"
                        // type={Type.Warning}
                    /> */}
                </Box>
                <Stack
                    direction={{ xs: 'row', sm: 'column', md: 'row' }}
                    spacing={1}
                    sx={{
                        paddingX: 2,
                    }}
                >
                    {false && (
                        <>
                            {/* <IconButton
                                icon="fa-chart-line"
                                onClick={ () => {
                                    navigate(`/dashboard/myircodes/${image.imageID}#analytics`);
                                    window.scrollTo(0, 0);
                                } }
                            /> */}
                            <IconButton
                                icon="fa-pen"
                                onClick={() => {
                                    navigateAndScrollToTop(`/dashboard/myircodes/${image.imageID}#information`);
                                }}
                            />
                            <IconButton
                                icon="fa-link"
                                onClick={() => {
                                    navigateAndScrollToTop(`/dashboard/ircodes/${image.imageID}`);
                                }}
                            />
                        </>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
}
