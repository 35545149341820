import {
    getLinksErrorsGeneric,
    validateAndNormalizeUrl,
    LinkErrors,
    LinksListErrors,
    LinkListValidatorOptions,
} from './linkUtils';
import { ValidationResult } from '../../types/MetaValidation';
import { IProductLink, TProductLink } from '../../types/ProductLink';

export type IProductLinkErrors = LinkErrors<IProductLink>;
export type TProductLinkErrors = LinksListErrors<IProductLink>;
export type TProductLinkValidatorOptions = LinkListValidatorOptions<IProductLink>;

function getProductLinkErrors(
    productLink: IProductLink,
    fields?: Array<keyof IProductLink>,
): ValidationResult<IProductLink, IProductLinkErrors> {
    const results = { ...productLink };
    const productLinkError: IProductLinkErrors = {};
    let isInvalid = false,
        validateImage = true,
        validateTitle = true,
        validateLink = true;

    if (fields) {
        validateImage = fields.includes('imageUrl') || fields.includes('upload');
        validateTitle = fields.includes('title');
        validateLink = fields.includes('linkToFollow');
    }

    if (validateImage && productLink.imageUrl === '' && productLink.upload?.file === undefined) {
        productLinkError.imageUrl = 'Image is required';
        isInvalid = true;
    }
    if (validateTitle && productLink.title === '') {
        productLinkError.title = 'Title is required';
        isInvalid = true;
    }
    if (validateLink) {
        const linkToFollowValidation = validateAndNormalizeUrl(productLink.linkToFollow);
        if (linkToFollowValidation.errors) {
            productLinkError.linkToFollow = linkToFollowValidation.errors;
            isInvalid = true;
        } else {
            results.linkToFollow = linkToFollowValidation.results!;
        }
    }
    if (isInvalid) {
        return { errors: productLinkError };
    }
    return { results };
}

function shouldFilterAndRemoveProductLink(link: IProductLink, _: LinkErrors<IProductLink>): boolean {
    return !link.title && !link.linkToFollow && !(link.imageUrl || link.upload);
}

export function getProductLinksErrors(
    productLink: TProductLink,
    options?: TProductLinkValidatorOptions,
): ValidationResult<TProductLink, TProductLinkErrors> {
    return getLinksErrorsGeneric(productLink, getProductLinkErrors, shouldFilterAndRemoveProductLink, options);
}
