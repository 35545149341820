import { Grid, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext, useState } from 'react';
import { MetaDataLabel, MetaDataRow, MetaDataValue } from '../../../../theme/units';
import TextField from '../../../general/TextField';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { Color } from '../../../../Color';
import { TDescription } from '../../../../types/MetaTypes';

const Description = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TDescription | undefined;
    const [showMore, setShowMore] = useState(false);

    if (!content) {
        return null;
    }

    const showLabel = false;
    const cutOff = 250;
    const wiggleRoom = 50;

    return (
        <>
            <Grid
                item
                container
                direction="row"
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
                {...MetaDataRow}
                sx={{
                    px: 0,
                }}
            >
                {showLabel && (
                    <Grid item {...MetaDataLabel}>
                        <Typography
                            variant="metaDataLabel"
                            sx={{
                                color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            }}
                        >
                            Description
                        </Typography>
                    </Grid>
                )}
                <Grid item {...MetaDataValue} xs={12}>
                    <Typography
                        variant="metaDataValue"
                        sx={{
                            // Wrapped text was busting into the padding
                            boxDecorationBreak: 'clone',
                        }}
                    >
                        {showMore || content.description.length < cutOff + wiggleRoom ?
                            content.description
                        :   `${content.description.substring(0, cutOff)}...`}
                        {content.description.length >= cutOff + wiggleRoom && (
                            <span
                                style={{
                                    cursor: 'pointer',
                                    color: Color.Purple,
                                }}
                                onClick={() => setShowMore(!showMore)}
                            >
                                {showMore ? ' See less' : ' See more'}
                            </span>
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const DescriptionForm = ({ metaField, onChange, onCopy }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TDescription;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                justifyItems: 'center',
            }}
        >
            <TextField
                label="Description"
                value={content?.description ?? ''}
                onChange={value => handleChange({ name: 'description', value })}
                onCopy={
                    onCopy ?
                        () => {
                            onCopy(content);
                        }
                    :   undefined
                }
                rows={5}
                wrapperSx={{
                    flexGrow: 1,
                }}
            />
        </Stack>
    );
};

export { Description, DescriptionForm };
