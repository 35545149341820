import { Color } from '../../Color';
import TextField, { Props as TextFieldProps } from '../general/TextField';

export default function AuthTextField(props: TextFieldProps) {
    return (
        <TextField
            {...props}
            sx={{
                '.MuiOutlinedInput-root': {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #626262',
                        borderRadius: 1,
                    },
                },
                '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #626262',
                    borderRadius: 1,
                },
                '.MuiOutlinedInput-input': {
                    border: '1px solid #626262',
                    borderRadius: 1,

                    backgroundColor: Color.PrimaryDarkGrayBlue,
                    color: Color.White,
                },
            }}
            wrapperSx={{
                // TODO: Color.ts
                '& label': { color: '#E6E6E6' },
            }}
        />
    );
}
