import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { nullUndefinedOrEmpty } from 'src/util/string';
import useAnalytics, { Event, Parameter, Timeframe } from 'src/hooks/useAnalytics';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import useOnScreen from 'src/hooks/useOnScreen';
import SortableTable from '../../../../general/SortableTable';

interface FallbackImageBoxProps {
    src?: string;
    isFoveated?: boolean;
    onClick?: () => void;
}

const FallbackImageBox = ({ src: initialSrc, isFoveated, onClick }: FallbackImageBoxProps) => {
    const [src, setSrc] = useState<string>();

    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    useEffect(() => {
        if (isVisible) {
            setSrc(initialSrc);
        }
    }, [initialSrc, isVisible]);

    if (src) {
        return (
            <Box
                component="img"
                sx={{
                    width: 140,
                    height: 140,
                    flexShrink: 0,
                    objectFit: 'cover',
                    objectPosition: '50% 100%',
                    cursor: isFoveated ? '' : 'pointer',
                }}
                src={src}
                onClick={onClick}
                onError={event => {
                    setSrc(undefined);
                }}
                loading="lazy"
            />
        );
    } else {
        return (
            <Box
                ref={ref}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexShrink: 0,
                    width: 140,
                    height: 140,
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '.75em',
                        fontWeight: '400',
                        lineHeight: '1em',
                        letterSpacing: '0em',
                    }}
                >
                    Image not found
                </Typography>
            </Box>
        );
    }
};

interface Extraction {
    // Well which is it?
    viewer_username: string;
    username: string;

    // "user_id": 13135,
    // "userId": "13135",
    keen: {
        id: string;
        timestamp: string;
        //     "created_at": "2024-05-10T14:47:02.336Z",
    };

    image_id: string;
    image_title: string;
    image_url: string;

    // "similar_images": 10,
    // "user_agent": "Mozilla/5.0 (iPhone; CPU iPhone OS 17_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
    // "tracking_id": "e709d0761c0f7e638266f8fb0830e626",
    // "time": 7.59594202041626,
    latitude: number;
    longitude: number;
    ip_geo_info: {
        province: string;
        city: string;
        country: string;
        coordinates: [number, number];
        formatted: boolean;
        //     "postal_code": "33331",
        //     "country_code": "US",
        //     "continent": "North America"
    };
    // "ip_address": "45.25.1.91",

    match: boolean;
    query_image_url: string;
}

interface Props {
    campaignID: number;
    timeframe?: Timeframe;
    timeOptionsRef: React.RefObject<HTMLElement>;
}

export default function ScanLog({ campaignID, timeframe, timeOptionsRef }: Props) {
    interface Scan {
        // link_title: string;
        // link_url: string;
        // result: number;
    }

    interface Data {
        [key: string]: any;
    }

    const { query, extraction } = useAnalytics();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();

    const [extractions, setExtractions] = useState<Extraction[]>([]);

    const fetchAnalytics = useCallback(() => {
        return new Promise<Extraction[]>((resolve, reject) => {
            extraction<Extraction>({
                event_collection: Event.ImageScanned,
                // event_collection: Event.ImageLinkOpened,
                // event_collection: Event.ImageViewed,
                timeframe,
                // interval: 'daily',
                filters: [
                    // These two must be together
                    {
                        property_name: Parameter.Match,
                        operator: 'eq',
                        property_value: true,
                    },
                    {
                        property_name: Parameter.CampaignID,
                        operator: 'eq',
                        property_value: campaignID,
                    },
                ],
                latest: 100,
            })
                .then(results => {
                    console.log('results', results);
                    resolve(results);
                })
                .catch((error: any) => {
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, query, timeframe]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        fetchAnalytics()
            .then(newExtractions => {
                newExtractions.forEach(extraction => {
                    if (nullUndefinedOrEmpty(extraction.username)) {
                        extraction.username = 'Anonymous';
                    }
                    if (nullUndefinedOrEmpty(extraction.image_title)) {
                        extraction.image_title = 'Untitled';
                    }
                    if (!extraction.ip_geo_info.formatted) {
                        const fullLocation = new Set(
                            [
                                extraction.ip_geo_info.city,
                                extraction.ip_geo_info.province,
                                extraction.ip_geo_info.country,
                            ].filter(Boolean),
                        );
                        extraction.ip_geo_info.city = Array.from(fullLocation)
                            .join(', ')
                            .replace('District of Columbia', 'DC')
                            .replace('United States', 'US')
                            .replace('United Kingdom', 'UK');
                        extraction.ip_geo_info.formatted = true;
                    }
                });
                setExtractions(newExtractions);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [fetchAnalytics, campaignID]);

    useLayoutEffect(() => {
        if (timeOptionsRef.current) {
            timeOptionsRef.current.scrollIntoView({
                behavior: window.scrollY > 500 ? 'auto' : 'smooth',
            });
        }
    }, [extractions, timeOptionsRef]);

    const columns = [
        { label: 'Scanned Image', sortValue: 'image_id', collapse: true },
        { label: 'Matched Image', sortValue: 'image_id', collapse: true },
        { label: 'Title', sortValue: 'image_title' },
        { label: 'Timestamp', sortValue: 'keen.timestamp' },
        { label: 'User', sortValue: 'username' },
        { label: 'Location', sortValue: 'ip_geo_info.city' },
    ];

    const customCellRenderers: { [key: string]: (row: Data) => React.ReactNode } = {
        'Scanned Image': extraction => (
            <FallbackImageBox
                key={'scan-' + extraction.image_url}
                src={`${extraction.query_image_url}?apikey=42`}
                isFoveated={true}
            />
        ),
        'Matched Image': extraction => (
            <FallbackImageBox
                key={'match-' + extraction.image_url}
                src={extraction.image_url}
                onClick={() => {
                    navigateAndScrollToTop(`/dashboard/ircodes/${extraction.image_id}`);
                }}
            />
        ),
        Title: extraction => extraction.image_title,
        Timestamp: extraction => DateTime.fromISO(extraction.keen.timestamp).toLocaleString(DateTime.DATETIME_SHORT),
        User: extraction => extraction.username,
        Location: extraction => (
            <span
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => {
                    const lat = extraction.latitude ?? extraction.ip_geo_info.coordinates[0];
                    const lng = extraction.longitude ?? extraction.ip_geo_info.coordinates[1];
                    if (lat && lng) {
                        // https://www.google.com/maps/@30.2481408,-97.7862656,14z
                        window.open(`https://www.google.com/maps/@${lat},${lng},14z`, '_blank');
                    }
                }}
            >
                {extraction.ip_geo_info.city}
            </span>
        ),
    };

    return <SortableTable columns={columns} rows={extractions} cellRenderers={customCellRenderers} />;
}
