import { Image, ImageOperation } from '../../contexts/MediaContext';
import { MetaType } from 'src/types/MetaTypes';
import { indexForMetaType } from '../../contexts/MetaContext';
import { createProductLink, IProductLink } from 'src/types/ProductLink';
import { createLink, ILink } from 'src/types/Link';
import { MetaField } from 'src/types/MetaField';

const nonEmptyLinkPredicate = (link: ILink) => link.title || link.url;
const nonEmptyProductLinkPredicate = (link: IProductLink) =>
    link.title || link.linkToFollow || link.imageUrl || link.upload?.preview;

const updateMetaArray = (srcMetaField: MetaField, destMetaArray: MetaField[], metaType?: MetaType) => {
    if (metaType && srcMetaField.metaType !== metaType) {
        console.log('Skipping', srcMetaField.metaType);
        return;
    }

    const srcMetaType = srcMetaField.metaType;
    const index = indexForMetaType(destMetaArray, srcMetaType);
    if (index !== -1) {
        switch (srcMetaType) {
            case MetaType.Custom:
                destMetaArray[index].metaContent = {
                    custom: {
                        ...destMetaArray[index].metaContent.custom,
                        ...srcMetaField.metaContent.custom,
                    },
                };
                break;
            case MetaType.Tags:
                destMetaArray[index].metaContent = {
                    tags: Array.from(
                        new Set([...destMetaArray[index].metaContent.tags, ...srcMetaField.metaContent.tags]),
                    ),
                };
                break;
            case MetaType.Link:
            case MetaType.ProductLink: {
                if (metaType === MetaType.ProductLink) {
                    // Filter blank ProductLinks
                    const linksToCopy = srcMetaField.metaContent.links.filter(nonEmptyProductLinkPredicate);
                    // Merge ProductLinks
                    const curr = destMetaArray[index].metaContent as { links: IProductLink[] };
                    curr.links = curr.links.filter(nonEmptyProductLinkPredicate);
                    for (const link of linksToCopy) {
                        const idx = curr.links.findIndex(l => l.linkToFollow === link.linkToFollow);
                        if (idx === -1) {
                            curr.links.push(createProductLink(link));
                        }
                    }
                    break;
                } else {
                    // Filter blank Links
                    const linksToCopy = srcMetaField.metaContent.links.filter(nonEmptyLinkPredicate);
                    const curr = destMetaArray[index].metaContent as { links: ILink[] };
                    curr.links = curr.links.filter(nonEmptyLinkPredicate);
                    for (const link of linksToCopy) {
                        const idx = curr.links.findIndex(l => l.url === link.url);
                        if (idx === -1) {
                            curr.links.push(createLink(link));
                        }
                    }
                    break;
                }
            }
            default:
                console.log('Overwriting', srcMetaField.metaType);
                destMetaArray[index].metaContent = srcMetaField.metaContent;
                break;
        }
    } else {
        console.log('Adding', srcMetaField.metaType);
        destMetaArray.push(srcMetaField);
    }
};
export const copyImageMetaData = (srcImage: Image, destImage: Image, metaType?: MetaType) => {
    const dest = { ...destImage };
    const destMetaArray = structuredClone(dest.metaArray) as MetaField[];
    srcImage.metaArray.forEach((srcMetaField: MetaField) => {
        updateMetaArray(srcMetaField, destMetaArray, metaType);
    });

    dest.metaArray = destMetaArray;
    return dest;
};

export const copyImageOperationMetaData = (
    srcImageOperation: ImageOperation<any>,
    destImageOperation: ImageOperation<any>,
    metaType?: MetaType,
) => {
    const dest: ImageOperation<any> = {
        ...destImageOperation,
        bulkOperation: { ...destImageOperation.bulkOperation! },
    };
    const destBulkOperation = dest.bulkOperation!;
    const destMetaArray = structuredClone(destBulkOperation.meta) as MetaField[];
    srcImageOperation.bulkOperation!.meta.forEach((srcMetaField: MetaField) => {
        updateMetaArray(srcMetaField, destMetaArray, metaType);
    });

    destBulkOperation.meta = destMetaArray;
    return dest;
};
