import { Box, Button, Container, Typography } from '@mui/material';
import { Color } from '../Color';
import { useContext } from 'react';
import ThemeContext, { TTheme } from '../contexts/ThemeContext';

interface Props {
    closePricing: () => void;
}

export default function Pricing({ closePricing }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Box
                sx={{
                    height: '20vh',
                    width: '100%',
                    background: 'linear-gradient(292.79deg, #8C9A09 9.08%, #128C34 92.12%)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    margin: '0',
                }}
            >
                <i
                    className="fa-regular fa-circle-xmark"
                    style={{
                        color: Color.White,
                        position: 'absolute',
                        top: '1rem',
                        right: '1rem',
                        cursor: 'pointer',
                    }}
                    onClick={closePricing}
                ></i>
                <Typography variant="mainFont8">Information Published</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <i className="fas fa-check" style={{ color: Color.White, fontSize: '1rem' }}></i>
                    <Typography variant="mainFont9" sx={{ color: Color.White }}>
                        IRCODE Protected
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    flexGrow: 1,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontWeight: 400,
                            lineHeight: '1rem',
                            letterSpacing: '0.15em',
                            textAlign: 'left',
                            fontSize: '0.75rem',
                        }}
                    >
                        DON'T MISS OUT
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nocturne Serif',
                            fontWeight: 400,
                            lineHeight: '1.625rem',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            fontSize: '1.5rem',
                            color: Color.Black,
                        }}
                    >
                        Secure and verify your IRCODE
                    </Typography>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: 'fit-content',
                        padding: '2rem',
                        borderRadius: '0.5rem',
                        border: '0.09375rem solid rgba(128, 89, 227, 1)',
                        gap: '1.5rem',
                        background: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                    }}
                >
                    <Typography variant="mainFont6" sx={{ color: Color.Black }}>
                        $5/Year
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <i className="fas fa-check" style={{ color: Color.Black, fontSize: '1rem' }}></i>
                        <Typography variant="mainFont9" sx={{ color: Color.Black }}>
                            Verify your IRCODE
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <i className="fa-solid fa-shield" style={{ color: Color.Black, fontSize: '1rem' }}></i>
                        <Typography variant="mainFont9" sx={{ color: Color.Black }}>
                            Prevent Unauthorized access
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <i className="fa-solid fa-key" style={{ color: Color.Black, fontSize: '1rem' }}></i>
                        <Typography variant="mainFont9" sx={{ color: Color.Black }}>
                            Securly transfer your IRCODE
                        </Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: 'fit-content',
                        padding: '2rem',
                        borderRadius: '0.5rem',
                        border: '0.09375rem solid rgba(128, 89, 227, 1)',
                        gap: '1.5rem',
                        background: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                    }}
                >
                    <Typography variant="mainFont6" sx={{ color: Color.Black, fontSize: '1.5rem' }}>
                        $2 with Pro account
                    </Typography>
                    <Button variant="irdbText">Learn More</Button>
                    <i className="fa-solid fa-arrow-right"></i>
                </Box>

                <Box
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <i className="fa-solid fa-tag"></i>
                    <Button variant="irdbText">ENTER REFERRAL CODE</Button>
                </Box>
            </Box>
        </Container>
    );
}
