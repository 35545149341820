import {
    Autocomplete,
    Box,
    Button,
    Chip,
    IconButton as MuiIconButton,
    Link as MuiLink,
    Stack,
    StackProps,
    TextField as MuiTextField,
} from '@mui/material';
import { forwardRef, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import DragDropHandle from 'src/components/general/DragDropHandle';
import DragDropList from 'src/components/general/DragDropList';
import FileDropArea from 'src/components/general/FileDropArea';
import { IconButton } from 'src/components/general/IconButton';
import { reorder, replaceItem } from 'src/util/array';
import { imageAccept } from 'src/util/reactDropzone';
import { Color } from '../../../../Color';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { createProductLink, IProductLink, isTProductLink, TProductLink } from '../../../../types/ProductLink';
import { nullUndefinedOrEmpty } from '../../../../util/string';
import TextField from '../../../general/TextField';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { Product } from 'src/types/Product';
import { useImageFetcher } from 'src/hooks/useImageFetcher';
import FirebaseContext, { TFirebase } from '../../../../contexts/FirebaseContext';
import HelperTextBox from '../../../general/HelperTextBox';
import { MetaType } from '../../../../types/MetaTypes';
import { validateMetaField } from '../../../../contexts/MetaContext';
import { IProductLinkErrors } from '../../../../util/metaValidation/productLink';
import useScopeMetaErrors, { useScopeMetaErrorsActions } from '../../../../hooks/metaErrors/useScopeMetaErrors';
import useEnsureIds from '../../../../hooks/useEnsureIds';

export const DefaultProductImageUrl =
    'https://firebasestorage.googleapis.com/v0/b/ircode-1a662.appspot.com/o/campaigns%2Fdownload.jpeg?alt=media&token=1c4d62de-4308-424f-b4ee-2c0679285957';

const ProductLink = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { productStores } = useContext(FirebaseContext) as TFirebase;
    const content = metaField?.metaContent as TProductLink | undefined;

    if (!content) {
        return null;
    }

    const getStoreImage = (url: string) => {
        const domain = new URL(url).hostname;
        const store = productStores.find(store =>
            // check if the product's hostname matches any known store domains, avoiding partial mismatches or subdomains/www
            store.domains.some(domainRegex => new RegExp(`(^|\\.)${domainRegex}`).test(domain)),
        );
        return (
            store ?
                darkMode ? store.buyBtnDark
                :   store.buyBtnLight
            :   ''
        );
    };

    return (
        <Stack spacing={1} sx={{ maxWidth: '26em' }}>
            {(isTProductLink(content) ? content.links : []).map(link => {
                let url: URL | undefined;
                try {
                    if (!nullUndefinedOrEmpty(link.linkToFollow) && !/^https?:\/\//i.test(link.linkToFollow)) {
                        link.linkToFollow = 'https://' + link.linkToFollow;
                    }

                    url = new URL(link.linkToFollow);
                } catch (error) {
                    // console.error('Error creating URL:', error);
                }

                if (!url) {
                    return null;
                }

                return (
                    <Box key={url.href}>
                        <MuiLink
                            href={url.href}
                            target="_blank"
                            rel="noreferrer noopener"
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <Chip
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: darkMode ? Color.White : Color.Black,
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                    borderRadius: '.4em',
                                    border: '1px solid rgba(0, 0, 0, 0.05)',
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: '100%',
                                    height: 'auto',
                                    ...{
                                        '.MuiChip-label': {
                                            display: 'block',
                                            px: 0,
                                            pr: 2,
                                        },
                                    },
                                }}
                                label={
                                    <Stack direction="row" sx={{ alignItems: 'center', minHeight: 80 }}>
                                        {link.imageUrl && (
                                            <img
                                                src={link.imageUrl}
                                                alt={link.title}
                                                style={{
                                                    background: 'white',
                                                    width: 80,
                                                    height: 80,
                                                    objectFit: 'contain',
                                                    flexShrink: 0,
                                                }}
                                            />
                                        )}
                                        <span style={{ flexGrow: 1, padding: '0 1em', whiteSpace: 'normal' }}>
                                            {!nullUndefinedOrEmpty(link.title) ? link.title : url.hostname}
                                        </span>
                                        <div
                                            style={{
                                                flexShrink: 0,
                                                width: 90,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {getStoreImage(link.linkToFollow) ?
                                                <img
                                                    src={getStoreImage(link.linkToFollow)}
                                                    alt={link.linkToFollow}
                                                    style={{ width: '100%', height: 50, objectFit: 'contain' }}
                                                />
                                            :   <>
                                                    Buy on
                                                    <br />
                                                    {new URL(link.linkToFollow).hostname.replace('www.', '')}
                                                </>
                                            }
                                        </div>
                                    </Stack>
                                }
                            />
                        </MuiLink>
                    </Box>
                );
            })}
        </Stack>
    );
};

interface ProductLinkFormItemProps extends StackProps<'div'> {
    disabled?: boolean;
    darkMode?: boolean;
    link: IProductLink;
    products: Product[];
    onLinkChange: (link: IProductLink) => void;
    onLinkCopy?: (link: IProductLink) => void;
    handle?: ReactNode;
    onDeleteLink: () => void;
    errors?: IProductLinkErrors;
    onValidate?: (link: IProductLink, field: keyof IProductLink) => void;
}

const ProductLinkFormItem = forwardRef<HTMLDivElement, ProductLinkFormItemProps>(function ProductLinkFormItem(
    {
        disabled = false,
        handle,
        darkMode,
        link,
        products = [],
        onLinkCopy: onCopy,
        onLinkChange: onChange,
        onDeleteLink,
        errors,
        onValidate,
        ...rest
    },
    ref,
) {
    const productRef = useRef<HTMLDivElement>(null);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const { fetchImage } = useImageFetcher();
    const linkDidBlur = useRef(false);
    const { removeError } = useScopeMetaErrorsActions();

    useEffect(
        () => {
            if (selectedProduct) {
                removeError(MetaType.ProductLink);
                onChange({
                    ...structuredClone(link),
                    title: 'Loading...',
                    linkToFollow: 'Loading...',
                });
                fetchImage(selectedProduct.imageUrl, crypto.randomUUID()).then(file => {
                    onChange({
                        ...structuredClone(link),
                        title: selectedProduct.name,
                        linkToFollow: selectedProduct.url,
                        upload: { file, preview: URL.createObjectURL(file) },
                    });
                });
            }
        },
        // If we include link, this gets called infinitely
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedProduct],
    );

    useEffect(() => {
        // TODO: Make this less hacky
        // This is to automatically increment to the next product when a new Product Link is added
        const prevRowInput = (productRef.current?.parentElement?.previousSibling as HTMLElement)?.querySelector(
            '.MuiAutocomplete-input',
        ) as HTMLInputElement;
        if (prevRowInput) {
            const lastValue = parseInt(prevRowInput.value.split('.')[0]);
            if (!isNaN(lastValue) && lastValue < products.length) {
                setSelectedProduct(products[lastValue]);
            }
        }
    }, []);

    const onDrop = (files: File[]) => {
        const file = files[0];
        if (file) {
            const newLink = {
                ...structuredClone(link),
                upload: {
                    file,
                    preview: URL.createObjectURL(file),
                },
            };
            onChange(newLink);
            onValidate?.(newLink, 'upload');
        }
    };

    const imgSrc =
        link.upload ? link.upload.preview
        : link.imageUrl ? link.imageUrl
        : DefaultProductImageUrl;

    return (
        <Stack
            ref={ref}
            direction="row"
            {...rest}
            spacing={1}
            sx={{
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                ...rest.sx,
            }}
            className="product-link-form"
        >
            <Box
                sx={{
                    minWidth: 4,
                    flexBasis: 4,
                    backgroundColor: darkMode ? 'rgba(1, 1, 1, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                }}
            />
            {handle}
            <Stack
                direction="row"
                sx={{
                    flexGrow: 1,
                    flexWrap: 'wrap',
                    p: 1,
                }}
                ref={productRef}
            >
                {products.length > 0 && (
                    <Autocomplete
                        options={products}
                        getOptionLabel={product => `${product.index + 1}. ${product.name}`}
                        isOptionEqualToValue={(option, value) => option.index === value.index}
                        value={selectedProduct || null}
                        autoHighlight
                        disableListWrap
                        onChange={(event, value) => {
                            if (!value) {
                                setSelectedProduct(undefined);
                            } else {
                                setSelectedProduct(value);
                            }
                        }}
                        renderOption={(props, product) => {
                            const { ...optionProps } = props;
                            return (
                                <Stack
                                    component="li"
                                    direction="row"
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '1em',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: '1em',
                                        p: 1,
                                        '&.Mui-selected': {
                                            backgroundColor: 'auto',
                                        },
                                    }}
                                    {...optionProps}
                                >
                                    <span style={{ opacity: '.5' }}>{product.index + 1}.</span>
                                    <span style={{ flexGrow: 1, padding: '0 .5em' }}>{product.name}</span>
                                    <img
                                        loading="lazy"
                                        src={product.imageUrl}
                                        style={{
                                            display: 'block',
                                            width: '32px',
                                            height: '32px',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Stack>
                            );
                        }}
                        renderInput={params => (
                            <MuiTextField
                                {...params}
                                label="Select a Product"
                                sx={{
                                    '.MuiInputLabel-formControl': {
                                        lineHeight: '.9em',
                                    },
                                    '.MuiAutocomplete-inputRoot': {
                                        p: 0.4,
                                    },
                                    '.MuiOutlinedInput-input': {
                                        p: 1,
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '1em',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: Color.Purple,
                                    },
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                        )}
                        sx={{
                            width: '100%',
                            my: 1,
                        }}
                    />
                )}
                <HelperTextBox helperText={errors?.imageUrl} error={!!errors?.imageUrl}>
                    <Box
                        sx={{
                            position: 'relative',
                            width: { xs: '100%', md: 150, lg: 200 },
                            height: { xs: 'auto', md: 150, lg: 200 },
                            aspectRatio: '1 / 1',
                            mr: 1,
                        }}
                    >
                        <FileDropArea
                            dropzoneOptions={{
                                onDrop,
                                accept: imageAccept,
                                disabled: disabled || !!selectedProduct,
                                multiple: false,
                                onFileDialogCancel: () => {
                                    onValidate?.(link, 'upload');
                                },
                            }}
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <>
                                <Box
                                    component="img"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                    }}
                                    src={imgSrc}
                                    alt=""
                                />
                                {!disabled && !selectedProduct && (
                                    <IconButton
                                        disabled={disabled || !!selectedProduct}
                                        sx={{
                                            position: 'absolute',
                                            // fontSize: '1rem',
                                            bottom: '1rem',
                                            right: '1rem',
                                        }}
                                        icon="fa-pen"
                                    />
                                )}
                            </>
                        </FileDropArea>
                    </Box>
                </HelperTextBox>
                <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                        flexGrow: 1,
                        p: 1,
                        width: { xs: '100%', xl: 'auto' },
                    }}
                >
                    <TextField
                        disabled={disabled || !!selectedProduct}
                        label="Product Name"
                        value={link.title}
                        onChange={value => {
                            const newLink = { ...structuredClone(link), title: value };
                            onChange(newLink);
                            onValidate?.(newLink, 'title');
                        }}
                        onBlur={() => onValidate?.(link, 'title')}
                        helperText={errors?.title}
                        error={!!errors?.title}
                    />
                    <TextField
                        disabled={disabled || !!selectedProduct}
                        label="URL"
                        value={link.linkToFollow}
                        onChange={value => {
                            const newLink = { ...structuredClone(link), linkToFollow: value };
                            onChange(newLink);
                            if (linkDidBlur.current) {
                                onValidate?.(newLink, 'linkToFollow');
                            }
                        }}
                        onBlur={() => {
                            linkDidBlur.current = true;
                            onValidate?.(link, 'linkToFollow');
                        }}
                        helperText={errors?.linkToFollow}
                        error={!!errors?.linkToFollow}
                    />
                </Stack>
            </Stack>
            <Stack
                direction="column"
                sx={{
                    justifyContent: 'center',
                }}
            >
                {onCopy && (
                    <MuiIconButton
                        disabled={disabled}
                        sx={{
                            fontSize: '1rem',
                        }}
                        onClick={() => {
                            onCopy(link);
                        }}
                    >
                        <i className="fa-regular fa-copy"></i>
                    </MuiIconButton>
                )}
                <MuiIconButton
                    disabled={disabled}
                    sx={{
                        width: '2em',
                        height: '2em',
                        alignSelf: 'center',
                        // fontSize: '1rem',
                    }}
                    onClick={() => onDeleteLink()}
                >
                    <i
                        className="fa-solid fa-xmark"
                        style={
                            {
                                // color: theme.palette.primary.main,
                            }
                        }
                    ></i>
                </MuiIconButton>
            </Stack>
        </Stack>
    );
});

export interface ProductLinkFormProps extends MetaFieldFormProps {
    products: Product[];
}

const ProductLinkForm = ({ metaField, onCopy, onChange, products }: ProductLinkFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TProductLink;
    const productBtnRef = useRef<HTMLButtonElement>(null);
    const { setError, removeError } = useScopeMetaErrorsActions();
    const errors = useScopeMetaErrors(state => state?.[MetaType.ProductLink]);

    const links = content?.links ?? [];
    const setLinks = (links: IProductLink[]) => {
        onChange?.({ links });
    };

    useEnsureIds(links, setLinks);

    const setLink = (index: number, value: IProductLink) => {
        const newLinks = [...links];
        newLinks[index] = value;
        setLinks(newLinks);
    };

    const onDeleteLink = (index: number) => {
        const newLinks = structuredClone(links);
        newLinks.splice(index, 1);
        setLinks(newLinks);

        if (!errors) return;
        if (newLinks.length === 0) {
            removeError(MetaType.ProductLink);
            return;
        }
        const newErrors = errors.filter(error => error.id !== links[index].id);
        if (newErrors.length !== errors.length) {
            setError(MetaType.ProductLink, newErrors);
        }
    };

    const items = isTProductLink(content) ? content.links : [];
    const isDragDropEnabled = items.length > 1;

    const handleValidate = (link: IProductLink, field: keyof IProductLink) => {
        const newLinks = replaceItem(
            [...links],
            () => link,
            item => item.id === link.id,
        );
        const { errors: fieldErrors } = validateMetaField(
            MetaType.ProductLink,
            { links: newLinks },
            { filter: [{ id: link.id, fields: [field] }], mergeErrors: errors },
        );
        if (fieldErrors) {
            setError(MetaType.ProductLink, fieldErrors);
        } else {
            removeError(MetaType.ProductLink);
        }
    };
    return (
        <>
            <DragDropList
                disabled={!isDragDropEnabled}
                items={items}
                onDragEnd={result => {
                    if (!result.destination) {
                        return;
                    }
                    const newLinks = reorder(links, result.source.index, result.destination.index);
                    setLinks(newLinks);
                }}
                listRender={(children, provided) => (
                    <Stack {...provided.droppableProps} ref={provided.innerRef} rowGap={2}>
                        {children}
                        {provided.placeholder}
                    </Stack>
                )}
                itemRender={([link, index], provided, { isDragging, isDropAnimating }) => (
                    <ProductLinkFormItem
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        errors={errors?.find(error => error.id === link.id)?.errors}
                        handle={isDragDropEnabled ? <DragDropHandle {...provided.dragHandleProps} /> : undefined}
                        disabled={isDragging || isDropAnimating}
                        darkMode={darkMode}
                        link={link}
                        products={products}
                        onLinkChange={value => setLink(index, value)}
                        onDeleteLink={() => onDeleteLink(index)}
                        onLinkCopy={onCopy && (() => onCopy({ links: [link] }))}
                        onValidate={handleValidate}
                    />
                )}
            />
            <Button
                variant="irdbGray"
                ref={productBtnRef}
                sx={{ scrollMargin: 16 }}
                onClick={() => {
                    const newLinks: IProductLink[] = [
                        ...(isTProductLink(content) ? content.links : links),
                        createProductLink(),
                    ];
                    setLinks(newLinks);
                    setTimeout(() => {
                        productBtnRef.current?.scrollIntoView({ block: 'end' });
                    }, 50);
                }}
            >
                <i className="fa-solid fa-bag-shopping"></i>
                Add Product Link
            </Button>
        </>
    );
};

export { ProductLink, ProductLinkForm };
