import React, { useContext, useEffect, useState } from 'react';
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    CircularProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import { useDebounce } from '@uidotdev/usehooks';
import { Color } from '../../Color';

interface IrcodeUserSearchProps {
    closeDialog: () => void;
    onUserSelect: (user: UserSearchResults) => void;
}

export const IrcodeUserSearch: React.FC<IrcodeUserSearchProps> = ({ closeDialog, onUserSelect }) => {
    const { fetchUserSearch } = useContext(AdminContext) as TAdmin;
    const [options, setOptions] = useState<UserSearchResults[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserSearchResults | null>(null);
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 500);
    const [isLocalLoading, setIsLocalLoading] = useState(false);

    useEffect(() => {
        const fetchOptions = async () => {
            if (debouncedInputValue) {
                setIsLocalLoading(true);
                try {
                    const response = await fetchUserSearch(debouncedInputValue, '', false);
                    if (response && response.Results.Users) {
                        setOptions(response.Results.Users);
                    }
                } finally {
                    setIsLocalLoading(false);
                }
            }
        };
        fetchOptions();
    }, [debouncedInputValue]);

    const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        setInputValue(value);
    };

    const handleSelect = (event: React.ChangeEvent<{}>, value: string | UserSearchResults | null, reason: string) => {
        if (reason === 'selectOption' && value && typeof value === 'object') {
            console.log('Selected user:', value);
            setSelectedUser(value);
            setDialogOpen(true);
        } else {
            setSelectedUser(null);
        }
    };

    const confirmSelection = () => {
        if (selectedUser) {
            onUserSelect(selectedUser);
        }
        setDialogOpen(false);
    };

    return (
        <Box sx={{ p: 2, border: '1px solid', borderColor: Color.PrimaryLavender }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h6">Search For Receiving User</Typography>

                {isLocalLoading && (
                    <CircularProgress
                        sx={{
                            alignSelf: 'center',
                        }}
                    />
                )}

                <Autocomplete
                    freeSolo
                    options={options}
                    getOptionLabel={option => (typeof option === 'string' ? option : option.userName)}
                    onInputChange={handleInputChange}
                    onChange={handleSelect}
                    renderInput={params => <TextField {...params} label="Search By Username" variant="outlined" />}
                />
                <Button variant="webTextButton" onClick={closeDialog}>
                    Cancel
                </Button>
                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    <Box sx={{ p: 2, border: '1px solid', borderColor: Color.PrimaryLavender, borderRadius: '8px' }}>
                        <Stack direction="column" spacing={2}>
                            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                                <Typography variant="webHistoryTitle">Confirm Receiving User</Typography>
                                <Avatar src={selectedUser?.profileUrl} />
                            </Stack>
                            <Typography variant="iawTable">Receiving Username: {selectedUser?.userName}</Typography>
                            <Typography variant="iawTable">Receiving Full Name: {selectedUser?.fullName}</Typography>
                            <Typography variant="iawTable">Receiving Email: {selectedUser?.email}</Typography>
                            <DialogActions>
                                <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
                                <Button onClick={confirmSelection}>Confirm</Button>
                            </DialogActions>
                        </Stack>
                    </Box>
                </Dialog>
            </Stack>
        </Box>
    );
};
