import React from 'react';
import { Box, Button, TextField, Tooltip, Select, MenuItem, InputAdornment } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

interface IrcodeSearchProps {
    searchTerms: string;
    setSearchTerms: (value: string) => void;
    searchType: 'registered' | 'scanned' | 'noMatch';
    setSearchType: (value: 'registered' | 'scanned' | 'noMatch') => void;
    handleSearch: (e: React.FormEvent<HTMLFormElement>) => void;
}

const IrcodeSearch: React.FC<IrcodeSearchProps> = ({
    searchTerms,
    setSearchTerms,
    searchType,
    setSearchType,
    handleSearch,
}) => {
    return (
        <form
            onSubmit={handleSearch}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '4rem',
                paddingTop: 4,
            }}
        >
            <Box sx={{ marginRight: 4 }}>
                <Select
                    value={searchType}
                    onChange={(e: SelectChangeEvent) =>
                        setSearchType(e.target.value as 'registered' | 'scanned' | 'noMatch')
                    }
                >
                    <MenuItem value="registered">Registered</MenuItem>
                    <MenuItem value="scanned">Scanned</MenuItem>
                    <MenuItem value="noMatch">No Match</MenuItem>
                </Select>
            </Box>
            <TextField
                placeholder="Search for IRCODE"
                value={searchTerms}
                onChange={e => setSearchTerms(e.target.value)}
                disabled={searchType === 'noMatch'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="Clear">
                                <Button disabled={searchType === 'noMatch'} onClick={() => setSearchTerms('')}>
                                    <i className="fa-solid fa-xmark" style={{ fontWeight: 400 }}></i>
                                </Button>
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        width: { xs: '100px', sm: '350px', md: '546px' },
                        height: '3rem',
                        borderRadius: '50px',
                    },
                }}
            />
        </form>
    );
};

export default IrcodeSearch;
