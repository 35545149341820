import React, { useContext } from 'react';
import UserContext, { TUser } from '../../../contexts/UserContext';
import { Collapse, Stack, StackProps } from '@mui/material';
import PasswordValidationDisplay from './PasswordValidationDisplay';
import StatefulProfileTextField from './StatefulProfileTextField';
import { useProfileInfo } from '../../../stores/useProfileState';

export interface ProfileChangePasswordSectionProps extends StackProps {
    mobileView?: boolean;
}

const CurrentPasswordField = () => {
    const show = useProfileInfo(
        state => !!(state.reauthenticationRequired && (state.newPassword || state.confirmNewPassword)),
    );
    return (
        <Collapse timeout={600} in={show} style={{ marginTop: 0 }}>
            <StatefulProfileTextField label="Current password" stateKey="currentPassword" type="password" />
        </Collapse>
    );
};

const ChangePasswordForm = ({ mobileView, ...rest }: ProfileChangePasswordSectionProps) => {
    const { user } = useContext(UserContext) as TUser;
    if (!user?.authProviders.password) {
        return null;
    }

    return (
        <Stack mt={1} rowGap={1} {...rest}>
            <CurrentPasswordField />
            <StatefulProfileTextField
                mobileView={mobileView}
                stateKey="newPassword"
                label="New Password"
                type="password"
            />
            <StatefulProfileTextField
                mobileView={mobileView}
                stateKey="confirmNewPassword"
                label="Confirm Password"
                type="password"
            />
            <PasswordValidationDisplay />
        </Stack>
    );
};

export default ChangePasswordForm;
