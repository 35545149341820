import { Grid, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext } from 'react';
import { MetaDataLabel, MetaDataValue } from '../../../../theme/units';
import TextField from '../../../general/TextField';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { Color } from '../../../../Color';
import { TCustom } from '../../../../types/MetaTypes';

const Custom = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TCustom | undefined;

    if (!content) {
        return null;
    }

    const keys = Object.keys(content.custom);

    return (
        <>
            {keys.map((key, index) => {
                return (
                    <Grid
                        key={index}
                        item
                        container
                        direction="row"
                        // {...MetaDataRow}
                    >
                        <Grid item {...MetaDataLabel}>
                            <Typography
                                variant="metaDataLabel"
                                sx={{
                                    color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                    // p: 2
                                }}
                            >
                                {key}
                            </Typography>
                        </Grid>
                        <Grid item {...MetaDataValue}>
                            <Typography
                                variant="metaDataValue"
                                sx={
                                    {
                                        // p: 2
                                    }
                                }
                            >
                                {content.custom[key]}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

const CustomForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const metaContent = metaField?.metaContent as TCustom | undefined;
    const keys = Object.keys(metaContent?.custom ?? {});

    const handleChange = (key: string, value: string) => {
        onChange?.({
            custom: {
                ...metaContent.custom,
                [key]: value,
            },
        });
    };

    const onDeleteCustom = (keyToRemove: string) => {
        if (Object.keys(metaContent.custom).length === 1) {
            onChange?.({
                custom: {},
            });
        } else {
            const newCustom = Object.keys(metaContent.custom)
                .filter(key => key !== keyToRemove)
                .reduce((obj, key) => {
                    // @ts-ignore
                    obj[key] = metaContent.custom[key];
                    return obj;
                }, {});
            onChange?.({
                custom: newCustom,
            });
        }
    };

    return (
        <Stack spacing={2}>
            {keys.map((key, index) => {
                return (
                    <TextField
                        key={index}
                        label={key}
                        value={(metaContent.custom as any)[key]}
                        onChange={value => handleChange(key, value)}
                        onCopy={
                            onCopy ?
                                () => {
                                    onCopy({
                                        custom: {
                                            [key]: (metaContent.custom as any)[key],
                                        },
                                    });
                                }
                            :   undefined
                        }
                        onDelete={() => onDeleteCustom(key)}
                        wrapperSx={{
                            flexGrow: 1,
                        }}
                    />
                );
            })}
        </Stack>
    );
};

export { Custom, CustomForm };
