import { FirebaseError } from 'firebase/app';
import firebaseAuthErrorMessages from '../constants/firebaseAuthErrorMessages.json';

export const getFBAuthErrorMessage = (error: FirebaseError): string | null => {
    if (error.code in firebaseAuthErrorMessages) {
        return firebaseAuthErrorMessages[error.code as keyof typeof firebaseAuthErrorMessages];
    }
    return null;
};

export { firebaseAuthErrorMessages };
