import React, { useContext } from 'react';
import { Button, SxProps, Theme } from '@mui/material';
import UserContext, { SignInResult, SignInStatus, TUser } from '../../contexts/UserContext';
import { Color } from '../../Color';

interface Props {
    sx?: SxProps<Theme>;
    setErrorMessage: (message?: string) => void;
    onComplete: (status: SignInResult) => void;
}

export default function GoogleSignInButton({ setErrorMessage, onComplete, sx }: Props) {
    const { signInWithGoogle } = useContext(UserContext) as TUser;

    const signIn = async () => {
        try {
            const result = await signInWithGoogle();
            console.log('result', result);
            switch (result.status) {
                case SignInStatus.Success:
                    onComplete(result);
                    break;
                case SignInStatus.Error:
                    setErrorMessage(result.error?.message);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Button
            variant="irdbDarkGray"
            sx={{
                height: '56px',
                textTransform: 'none',
                ...sx,
            }}
            onClick={signIn}
        >
            <i className="fa-brands fa-google fa-xl fa-fw" style={{ color: Color.White, marginRight: 2 }}></i>
            <span
                style={{
                    textAlign: 'left',
                    width: '10em',
                }}
            >
                Sign in with Google
            </span>
        </Button>
    );
}
