import { ValidationResult } from '../../types/MetaValidation';
import { urlRegex } from '../string';
import { dedupe } from '../array';

export type LinkErrors<T extends object> = { [key in keyof T]?: string };

export type LinksListErrors<T extends object> = Array<{
    id: string;
    errors: LinkErrors<T>;
}>;

export type LinkListValidatorOptions<T extends object> = {
    filter?: Array<{ id: string; fields?: Array<keyof T> }>;
    /** Can be passed along with filter to merge previous errors in links that were not included as part of the filter.
     * Ignored if no filter was passed.  */
    mergeErrors?: LinksListErrors<T> | null;
};

/**
 * @param linksMeta An object containing the links to validate.
 * @param getLinkErrors A function that receives a link and returns the validation result.
 * @param getShouldFilterErrorAndRemove A function that receives the link and its errors and should return true if the link should be filtered out of the list instead of returning an error for it. Only executes when validating all links (no filter was passed).
 * @param options Options to filter the links that should be validated.
 * @returns A validation result containing the filtered links or the errors for the links.
 * */
export function getLinksErrorsGeneric<T extends { id: string }>(
    linksMeta: { links: Array<T> },
    getLinkErrors: (link: T, filter?: Array<keyof T>) => ValidationResult<T, LinkErrors<T>>,
    getShouldFilterErrorAndRemove?: ((link: T, errors: LinkErrors<T>) => boolean) | null,
    options?: LinkListValidatorOptions<T>,
): ValidationResult<{ links: Array<T> }, LinksListErrors<T>> {
    const linkErrors: LinksListErrors<T> = [];
    const filter = options?.filter;
    const results: { links: T[] } = { links: [] };
    const removeIds: string[] = [];
    // Handle filtered validation
    if (filter) {
        // Cache errors ids to avoid revalidating links that already have errors
        const errorIds: string[] = [];
        const { mergeErrors } = options;
        // Iterate over the filter records and validate the corresponding links
        for (let i = 0; i < filter.length; i++) {
            const { id, fields } = filter[i];
            const link = linksMeta.links.find(link => link.id === id);
            if (!link) {
                console.warn(`Couldn't find link with id ${id}`);
                continue;
            }
            // If the link had previous errors, we need to merge them into the filter fields, so they would get revalidated
            const errorToMerge = mergeErrors?.length && mergeErrors.find(e => e.id === id);
            const newFields = fields ?? [];
            const mergedFields =
                errorToMerge ? [...newFields, ...(Object.keys(errorToMerge?.errors) as Array<keyof T>)] : newFields;
            const { results: linkResults, errors: linkError } = getLinkErrors(link, mergedFields);
            if (linkError) {
                errorIds.push(id);
                linkErrors.push({
                    id,
                    errors: linkError,
                });
            } else {
                results.links.push(linkResults!);
            }
        }
        // Handle merge errors for the links that were not in the filter
        if (mergeErrors) {
            // Filter out the errors that were caught while validating the filter
            const previousErrors = mergeErrors.filter(e => !errorIds.includes(e.id));
            // Revalidate the links that had errors but were not in the filter
            for (let i = 0; i < previousErrors.length; i++) {
                const { id, errors } = previousErrors[i];
                if (!errors) continue;
                const link = linksMeta.links.find(link => link.id === id);
                if (!link) {
                    console.warn(`Couldn't find link with id ${id}`);
                    continue;
                }
                const { results: linkResults, errors: linkError } = getLinkErrors(
                    link,
                    Object.keys(errors) as Array<keyof T>,
                );
                if (linkError) {
                    linkErrors.push({
                        id,
                        errors: linkError,
                    });
                } else {
                    results.links.push(linkResults!);
                }
            }
        }
    } else {
        // Handle validate all
        for (let i = 0; i < linksMeta.links.length; i++) {
            const link = linksMeta.links[i];
            const { results: linkResults, errors: linkError } = getLinkErrors(link);

            if (linkError) {
                if (getShouldFilterErrorAndRemove?.(link, linkError)) {
                    removeIds.push(link.id);
                } else {
                    linkErrors.push({
                        id: link.id,
                        errors: linkError,
                    });
                }
            } else {
                results.links.push(linkResults!);
            }
        }
    }
    if (removeIds.length > 0) {
        // remove links that passed getShouldFilterErrorAndRemove
        results.links = results.links.filter(link => !removeIds.includes(link.id));
    }
    if (linkErrors.length === 0) {
        return { results };
    }
    return { errors: linkErrors };
}

export const urlForbiddenCharactersRegex = /[<>{}]/g;

export const validateAndNormalizeUrl = (url: string): ValidationResult<string, string> => {
    url = url.trim();
    if (url.length === 0) {
        // is empty
        return { errors: 'URL is required' };
    } else if (!urlRegex.test(url)) {
        // is not a valid URL structure
        return { errors: 'Invalid URL' };
    } else {
        // test for forbidden characters
        const matches = Array.from(url.matchAll(urlForbiddenCharactersRegex), m => `' ${m[0]} '`);
        if (matches.length > 0) {
            // contains forbidden characters
            return { errors: `URL contains invalid characters: ${dedupe(matches).join(', ')}` };
        }
    }
    try {
        // try to return the URL
        const normalizedUrl = new URL(url);
        return { results: normalizedUrl.toString() };
    } catch {
        // try to add https://
        try {
            const normalizedUrl = new URL(`https://${url}`);
            return { results: normalizedUrl.toString() };
        } catch {
            // continue
        }
        // is not a valid URL
        return { errors: 'Invalid URL' };
    }
};
