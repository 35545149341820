import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Puller } from './Puller';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';

interface Props {
    children: React.ReactNode;
}

const Drawer = ({ children }: Props) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <Box
            sx={{
                m: 0,
                p: 0,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                // Maintains the rounded corners and allows scrolling
                overflow: 'scroll',
                backgroundColor: 'transparent',
            }}
        >
            <Box
                sx={{
                    position: 'fixed',
                    right: 0,
                    left: 0,
                    // :/
                    zIndex: 100,
                    // backgroundColor: darkMode ? Color.White : Color.White,
                }}
            >
                <Puller />
            </Box>
            {children}
        </Box>
    );
};

export default Drawer;
