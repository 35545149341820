import { memo, useContext, useMemo } from 'react';
import { Card, Stack, Typography, Box } from '@mui/material';
import { ImageOperation } from 'src/contexts/MediaContext';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { Color } from 'src/Color';
import { IconButton } from '../../general/IconButton';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import { useCopyToClipboard } from 'usehooks-ts';
import { arePropertiesEqual } from 'src/util/objects';
import { MetaType, TTitle } from '../../../types/MetaTypes';
import useBulkUploaderState from '../../../stores/useBulkUploaderState';

interface UnavailableItemProps {
    imageOperation: ImageOperation<any>;
    darkMode: boolean;
    setSuccessMessage: (message: string) => void;
    setErrorMessage: (message: string) => void;
}

const UnavailableItem = memo(
    function UnavailableItem({ imageOperation, darkMode, setSuccessMessage, setErrorMessage }: UnavailableItemProps) {
        const [, copyToClipboard] = useCopyToClipboard();
        const imgSrc = imageOperation.cropped?.preview ?? imageOperation.original?.preview;
        const url = `${window.location.origin}/dashboard/ircodes/${imageOperation.operation.Results?.Image?.imageID}`;
        const titleMeta = useMemo(
            () => imageOperation.bulkOperation?.meta.find(meta => meta.metaType === MetaType.Title),
            [imageOperation.bulkOperation?.meta],
        );
        const title = (titleMeta?.metaContent as TTitle).title ?? 'Untitled';

        return (
            <Stack
                key={imageOperation.id}
                direction="row"
                sx={{
                    flexGrow: 1,
                    alignItems: 'center',
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                    overflow: 'hidden',
                }}
                onClick={() => {
                    !imageOperation.operation.Results?.ImagePrivate && window.open(url, '_blank');
                }}
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        flex: '0 0 50%',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            flexShrink: 0,
                            width: 130,
                            height: 100,
                            objectFit: 'cover',
                        }}
                        src={imgSrc}
                        alt=""
                    />
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                            flexGrow: 1,
                            pr: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                flexGrow: 1,
                                textAlign: 'left',
                                fontFamily: 'Nunito Sans',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',
                                wordBreak: 'break-word',
                            }}
                        >
                            {title}
                            <i style={{ opacity: 0.7 }}> matched...</i>
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        flex: '0 0 50%',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            flexShrink: 0,
                            width: 130,
                            height: 100,
                            objectFit: 'cover',
                        }}
                        src={imageOperation.operation.Results?.Image?.imageUrl}
                        alt=""
                    />
                    <Stack direction="row" spacing={2} sx={{ alignItems: 'center', flexGrow: 1, pr: 2 }}>
                        <Typography
                            sx={{
                                flexGrow: 1,
                                textAlign: 'left',

                                fontFamily: 'Nunito Sans',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',

                                wordBreak: 'break-word',
                            }}
                        >
                            {imageOperation.operation.Results?.Image?.metaContent?.title ?? 'Untitled'}
                            {!imageOperation.operation.Results?.ImagePrivate && (
                                <i className="fa-solid fa-arrow-right" style={{ marginLeft: 8 }} />
                            )}
                        </Typography>
                        {imageOperation.operation.Results?.ImagePrivate ?
                            <IconButton icon="fa-lock" tooltip="Private IRCODE" sx={{ opacity: 0.5 }} />
                        :   <IconButton
                                icon="fa-link"
                                tooltip="Copy to Clipboard"
                                onClick={event => {
                                    event.stopPropagation();
                                    copyToClipboard(url)
                                        .then(() => {
                                            setSuccessMessage('Copied to clipboard');
                                        })
                                        .catch(() => {
                                            setErrorMessage('Failed to copy to clipboard');
                                        });
                                }}
                            />
                        }
                    </Stack>
                </Stack>
            </Stack>
        );
    },
    (prevProps, nextProps) =>
        arePropertiesEqual(prevProps, nextProps, 'darkMode', 'setSuccessMessage', 'setErrorMessage') &&
        arePropertiesEqual(prevProps.imageOperation, nextProps.imageOperation, 'id', 'original', 'cropped') &&
        arePropertiesEqual(prevProps.imageOperation.bulkOperation, nextProps.imageOperation.bulkOperation, 'meta') &&
        arePropertiesEqual(prevProps.imageOperation.operation, nextProps.imageOperation.operation, 'Results'),
);

const Unavailable = memo(function Unavailable() {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { setSuccessMessage, setErrorMessage } = useContext(FeedbackContext) as TFeedback;
    const imageOperations = useBulkUploaderState().use.unavailableIrcodes();

    if (imageOperations.length === 0) {
        return null;
    } else {
        return (
            <Card
                sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                }}
                elevation={0}
            >
                <Stack direction="column" spacing={2}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <i
                            className="fa-solid fa-magnifying-glass fa-2xl"
                            style={{ marginRight: '16px', color: '#ED1B7F' }}
                        ></i>
                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                fontSize: '24px',
                                fontWeight: 400,
                                lineHeight: '26px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}
                        >
                            {imageOperations.length > 1 ? `${imageOperations.length} IRCODES Found` : 'IRCODE Found'}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',
                                textAlign: 'left',
                            }}
                        >
                            {imageOperations.length > 1 ? 'These IRCODES are' : 'This IRCODE is'} already registered
                        </Typography>
                    </Stack>
                    {imageOperations.map(imageOperation => (
                        <UnavailableItem
                            key={imageOperation.id}
                            imageOperation={imageOperation}
                            darkMode={darkMode}
                            setSuccessMessage={setSuccessMessage}
                            setErrorMessage={setErrorMessage}
                        />
                    ))}
                </Stack>
            </Card>
        );
    }
});

export default Unavailable;
