import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

type useAuthHandler = (() => void) | (() => () => void);

const useAuth = (handler: useAuthHandler, dependencies?: any[]) => {
    useEffect(() => {
        const auth = getAuth();
        let cleanup: ReturnType<useAuthHandler>;
        const unsubscribe = onAuthStateChanged(auth, async user => {
            if (!user) {
                return;
            }
            cleanup = handler();
        });

        return () => {
            if (typeof cleanup === 'function') {
                cleanup();
            }
            unsubscribe();
        };
    }, dependencies ?? []);
};

export default useAuth;
