import { Chip as MuiChip, Avatar, SxProps, Theme } from '@mui/material';
import { ReactElement, useContext } from 'react';

import { Color } from '../../Color';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';

export enum Type {
    Default,
    Warning,
    ActivityUser,
    NotificationImage,
    New,
}

interface Props {
    label: string;
    color?: Color;
    type?: Type;
    avatarUrl?: string;
    icon?: ReactElement;
    sx?: SxProps<Theme>;
}

// Returns a combination of background color and text color for the given type
// TODO: More Color.ts
const colorForType = (type: Type, darkMode: boolean): [string, string] => {
    switch (type) {
        case Type.Default:
            return [
                darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                darkMode ? Color.White : Color.Black,
            ];
        case Type.Warning:
            return [Color.AnalyticsRed, Color.White];
        case Type.ActivityUser:
            return [
                darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                darkMode ? Color.White : Color.Black,
            ];
        case Type.NotificationImage:
            return [
                darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                darkMode ? Color.White : Color.Black,
            ];
        case Type.New:
            return [Color.WebNotificationGreen, Color.Black];
    }
};

export const Chip = ({
    label,
    // color,
    type = Type.Default,
    avatarUrl,
    icon,
    sx,
}: Props) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const [backgroundColor, color] = colorForType(type, darkMode);

    const avatar =
        avatarUrl ?
            <Avatar
                src={avatarUrl}
                sx={
                    type === Type.ActivityUser ?
                        {
                            borderRadius: '50%',
                            height: '28px',
                            width: '28px',
                            verticalAlign: 'middle',
                        }
                    : type === Type.NotificationImage ?
                        {
                            borderRadius: '4px',
                            height: '28px',
                            verticalAlign: 'middle',
                            margin: '-4px 0',
                        }
                    :   {}
                }
            />
        :   undefined;

    return (
        <MuiChip
            // key={option}
            component="span"
            variant="outlined"
            icon={icon}
            label={label}
            avatar={avatar}
            // <i class="fa-regular fa-triangle-exclamation"></i>
            // {...getTagProps({ index })}
            // color="primary"
            // deleteIcon={<i className="fa-solid fa-x fa-2xs"></i>}
            sx={{
                borderRadius: 1,
                borderColor: 'transparent',
                backgroundColor,
                color,
                height: 'auto',
                padding: '4px 8px',

                fontFamily: 'Nunito Sans',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '1.8px',
                textTransform: 'uppercase',

                '& .MuiChip-label': {
                    p: 0,
                    // Prevents the label from wrapping
                    whiteSpace: 'normal',
                    textAlign: 'center',
                },

                ...(type === Type.New && {
                    padding: '2px 4px',
                    fontSize: '10px',
                    letterSpacing: '0.15em',

                    // p: '0px',

                    // height: '1rem',
                    // padding: '2px 4px',
                    // marginLeft: 2,
                    // '& .MuiChip-label': {
                    //     padding: 0,
                    // },
                }),
                ...sx,
            }}
        />
    );
};
