import {
    Box,
    Button,
    Checkbox,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    LinearProgress,
    Link,
    Radio,
    Stack,
    Typography,
} from '@mui/material';
import { Color } from '../../../../Color';
import UserContext, { TUser, UserType } from '../../../../contexts/UserContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Chip, Type } from '../../../general/Chip';
import TextField from '../../../general/TextField';
import { useDropzone } from 'react-dropzone';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';

interface ProgressBarData {
    label: string;
    value: number;
    max: number;
}

const ProgressBarWithLabel: React.FC<{ data: ProgressBarData }> = ({ data }) => {
    const { label, value, max } = data;
    const normalizedValue = (value / max) * 100;

    return (
        <Stack direction="column" spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                <Typography variant="lineDataLabel" sx={{ textTransform: 'uppercase' }}>
                    {label}
                </Typography>
                <Typography variant="lineDataLabel" sx={{ textTransform: 'uppercase' }}>
                    {`${value}/${max}`}
                </Typography>
            </Stack>
            <Box sx={{ width: '100%' }}>
                <LinearProgress
                    variant="determinate"
                    value={normalizedValue}
                    sx={{
                        height: '20px',
                        borderRadius: '4px',
                        marginBottom: 4,
                        backgroundColor: '#A0D7E780',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#188BD8',
                            borderRadius: '4px',
                        },
                    }}
                />
            </Box>
        </Stack>
    );
};

interface IrcodePlanProps {
    onChangeTab: () => void;
}

export function IrcodePlan({ onChangeTab }: IrcodePlanProps) {
    // const theme = useTheme();
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;

    const progressData = [
        { label: 'IRCode Registered', value: 43, max: 100 },
        { label: 'Additional Users', value: 2, max: 3 },
    ];

    const basicPlan = [
        { icon: <i className="fa-light fa-magnifying-glass"></i>, label: 'Search and find IRCODES , worldwide' },
        { icon: <i className="fa-light fa-check"></i>, label: 'Register up to 100 images as IRCODES' },
        { icon: <i className="fa-light fa-shield-halved"></i>, label: 'Secure and verify your add-on for 5$ / month' },
    ];
    const proPlan = [
        { icon: <i className="fa-light fa-magnifying-glass"></i>, label: 'Search and find IRCODES , worldwide' },
        { icon: <i className="fa-light fa-check"></i>, label: 'Register up to 100 images as IRCODES' },
        { icon: <i className="fa-light fa-shield-halved"></i>, label: 'Secure and verify your add-on for 2$ / month' },
        { icon: <i className="fa-light fa-badge-check"></i>, label: 'Account verificatino badge' },
        { icon: <i className="fa-light fa-cubes-stacked"></i>, label: 'Bulk register 10 images at a time' },
        { icon: <i className="fa-light fa-chart-line"></i>, label: 'IRCODE Analytics for more insights' },
    ];

    const [openDialog, setOpenDialog] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [currentPlan, setCurrentPlan] = useState<UserType>(user?.userType ?? UserType.Basic);
    const [file, setFile] = useState<File | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [checked, setChecked] = useState(false);
    const [isFormComplete, setIsFormComplete] = useState(false);

    const resetForm = () => {
        setPhoneNumber('');
        setAddress('');
        setCurrentPlan(user?.userType ?? UserType.Basic);
        setSelectedFile(null);
        setChecked(false);
    };

    const toggleDialog = () => {
        if (openDialog) {
            resetForm();
        }
        setOpenDialog(!openDialog);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setSelectedFile(acceptedFiles[0]);
    }, []);

    useEffect(() => {
        if (file) {
            setIsProcessing(true);

            setTimeout(() => {
                setIsProcessing(false);
                console.log('File processing completed:', file.name);
            }, 2000);
        }
    }, [file]);

    useEffect(() => {
        const isPhoneNumberValid = !!phoneNumber.match(/\d{10}/);
        const isAddressFilled = address.trim() !== '';
        const isImageUploaded = selectedFile !== null;
        const isPlanSelected = user?.userType !== undefined;
        const isTermsChecked = checked;

        setIsFormComplete(isPhoneNumberValid && isAddressFilled && isImageUploaded && isPlanSelected && isTermsChecked);
    }, [phoneNumber, address, selectedFile, user?.userType, checked]);

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop });

    const handlePlanChange = (newPlan: UserType) => {
        setCurrentPlan(newPlan);
    };

    const truncateFileName = (fileName: string) => {
        // Might need to be adjusted based on the design
        const MAX_LENGTH = 20;
        if (fileName.length <= MAX_LENGTH) {
            return fileName;
        }

        return fileName.slice(0, Math.floor(MAX_LENGTH / 2) - 3) + '...' + fileName.slice(-Math.floor(MAX_LENGTH / 2));
    };

    return (
        <>
            <Stack direction="column" spacing={2}>
                <Typography variant="webHeadlineSmall">Your current plan</Typography>
                <Box
                    sx={{
                        padding: '20px 32px 20px 20px',
                        borderRadius: 3,
                        backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Typography variant="webHistoryTitle" sx={{ paddingBottom: 4 }}>
                        Basic Account
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" sx={{ paddingBottom: 4 }}>
                        <Typography variant="lineDataLabel" sx={{ textTransform: 'uppercase' }}>
                            Plan Cycle
                        </Typography>
                        <Typography variant="lineDataLabel" sx={{ textTransform: 'uppercase' }}>
                            Yearly
                        </Typography>
                    </Stack>
                    {progressData.map((data, index) => (
                        <ProgressBarWithLabel key={index} data={data} />
                    ))}
                    <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                        <Typography variant="proVerifyFont2" onClick={onChangeTab}>
                            MANAGE BILLING
                            <i className="fa-light fa-arrow-right"></i>
                        </Typography>
                    </Stack>
                </Box>

                <Divider></Divider>
                <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ paddingBottom: 4 }}>
                    <Typography variant="webHeadlineSmall">Change plan</Typography>
                    <Typography variant="proVerifyFont2">
                        LEARN MORE ABOUT IRCODE PLANS
                        <i className="fa-light fa-arrow-right"></i>
                    </Typography>
                </Stack>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={2}
                    sx={
                        {
                            // alignContent: 'stretch',
                            // justifyContent: 'stretch',
                        }
                    }
                    // justifyContent="space-between"
                >
                    <Box
                        sx={{
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                            borderRadius: 4,
                            p: 4,
                            width: '100%',
                            minHeight: 'fill-available',
                            border: 1,
                            borderColor: user?.userType !== UserType.Pro ? Color.PrimaryLavender : 'transparent',
                        }}
                    >
                        <Stack direction="column" spacing={2}>
                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Typography variant="proVerifyHeader">Basic</Typography>
                                {user?.userType !== UserType.Pro && (
                                    <Chip
                                        type={Type.Default}
                                        label="Current Plan"
                                        sx={{
                                            background: 'linear-gradient(106.88deg, #5B60C1 37.55%, #882EF1 76.92%)',
                                            color: Color.White,
                                        }}
                                    />
                                )}
                            </Stack>

                            {basicPlan.map((plan, index) => (
                                <Stack direction="row" spacing={2} key={index}>
                                    <Typography
                                        sx={{
                                            fontWeight: 300,
                                            fontSize: '18px',
                                            lineHeight: '24px',
                                            color: Color.PrimaryLavender,
                                        }}
                                    >
                                        {plan.icon}
                                    </Typography>
                                    <Typography variant="notificationSettingInfo">{plan.label}</Typography>
                                </Stack>
                            ))}

                            <Divider />
                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Stack direction="row" alignItems="center" spacing={0}>
                                    <Radio
                                        checked={currentPlan === UserType.Basic}
                                        onChange={() => handlePlanChange(UserType.Basic)}
                                        value={UserType.Basic}
                                        sx={{
                                            color: currentPlan === UserType.Basic ? Color.PrimaryLavender : 'default',
                                            '&.Mui-checked': {
                                                color: Color.PrimaryLavender,
                                            },
                                        }}
                                    />
                                    <Typography variant="webHistoryTitle">Basic Plan</Typography>
                                </Stack>
                                <Typography variant="webHistoryTitle">$0</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                            borderRadius: 4,
                            p: 4,
                            width: '100%',
                            height: '100%',
                            border: 1,
                            borderColor: user?.userType === UserType.Pro ? Color.PrimaryLavender : 'transparent',
                        }}
                    >
                        <Stack direction="column" spacing={2}>
                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Typography variant="proVerifyHeader">Pro</Typography>
                                {user?.userType === UserType.Pro || user?.userType === UserType.ProYearly ?
                                    <Chip
                                        type={Type.Default}
                                        label="Current Plan"
                                        sx={{
                                            background: 'linear-gradient(106.88deg, #5B60C1 37.55%, #882EF1 76.92%)',
                                            color: Color.White,
                                        }}
                                    />
                                :   <Typography
                                        sx={{
                                            fontFamily: 'Nunito Sans',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '21px',
                                            letterSpacing: '0.15em',
                                            textAlign: 'left',
                                            color: Color.PrimaryLavender,
                                        }}
                                    >
                                        VERIFY YOUR ACCOUNT
                                    </Typography>
                                }
                            </Stack>

                            {proPlan.map((plan, index) => (
                                <Stack direction="row" spacing={2} key={index}>
                                    <Typography
                                        sx={{
                                            fontWeight: 300,
                                            fontSize: '18px',
                                            lineHeight: '24px',
                                            color: Color.PrimaryLavender,
                                        }}
                                    >
                                        {plan.icon}
                                    </Typography>
                                    <Typography variant="notificationSettingInfo">{plan.label}</Typography>
                                </Stack>
                            ))}

                            <Divider />
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                {user?.userType === UserType.Pro ?? user?.userType === UserType.ProYearly ?? (
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={currentPlan === UserType.Pro}
                                                onChange={() => handlePlanChange(UserType.Pro)}
                                                value={UserType.Pro}
                                            />
                                        }
                                        label="Pro Monthly Plan"
                                    />
                                )}
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                                {user?.userType === UserType.Pro ?? user?.userType === UserType.ProYearly ?? (
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={currentPlan === UserType.ProYearly}
                                                onChange={() => handlePlanChange(UserType.ProYearly)}
                                                value={UserType.ProYearly}
                                            />
                                        }
                                        label="Pro Yearly Plan"
                                    />
                                )}
                                {user?.userType !== UserType.Pro && user?.userType !== UserType.ProYearly && (
                                    <Button
                                        variant="irdbGradient"
                                        sx={{ width: 'auto', marginTop: 2 }}
                                        onClick={toggleDialog}
                                    >
                                        Verify and upgrade
                                    </Button>
                                )}

                                <Dialog open={openDialog} onClose={toggleDialog}>
                                    <Stack direction="column" p={4} sx={{ borderRadius: '12px' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Button
                                                onClick={toggleDialog}
                                                sx={{
                                                    fontSize: '24px',
                                                    '&:hover': {
                                                        backgroundColor: 'transparent',
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                            >
                                                <i className="fa-light fa-xmark"></i>
                                            </Button>
                                        </Box>
                                        <FormControl>
                                            <Typography
                                                variant="proVerifyHeader"
                                                sx={{ paddingTop: '16px', paddingBottom: '16px' }}
                                            >
                                                Apply for Pro verification
                                            </Typography>
                                            <Typography
                                                variant="webHeadlineSmall"
                                                sx={{ paddingTop: '32px', paddingBottom: '16px' }}
                                            >
                                                Your details
                                            </Typography>

                                            <Box
                                                sx={{
                                                    backgroundColor:
                                                        darkMode ?
                                                            Color.LightLavenderDarkMode
                                                        :   Color.LightLavenderLightMode,
                                                    width: 'Fill (452px)',
                                                    height: 'Hug (86px)',
                                                    padding: '14px 16px',
                                                    borderRadius: '4px',
                                                    border: '1px solid',
                                                    borderColor: 'rgba(0,0,0,.32)',
                                                    gap: '10px',
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Stack sx={{ gap: 2 }}>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography pt={0.25} variant="webLabel">
                                                                NAME
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    overflow: 'auto',
                                                                    maxWidth: 150,
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="notificationSettingInfo"
                                                                    sx={{ color: Color.PrimaryDarkGrayBlue }}
                                                                >
                                                                    {user?.fullName}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                        <Stack direction="row" spacing={2}>
                                                            <Typography pt={0.25} variant="webLabel">
                                                                EMAIL
                                                            </Typography>
                                                            <Typography
                                                                variant="notificationSettingInfo"
                                                                sx={{
                                                                    color: Color.PrimaryDarkGrayBlue,
                                                                    maxWidth: 150,
                                                                    overflow: 'auto',
                                                                }}
                                                            >
                                                                {user?.email}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                    <Button
                                                        variant="webTextButton"
                                                        sx={{
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                                cursor: 'pointer',
                                                            },
                                                        }}
                                                    >
                                                        Edit<i className="fa-light fa-arrow-right"></i>
                                                    </Button>
                                                </Stack>
                                            </Box>
                                            <Box sx={{ paddingTop: '16px', paddingBottom: '16px' }}>
                                                <TextField
                                                    label="Phone Number"
                                                    labelSx={{
                                                        position: 'static',
                                                        transform: 'none',
                                                        fontWeight: 600,
                                                    }}
                                                    value={phoneNumber}
                                                    inputProps={{ placeholder: '(_ _ _) _ _ _ - _ _ _ _' }}
                                                    onChange={value => setPhoneNumber(value)}
                                                />
                                            </Box>
                                            <TextField
                                                label="Address"
                                                labelSx={{
                                                    position: 'static',
                                                    transform: 'none',
                                                    fontWeight: 600,
                                                }}
                                                value={address}
                                                onChange={value => setAddress(value)}
                                                sx={{ paddingBottom: '20px' }}
                                            />
                                            <Typography variant="webFont8" pb={1.5}>
                                                IDENTIFICATION (PHOTO ID, DRIVERS LICENSE, OR PASSPORT)
                                            </Typography>
                                            <Stack direction="row" spacing={2} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Button
                                                    sx={{
                                                        width: 142,
                                                        height: 34,
                                                        borderRadius: '17px',
                                                        backgroundColor:
                                                            darkMode ?
                                                                Color.LightLavenderDarkMode
                                                            :   Color.LightLavenderLightMode,
                                                        fontFamily: 'Nunito Sans',
                                                        fontSize: '10px',
                                                        fontWeight: 400,
                                                        lineHeight: '14px',
                                                        letterSpacing: '0.15em',
                                                        textAlign: 'center',
                                                        marginLeft: '0 !important',
                                                    }}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        open();
                                                    }}
                                                >
                                                    UPLOAD IMAGE
                                                </Button>

                                                {selectedFile && (
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Nunito Sans',
                                                            marginTop: 'auto !important',
                                                            marginBottom: 'auto !important',
                                                            marginLeft: '10px !important',
                                                            fontWeight: 600,
                                                            fontSize: '14px',
                                                            lineHeight: '18px',
                                                        }}
                                                    >
                                                        File selected: {truncateFileName(selectedFile.name)}
                                                    </Typography>
                                                )}
                                            </Stack>
                                            <Stack direction="column" spacing={1} sx={{ paddingTop: '40px' }}>
                                                <Typography variant="webHeadlineSmall">Billing cycle</Typography>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                checked={currentPlan === UserType.ProYearly}
                                                                onChange={() => handlePlanChange(UserType.ProYearly)}
                                                                value={UserType.ProYearly}
                                                                sx={{
                                                                    color:
                                                                        currentPlan === UserType.ProYearly ?
                                                                            Color.PrimaryLavender
                                                                        :   'default',
                                                                    '&.Mui-checked': {
                                                                        color: Color.PrimaryLavender,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                variant="webLabel2"
                                                                sx={{
                                                                    color:
                                                                        currentPlan === UserType.ProYearly ?
                                                                            Color.PrimaryLavender
                                                                        :   'default',
                                                                }}
                                                            >
                                                                Pro Yearly Plan
                                                            </Typography>
                                                        }
                                                        sx={{
                                                            color:
                                                                currentPlan === UserType.ProYearly ?
                                                                    Color.PrimaryLavender
                                                                :   'default',
                                                            alignItems: 'center',
                                                        }}
                                                    />

                                                    <Typography
                                                        variant="webLabel2"
                                                        sx={{
                                                            color:
                                                                currentPlan === UserType.ProYearly ?
                                                                    Color.PrimaryLavender
                                                                :   'default',
                                                        }}
                                                    >
                                                        $200/Year
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                checked={currentPlan === UserType.Pro}
                                                                onChange={() => handlePlanChange(UserType.Pro)}
                                                                value={UserType.Pro}
                                                                sx={{
                                                                    color:
                                                                        currentPlan === UserType.Pro ?
                                                                            Color.PrimaryLavender
                                                                        :   'default',
                                                                    '&.Mui-checked': {
                                                                        color: Color.PrimaryLavender,
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <Typography
                                                                variant="webLabel2"
                                                                sx={{
                                                                    color:
                                                                        currentPlan === UserType.Pro ?
                                                                            Color.PrimaryLavender
                                                                        :   'default',
                                                                }}
                                                            >
                                                                Pro Monthly Plan
                                                            </Typography>
                                                        }
                                                    />
                                                    <Typography
                                                        variant="webLabel2"
                                                        sx={{
                                                            color:
                                                                currentPlan === UserType.Pro ?
                                                                    Color.PrimaryLavender
                                                                :   'default',
                                                        }}
                                                    >
                                                        $20/Month
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack direction="row" paddingTop="32px">
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={handleChange}
                                                    sx={{
                                                        p: 0,
                                                        marginRight: 1,
                                                        '&.Mui-checked': {
                                                            color: Color.PrimaryLavender,
                                                        },
                                                    }}
                                                />
                                                <Typography>
                                                    I agree with
                                                    <Link
                                                        sx={{
                                                            color: Color.PrimaryLavender,
                                                            textDecoration: 'none',
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {' '}
                                                        IRCODE Pro account and payment terms
                                                    </Link>
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                alignItems="flex-end"
                                                justifyContent="flex-start"
                                                columnGap={2}
                                            >
                                                <Button
                                                    variant="irdbGradient"
                                                    disabled={!isFormComplete}
                                                    sx={{
                                                        width: 'auto',
                                                        marginTop: 2,
                                                        opacity: isFormComplete ? 1 : 0.5,
                                                    }}
                                                    onClick={() => {
                                                        alert('Form submitted');
                                                    }}
                                                >
                                                    Submit Application
                                                </Button>
                                                <Button onClick={toggleDialog} sx={{ py: 1 }} variant="webTextButton">
                                                    Cancel
                                                </Button>
                                            </Stack>
                                        </FormControl>
                                    </Stack>
                                </Dialog>

                                <Box>
                                    <Typography
                                        variant="webHistoryTitle"
                                        sx={{ marginLeft: 'auto', color: Color.PrimaryLavender }}
                                    >
                                        $200/Year
                                    </Typography>
                                    {/* left the typography below in case they want to display along with yearly price */}
                                    {/* <Typography sx={{ marginLeft: 'auto' }}>$20/Month</Typography> */}
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </>
    );
}
