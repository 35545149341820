import { createContext, Dispatch, FC, SetStateAction, useContext } from 'react';
import { Semaphore } from 'async-mutex';
import { Operation, Type } from './Operation';
import UserContext, { SignInStatus, TUser, User } from './UserContext';
import FirebaseContext, { TFirebase } from './FirebaseContext';
import { MetaField } from '../types/MetaField';
import { nullUndefinedOrEmpty } from '../util/string';
import { MetaType, TArtistName, TTitle } from '../types/MetaTypes';
import { createLink, TLink } from '../types/Link';
import { AxiosResponse, CanceledError } from 'axios';
import useApi, { Method } from 'src/hooks/useApi';
import { Asset, convertToJpeg, CropArea, cropImage } from 'src/util/image';
import { readCsvFromFile, readJsonFromFile } from 'src/util/file';
import { ImageSortByField, ImageSortOrderField } from '../types/Image';
import { removeItem, replaceItem } from '../util/array';
import { AbortablePromise, AbortablePromiseConstructor } from '../util/AbortablePromise/AbortablePromise';
import { AbortError } from '../util/AbortablePromise/AbortError';

// TODO: Move image and image id and related types / interfaces elsewhere
export type ImageID = string;

// TODO: Move to Analytics
export interface HomeStats {
    TotalViews: number;
    UniqueViews: number;
    TopViews: Image[];
    TotalBars: { key: string; value: number }[];
    UniqueBars: { key: string; value: number }[];
}

export interface UserHistory {
    imageID: ImageID;
    imageUrl: string;
    imageCreated: number;
    created: number;
    metaContent: {
        title: string;
    };
}

export interface Image {
    imageID: ImageID;
    imageStatus: 'publish' | 'draft';
    imageUrl: string;
    imageCreated: number;
    ImageUser: {
        userID: number;
        fullName: string;
        profileUrl: string;
        userName: string;
    };
    webUrl: string;

    editingAllowed: boolean;
    metaContent?: {
        title: string;
    };

    metaArray: MetaField[];

    campaignID: number;
    campaignName: string;

    // Stats
    countViews: number;
    countQueries: number;
    timeScanned: number;
    timeSaved: number;
    timeArchived: number;
    transferInProcess: number;
    save: boolean;
}

// T is only for server operations, we can get rid of these
export interface Init {}

export interface Prep {}

export interface Foveate {}

export interface Upload {
    progress: number;
    downloadUrl?: string;
}

export interface Query {
    ImageAlreadyExists: boolean;

    // Present when ImageAlreadyExists is true
    Image?: Image;

    // Present when ImageAlreadyExists is false
    Images?: Image[];

    ImagePrivate?: boolean;
}

export interface Crop {
    Image?: Image;
    ImageCropped?: boolean;
    ImageAlreadyExists?: boolean;
}

export interface Add {
    Image: Image;
    ImageAdded?: boolean;
    ImageAlreadyExists?: boolean;
}

export interface Replace {
    Image: Image;
    ImageReplaced?: boolean;
    error?: string;
}

export interface ImageOperation<T> {
    // This is not the IRCODE Id, this is for React use only (ie `key`).
    id: string;

    // `type`, `file` and `meta` are all for initial creation
    type: 'image' | 'video';
    file?: File | RemoteFile;
    meta?: MetaField[];

    original?: Asset;
    cropped?: Asset & {
        // This is the crop area of the original image
        cropArea: CropArea;
    };
    foveated?: Asset;
    operation: Operation<T>;

    bulkOperation?: {
        selected: boolean;
        meta: MetaField[];
        status: 'publish' | 'draft';
    };

    status: RegistrationStatus;
}

export interface PagedResults<T> {
    NextOffset: number;
    Count: number;
    Pages: number;
    Results: T;
}

export interface RemoteFile {
    src: string;
}

export function isRemoteFile(maybeRemoteFile: any): maybeRemoteFile is RemoteFile {
    return maybeRemoteFile.hasOwnProperty('src');
}

export interface CancellablePromise<T> {
    id: string;
    promise: Promise<T>;
    cancel: () => void;
}

export const createCancellablePromise = <T,>(
    promise: Promise<T> | ConstructorParameters<AbortablePromiseConstructor<T>>[0],
    operationId?: string,
    abortController?: AbortController,
): CancellablePromise<T> => {
    operationId ??= crypto.randomUUID();
    const abortablePromise =
        'then' in promise ?
            AbortablePromise.from<T>(promise, abortController)
        :   new AbortablePromise<T>(promise, abortController);
    return {
        id: operationId,
        promise: abortablePromise,
        cancel: () => abortablePromise.abort(),
    };
};

export enum RegistrationStatus {
    Unknown = 'Unknown',
    Pending = 'Pending',
    Available = 'Available',
    Unavailable = 'Unavailable',
    Adding = 'Adding',
    Added = 'Added',
    Failed = 'Failed',
}

export function replaceImage(image: Image, images: Image[]): Image[];
export function replaceImage(image: Image, setImages: Dispatch<SetStateAction<Image[]>>): void;
export function replaceImage(image: Image, imagesOrSetImage: Dispatch<SetStateAction<Image[]>> | Image[]): unknown {
    const replace = (prevImages: Image[]) =>
        replaceItem(
            prevImages,
            () => image,
            i => i.imageID === image.imageID,
            true,
        );
    if (Array.isArray(imagesOrSetImage)) {
        return replace(imagesOrSetImage);
    }
    return imagesOrSetImage(replace);
}

export function removeImage(image: Image, images: Image[]): Image[];
export function removeImage(image: Image, setImages: Dispatch<SetStateAction<Image[]>>): void;
export function removeImage(image: Image, imagesOrSetImage: Dispatch<SetStateAction<Image[]>> | Image[]): unknown {
    const remove = (prevImages: Image[]) => removeItem(prevImages, i => i.imageID === image.imageID);
    if (Array.isArray(imagesOrSetImage)) {
        return remove(imagesOrSetImage);
    }
    return imagesOrSetImage(remove);
}

export function replaceImageOperation(
    imageOperation: ImageOperation<any>,
    imageOperations: ImageOperation<any>[],
): ImageOperation<any>[];
export function replaceImageOperation(
    imageOperation: ImageOperation<any>,
    setImageOperations: Dispatch<SetStateAction<ImageOperation<any>[]>>,
): void;
export function replaceImageOperation(
    imageOperation: ImageOperation<any>,
    imageOperationsOrSet: Dispatch<SetStateAction<ImageOperation<any>[]>> | ImageOperation<any>[],
): unknown {
    const replace = (prevImageOperations: ImageOperation<any>[]) =>
        replaceItem(
            prevImageOperations,
            () => imageOperation,
            i => i.id === imageOperation.id,
            true,
        );
    if (Array.isArray(imageOperationsOrSet)) {
        return replace(imageOperationsOrSet);
    }
    return imageOperationsOrSet(replace);
}

export function removeImageOperation(
    imageOperation: ImageOperation<any>,
    imageOperations: ImageOperation<any>[],
): ImageOperation<any>[];
export function removeImageOperation(
    imageOperation: ImageOperation<any>,
    setImageOperations: Dispatch<SetStateAction<ImageOperation<any>[]>>,
): void;
export function removeImageOperation(
    imageOperation: ImageOperation<any>,
    imageOperationsOrSet: ImageOperation<any>[] | Dispatch<SetStateAction<ImageOperation<any>[]>>,
): unknown {
    const remove = (prevImageOperations: ImageOperation<any>[]) =>
        removeItem(prevImageOperations, i => i.id === imageOperation.id);
    if (Array.isArray(imageOperationsOrSet)) {
        return remove(imageOperationsOrSet);
    }
    return imageOperationsOrSet(remove);
}

export const imageFromImageOperation = (imageOperation: ImageOperation<any>): Image | undefined => {
    // console.log('imageOperation', imageOperation);
    switch (imageOperation.operation.type) {
        case Type.Query:
        case Type.Add:
        case Type.Prep:
            // case Type.Load:
            return imageOperation.operation.Results.Image as Image;
        default:
            return undefined;
    }
};

export const imageOperationFromImage = async (
    image: Image,
    operationType: Type = Type.Prep,
): Promise<ImageOperation<any>> => {
    const file = await fetch(image.imageUrl);
    const blob = await file.blob();
    return {
        id: crypto.randomUUID(),
        type: 'image',
        original: {
            file: new File([blob], image.imageUrl),
            width: 0,
            height: 0,
            preview: image.imageUrl,
        },
        operation: {
            type: operationType,
            status: '',
            Pending: false,
            Completed: true,
            Results: {
                Image: image,
            },
        },
        status: RegistrationStatus.Unknown,
    };
};

export const similarImagesFromImageOperation = (imageOperation: ImageOperation<any>): Image[] | undefined => {
    if (imageOperation.operation.type === Type.Query && imageOperation.operation.Results) {
        return imageOperation.operation.Results.Images as Image[];
    }
    return undefined;
};

export const isImageOwnedByUser = (image: Image | undefined, user: User | undefined) =>
    !!image && !!user && image.ImageUser.userID === user.userID;

export interface TMedia {
    init: (
        file: File,
        onProgress: (progress: ImageOperation<Init>) => void,
    ) => CancellablePromise<ImageOperation<Init>[]>;
    prep(imageOperation: ImageOperation<Init>, bulk?: boolean): CancellablePromise<ImageOperation<Prep>>;
    foveate(image: ImageOperation<Prep>): CancellablePromise<ImageOperation<Foveate>>;
    crop(imageOperation: ImageOperation<any>, cropArea: CropArea): Promise<ImageOperation<any>>;
    upload(
        image: ImageOperation<any>,
        onProgress: (progress: ImageOperation<Upload>) => void,
    ): CancellablePromise<ImageOperation<Upload>>;
    query(
        image: ImageOperation<any>,
        onProgress: (progress: ImageOperation<Query>) => void,
    ): CancellablePromise<ImageOperation<Query>>;
    add(
        image: ImageOperation<any>,
        status: 'publish' | 'draft' | undefined,
        onProgress?: (progress: ImageOperation<Add>) => void,
    ): CancellablePromise<ImageOperation<Add>>;
    /** Replace an ircode's imageUrl */
    replace(
        imageId: ImageID,
        imageOperation: ImageOperation<Upload>,
        twoFA: string,
    ): CancellablePromise<ImageOperation<Replace>>;
    remove(imageID: ImageID): Promise<boolean>;
    restore(imageID: ImageID): Promise<void>;
    stats(): Promise<HomeStats>;
    upcoming(): Promise<{}>;

    fetchHistory: (page: number) => Promise<PagedResults<Image[]>>;
    fetchImages(
        userId: number,
        page: number,
        limit?: number,
        sortBy?: ImageSortByField,
        sortOrder?: ImageSortOrderField,
        hideCampaigns?: boolean,
    ): Promise<PagedResults<Image[]>>;
    fetchImageList(imageIDs: ImageID[]): Promise<Image[]>;
    fetchRemoved: (page: number) => Promise<PagedResults<Image[]>>;
    fetchSaved(page: number, limit?: number): Promise<PagedResults<Image[]>>;
    fetchSimilarImages(image: Image): Promise<PagedResults<Image[]>>;
    fetchContest(from: number, to: number): Promise<Image[]>;

    /** Save as favorite */
    save(imageId: ImageID): Promise<void>;
    /** Unsave as favorite */
    unsave(imageId: ImageID): Promise<void>;
    // view(imageId: ImageID): Promise<void>;

    load(imageId: ImageID): Promise<Image>;
    status(imageId: ImageID, status: 'publish' | 'draft'): Promise<void>;
    meta(imageId: ImageID, type: string, content: object): Promise<void>;
    initiateTransfer(
        imageIDToTransfer: ImageID,
        emailOfReceivingUser: string,
        password: string,
        checkTwoFA: string,
    ): Promise<void>;
    respondToTransfer(imageIDToTransfer: ImageID, accept: boolean, checkTwoFA?: string): Promise<void>;
}

type ProgressHandler = (progress: ImageOperation<any>) => void;

function getProgressHandler(
    onProgress: (progress: ImageOperation<any>) => void,
    abortController: AbortController,
): ProgressHandler;
function getProgressHandler(
    onProgress: ProgressHandler | undefined,
    abortController: AbortController,
): ProgressHandler | undefined;
function getProgressHandler(onProgress: ProgressHandler | undefined, abortController: AbortController): unknown {
    if (!onProgress) {
        return undefined;
    }
    return (progress: ImageOperation<any>) => {
        if (abortController.signal.aborted) {
            return;
        }
        onProgress(progress);
    };
}

interface Props {
    children: React.ReactNode;
}

export const MediaProvider: FC<Props> = ({ children }) => {
    const { request } = useApi();
    const { upload: firebaseUpload } = useContext(FirebaseContext) as TFirebase;
    const { user, signInWithEmailAndPassword } = useContext(UserContext) as TUser;

    const extractFileName = (url: string): string => {
        const parsedUrl = new URL(url);
        const pathSegments = parsedUrl.pathname.split('/');
        let fileName = pathSegments[pathSegments.length - 1];
        fileName = fileName.split('?')[0];
        return fileName;
    };

    // TODO: Init and Prep should be combined. The only difference really is that Prep will download the file if it's remote
    const init = (
        file: File,
        onProgress: (progress: ImageOperation<Init>) => void,
    ): CancellablePromise<ImageOperation<Init>[]> => {
        const id = crypto.randomUUID();

        const promise = new AbortablePromise<ImageOperation<Init>[]>(async (resolve, reject, abortController) => {
            const _onProgress = getProgressHandler(onProgress, abortController);
            switch (true) {
                case file.type === 'text/csv':
                case file.type === 'application/json':
                    {
                        let json = [];
                        if (file.type === 'text/csv') {
                            json = await readCsvFromFile(file);
                        } else {
                            json = await readJsonFromFile(file);
                        }

                        const imageOperations = json.map(
                            ({ src, meta }: { src: string; meta: any[] }): ImageOperation<Init> => {
                                return {
                                    id: crypto.randomUUID(),
                                    type: 'image',
                                    file: {
                                        src,
                                    } as RemoteFile,
                                    meta: meta.map((metaField, index: number) => {
                                        return {
                                            metaID: 0,
                                            key: index.toString(),
                                            ...metaField,
                                        };
                                    }),
                                    operation: {
                                        type: Type.Init,
                                        status: '',
                                        Pending: true,
                                        Completed: false,
                                    },
                                    status: RegistrationStatus.Pending,
                                };
                            },
                        );

                        resolve(imageOperations);
                    }
                    break;
                case file.type.startsWith('image/'): {
                    const imageOperation: ImageOperation<Init> = {
                        id: crypto.randomUUID(),
                        type: 'image',
                        file,
                        meta: [
                            {
                                key: crypto.randomUUID(),
                                metaID: 0,
                                metaType: MetaType.Title,
                                metaContent: {
                                    title: file.name.replace(/(.*)\.[^.]+$/, '$1'),
                                } as TTitle,
                            },
                            {
                                key: crypto.randomUUID(),
                                metaID: 0,
                                metaType: MetaType.ArtistName,
                                // TODO: isArtist?
                                metaContent: {
                                    name: user?.fullName ?? '',
                                } as TArtistName,
                            },
                            {
                                key: crypto.randomUUID(),
                                metaID: 0,
                                metaType: MetaType.Link,
                                metaContent: {
                                    links: [createLink()],
                                } as TLink,
                            },
                        ],
                        original: {
                            file,
                            width: 0,
                            height: 0,
                            preview: URL.createObjectURL(file),
                        },
                        operation: {
                            type: Type.Init,
                            status: '',
                            Pending: true,
                            Completed: false,
                        },
                        status: RegistrationStatus.Pending,
                    };

                    _onProgress(imageOperation);

                    resolve([imageOperation]);
                    break;
                }
                default:
                    console.error('Invalid file type');
                    reject('Please upload only valid image files.');
            }
        });

        return {
            id,
            promise,
            cancel: () => promise.abort(),
        };
    };

    // Will download if remote file
    const prep = (
        imageOperation: ImageOperation<Init>,
        bulk: boolean = false,
    ): CancellablePromise<ImageOperation<Prep>> => {
        const id = crypto.randomUUID();
        const promise = new AbortablePromise<ImageOperation<Prep>>(async (resolve, reject, abortController) => {
            if (imageOperation.file === undefined) {
                throw new Error('File is undefined');
            }

            const operation: Operation<Prep> = {
                type: Type.Prep,
                status: 'Waiting...',
                Pending: true,
                Completed: false,
            };

            let file: File;
            if (isRemoteFile(imageOperation.file)) {
                let response: AxiosResponse | undefined;
                try {
                    response = await request({
                        method: Method.GET,
                        path: imageOperation.file.src,
                        onProgress: (progress: number) => {
                            console.log('progress', progress);
                        },
                        axiosParams: {
                            responseType: 'blob',
                        },
                        abortController,
                    });
                } catch (error) {
                    // This is not terminal, we'll try a proxy
                    console.error(error);
                    if (error instanceof CanceledError) {
                        reject(new AbortError());
                        return;
                    }
                }

                if (!response) {
                    try {
                        response = await request({
                            method: Method.GET,
                            path: `/proxy?url=${imageOperation.file.src}`,
                            onProgress: (progress: number) => {
                                console.log('progress', progress);
                            },
                            axiosParams: {
                                responseType: 'blob',
                            },
                            abortController,
                        });
                    } catch (error) {
                        console.error(error);
                        if (error instanceof CanceledError) {
                            reject(new AbortError());
                            return;
                        }
                    }
                }

                if (!response) {
                    reject(new Error('Unable to fetch remote file'));
                    return;
                }

                const blob: Blob = await response.data;
                const fileName = extractFileName(imageOperation.file.src);
                file = new File([blob], fileName, { type: blob.type });

                // Only for images with weird compositions
                // file = (await cropImage(file, { unit: 'px', width: 2156 - 363, height: 2379 - 160, x: 363, y: 160 })).file;
            } else {
                file = imageOperation.file;
            }

            resolve({
                ...imageOperation,
                id: imageOperation.id,

                type: imageOperation.type,

                // TODO: Need these anymore?
                // file,
                // meta,

                original: {
                    file,
                    width: 0,
                    height: 0,
                    preview: URL.createObjectURL(file),
                },
                operation,
                bulkOperation:
                    bulk ?
                        {
                            selected: true,
                            status: 'publish',
                            meta: imageOperation.meta ?? [],
                        }
                    :   undefined,
                status: RegistrationStatus.Pending,
            });
        });

        return {
            id,
            promise,
            cancel: () => promise.abort(),
        };
    };

    // Helper function for digital zoom in TypeScript
    function digitalZoom(
        rect: { x: number; y: number; width: number; height: number },
        zoomLevel: number,
    ): { x: number; y: number; width: number; height: number } {
        const aspectRatio = rect.width / rect.height;
        const scaledWidth = rect.width * zoomLevel;
        const scaledHeight = scaledWidth / aspectRatio; // Maintain aspect ratio
        const newX = rect.x + rect.width / 2 - scaledWidth / 2;
        const newY = rect.y + rect.height / 2 - scaledHeight / 2;
        return { x: newX, y: newY, width: scaledWidth, height: scaledHeight };
    }

    // Main function to create triple foveated query image
    ///// XXX only createTripleFoveated image if minZoomLevel is below 1 (= has a wide angle lens)
    /////     you can easily parameterize this to only do two steps (see newHeight = ... *3 and the for loop!!)
    function createTripleFoveatedQueryImage(image: HTMLImageElement, isNewlyCaptured: boolean): HTMLCanvasElement {
        const originalWidth = image.width;
        const originalHeight = image.height;
        const originalSquareSize = Math.min(originalWidth, originalHeight);

        if (isNewlyCaptured) {
            /// if the image captured was taken from a video preview that was deliberately placed over-sized in the UI DOM, so that the user sees just a cropped view
            /// then this line corrects the zoom level accordingly
            /// leave it commented out, if the video preview is fitting the screen
            //originalSquareSize = originalSquareSize * 0.6; // Constants.queryImageFoviationZoomFactor;
        }

        const baseZoomOut = 0.5;
        const targetMP = 1638400;
        const squareMP = originalSquareSize * originalSquareSize;
        const scaleRatio = Math.sqrt(targetMP / squareMP);

        const newWidth = originalSquareSize * scaleRatio;
        const newHeight = originalSquareSize * scaleRatio * 3;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        canvas.width = newWidth;
        canvas.height = newHeight;

        const overlapX = (originalWidth - originalSquareSize) / 2;
        const overlapY = (originalHeight - originalSquareSize) / 2;
        const squareRect = {
            x: overlapX,
            y: overlapY,
            width: originalSquareSize,
            height: originalSquareSize,
        };

        const squareWidth = originalSquareSize * scaleRatio;
        const squareHeight = originalSquareSize * scaleRatio;

        // Apply different zoom levels for the top, middle, and bottom images in reversed order
        for (let i = 2; i >= 0; i--) {
            const zoomLevel = Math.pow(baseZoomOut, 2 - i);
            const zoomedRect = digitalZoom(squareRect, zoomLevel);

            // Draw the zoomed image on the canvas
            ctx.drawImage(
                image,
                zoomedRect.x,
                zoomedRect.y,
                zoomedRect.width,
                zoomedRect.height,
                0,
                squareHeight * i,
                squareWidth,
                squareHeight,
            );
        }

        return canvas;
    }

    const foveate = <T,>(imageOperation: ImageOperation<T>): CancellablePromise<ImageOperation<T>> => {
        const promise = new AbortablePromise<ImageOperation<T>>(async (resolve, reject, abortController) => {
            if (abortController.signal.aborted) {
                reject(new AbortError());
                return;
            }
            const useFile = imageOperation.cropped?.file ?? imageOperation.original?.file;
            if (useFile === undefined) {
                throw new Error('Image file is undefined');
            }

            const image = new Image();
            image.src = URL.createObjectURL(useFile);
            image.onerror = function () {
                URL.revokeObjectURL(this.src);
                reject('Failed to load image');
            };
            image.onload = function () {
                URL.revokeObjectURL(image.src);
                if (abortController.signal.aborted) {
                    reject(new AbortError());
                    return;
                }

                const canvas = createTripleFoveatedQueryImage(image, true);
                canvas.toBlob(
                    blob => {
                        if (abortController.signal.aborted) {
                            reject(new AbortError());
                            return;
                        }
                        if (blob) {
                            const newFile = new File([blob], useFile.name, { type: 'image/jpeg' });
                            const newOperation = {
                                ...imageOperation,
                                operation: {
                                    ...imageOperation.operation,
                                    type: Type.Foveate,
                                },
                                foveated: {
                                    file: newFile,
                                    // width: newSize.width,
                                    // height: newSize.height,
                                    width: canvas.clientWidth,
                                    height: canvas.clientHeight,
                                    preview: URL.createObjectURL(newFile),
                                },
                            };

                            resolve(newOperation);
                        } else {
                            reject('Failed to foveate image');
                        }
                    },
                    'image/jpeg',
                    0.7,
                );
            };
        });

        return {
            id: imageOperation.id,
            promise,
            cancel: () => promise.abort(),
        };
    };

    const crop = <T,>(imageOperation: ImageOperation<T>, cropArea: CropArea): Promise<ImageOperation<T>> => {
        // const abortController = new AbortController();
        const promise = new Promise<ImageOperation<T>>(async (resolve, reject) => {
            if (imageOperation.original?.file === undefined) {
                reject(new Error('Image file is undefined'));
            }

            // console.log('imageOperation', imageOperation);
            // console.log('cropArea', cropArea);
            // console.log('imageOperation.naturalWidth', imageOperation.naturalWidth);

            try {
                const cropped = await cropImage(imageOperation.original?.file!, cropArea);
                const newOperation = {
                    ...imageOperation,
                    operation: {
                        ...imageOperation.operation,
                        type: Type.Crop,
                    },
                    cropped,
                };

                resolve(newOperation);
            } catch (error) {
                reject(error);
            }
        });

        return promise;
    };

    const uploadSemaphore = new Semaphore(1);
    const upload = (
        imageOperation: ImageOperation<any>,
        onProgress: (progress: ImageOperation<Upload>) => void,
    ): CancellablePromise<ImageOperation<Upload>> => {
        const promise = new AbortablePromise<ImageOperation<Upload>>(async (resolve, reject, abortController) => {
            const resultImageOperation = { ...imageOperation };
            const _onProgress = getProgressHandler(onProgress, abortController);
            resultImageOperation.status = RegistrationStatus.Adding;
            _onProgress({
                ...resultImageOperation,
                operation: {
                    type: Type.Upload,
                    status: 'Waiting to upload...',
                    Pending: true,
                    Completed: false,
                },
            });

            const [value, release] = await uploadSemaphore.acquire();

            abortController.signal.addEventListener('abort', release);

            _onProgress({
                ...resultImageOperation,
                operation: {
                    type: Type.Upload,
                    status: 'Uploading...',
                    Pending: false,
                    Completed: false,
                    Results: {
                        progress: 0,
                    },
                },
            });

            try {
                let fileToUpload: File | undefined =
                    resultImageOperation.cropped?.file ?? resultImageOperation.original?.file;
                if (fileToUpload === undefined) {
                    throw new Error('File to upload is undefined');
                }

                fileToUpload = await convertToJpeg(fileToUpload, 0.8);

                let downloadUrl = await firebaseUpload(
                    fileToUpload!,
                    'irdbAdd',
                    (progress: number) => {
                        // console.log("upload progress", progress);
                        _onProgress({
                            ...resultImageOperation,
                            operation: {
                                type: Type.Upload,
                                status: 'Uploading...',
                                Pending: false,
                                Completed: false,
                                Results: {
                                    progress,
                                },
                            },
                        });
                    },
                    abortController,
                );
                // console.log("downloadUrl", downloadUrl);

                // The server needs Google Storage URLs
                downloadUrl = downloadUrl.replace(
                    'https://firebasestorage.googleapis.com/v0/b/ircode-1a662.appspot.com/o/',
                    'https://storage.googleapis.com/ircode-1a662.appspot.com/',
                );
                downloadUrl = downloadUrl.split('?')[0];
                // console.log("downloadUrl", downloadUrl);

                if (resultImageOperation.cropped) {
                    resultImageOperation.cropped.url = downloadUrl;
                } else {
                    resultImageOperation.original!.url = downloadUrl;
                }

                console.log('Upload complete');
                resolve({
                    ...resultImageOperation,
                    operation: {
                        type: Type.Upload,
                        status: 'Uploaded',
                        Pending: false,
                        Completed: true,
                        Results: {
                            progress: 100,
                            downloadUrl,
                        },
                    },
                });
            } catch (error) {
                reject(error);
            } finally {
                abortController.signal.removeEventListener('abort', release);
                release();
            }
        });

        return {
            id: imageOperation.id,
            promise,
            cancel: () => promise.abort(),
        };
    };

    const querySemaphore = new Semaphore(10);
    const query = (
        imageOperation: ImageOperation<any>,
        onProgress: (progress: ImageOperation<Query>) => void,
    ): CancellablePromise<ImageOperation<Query>> => {
        const promise = new AbortablePromise<ImageOperation<Query>>(async (resolve, reject, abortController) => {
            const _onProgress = getProgressHandler(onProgress, abortController);
            _onProgress({
                ...imageOperation,
                operation: {
                    type: Type.Query,
                    status: 'Waiting to query...',
                    Pending: true,
                    Completed: false,
                },
            });

            const [value, release] = await querySemaphore.acquire();

            abortController.signal.addEventListener('abort', release);

            _onProgress({
                ...imageOperation,
                operation: {
                    type: Type.Query,
                    status: 'Querying...',
                    Pending: true,
                    Completed: false,
                },
            });

            // TODO: Failure cases need to be handled in one way

            try {
                const newImageOperation = await new AbortablePromise<ImageOperation<Query>>(
                    async (innerResolve, innerReject) => {
                        let response;
                        if (imageOperation.foveated?.file === undefined) {
                            throw new Error('Image file is undefined');
                        }

                        const reader = new FileReader();
                        reader.readAsArrayBuffer(await convertToJpeg(imageOperation.foveated?.file, 0.5));
                        reader.onprogress = async function (event) {
                            // console.log("progress", event.loaded, event.total);
                        };
                        reader.onloadend = async function (event) {
                            try {
                                if (event.target === null) {
                                    return innerReject('File read error');
                                }

                                const arrayBuffer = event.target.result;

                                if (arrayBuffer === null) {
                                    return innerReject('File read error');
                                }

                                response = await request({
                                    headers: {
                                        'Content-Type': 'application/octet-stream',
                                    },
                                    method: Method.POST,
                                    path: `/IREngine`,
                                    data: arrayBuffer,
                                    onProgress: (progress: number) => {
                                        // console.log("progress", progress);
                                    },
                                    abortController,
                                });
                                // console.log("response", response);

                                const status =
                                    response.data.Results.ImageAlreadyExists ?
                                        RegistrationStatus.Unavailable
                                    :   RegistrationStatus.Available;

                                innerResolve({
                                    ...imageOperation,
                                    operation: {
                                        type: Type.Query,
                                        Pending: false,
                                        ...response?.data,
                                    },
                                    status,
                                });
                            } catch (error) {
                                innerReject(error);
                            }
                        };

                        reader.onerror = function (event) {
                            event.stopPropagation();
                            console.error(event.target?.error);
                            innerReject(event.target?.error);
                        };
                    },
                    abortController,
                );

                if (newImageOperation) {
                    resolve(newImageOperation);
                } else {
                    throw new Error('File read error');
                }
            } catch (error: any) {
                if (error instanceof CanceledError) {
                    reject(new AbortError());
                    return;
                }
                resolve({
                    ...imageOperation,
                    operation: {
                        type: Type.Query,
                        Pending: false,
                        ...error.response?.data,
                    },
                });
            } finally {
                release();
                abortController.signal.removeEventListener('abort', release);
            }
        });

        return {
            id: imageOperation.id,
            promise,
            cancel: () => promise.abort(),
        };
    };

    const addSemaphore = new Semaphore(1);
    const add = (
        imageOperation: ImageOperation<Query>,
        status: 'publish' | 'draft' = 'publish',
        onProgress?: (progress: ImageOperation<Add>) => void,
    ): CancellablePromise<ImageOperation<Add>> => {
        const abortController = new AbortController();
        const promise = new Promise<ImageOperation<Add>>(async (resolve, reject) => {
            // Can only be aborted at the very start of execution
            if (abortController.signal.aborted) {
                reject(new AbortError());
                return;
            }
            onProgress?.({
                ...imageOperation,
                operation: {
                    type: Type.Add,
                    status: 'Waiting to save...',
                    Pending: true,
                    Completed: false,
                },
                status: RegistrationStatus.Adding,
            });

            const [value, release] = await addSemaphore.acquire();

            onProgress?.({
                ...imageOperation,
                operation: {
                    type: Type.Add,
                    status: 'Saving...',
                    Pending: true,
                    Completed: false,
                },
                status: RegistrationStatus.Adding,
            });

            let response;
            try {
                const asset = imageOperation.cropped ?? imageOperation.original;
                response = await request({
                    method: Method.PUT,
                    path: '/IREngine',
                    data: {
                        imageUrl: asset?.url,
                        imageWidth: asset?.width,
                        imageHeight: asset?.height,
                        // TODO: Fix this
                        imageSize: asset?.file?.size ?? 0,
                        status,
                    },
                });
            } catch (error) {
                // TODO: This should reject ... maybe?
                onProgress?.({
                    ...imageOperation,
                    operation: {
                        type: Type.Add,
                        status: 'Error',
                        Pending: false,
                        Completed: true,
                    },
                    status: RegistrationStatus.Failed,
                });
                console.log('error', error);
            }

            release();
            const operationStatus =
                response?.data.Results.ImageAlreadyExists ? RegistrationStatus.Unavailable : RegistrationStatus.Added;
            resolve({
                ...imageOperation,
                operation: {
                    type: Type.Add,
                    status: 'Added',
                    Pending: false,
                    ...response?.data,
                },
                // TODO: Is this added? We have a catch which maybe it never threw?
                status: operationStatus,
            });
        });

        return {
            id: imageOperation.id,
            promise,
            cancel: () => abortController.abort(),
        };
    };

    const replace = (
        imageID: ImageID,
        imageOperation: ImageOperation<Upload>,
        twoFA: string,
    ): CancellablePromise<ImageOperation<Replace>> => {
        const promise = new AbortablePromise<ImageOperation<Replace>>(async (resolve, reject, abortController) => {
            try {
                const url = imageOperation.operation.Results?.downloadUrl;
                if (!url) {
                    throw new Error('No download url');
                }
                const response: AxiosResponse<PagedResults<Replace>> = await request({
                    method: Method.PATCH,
                    path: `/IREngine/imageless/${imageID}`,
                    data: {
                        imageUrl: url,
                        twoFA,
                    },
                    abortController,
                });

                const Results = response.data.Results;
                if (Results.error) {
                    throw new Error(Results.error);
                }
                const result: ImageOperation<Replace> = {
                    ...imageOperation,
                    operation: {
                        type: Type.Replace,
                        status: Results.ImageReplaced ? 'Replaced' : 'Failed',
                        Pending: false,
                        Completed: true,
                        Results: response.data.Results,
                    },
                };
                resolve(result);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
        return {
            id: imageOperation.id,
            promise,
            cancel: () => promise.abort(),
        };
    };

    const load = async (imageId: ImageID): Promise<Image> => {
        const response = await request({
            method: Method.GET,
            path: `/Images/${imageId}`,
        });
        // console.log("response", response);

        return response.data.Results.Image;
    };

    const status = async (imageId: ImageID, status: 'publish' | 'draft'): Promise<void> => {
        try {
            await request({
                method: Method.PUT,
                path: `/Images/status`,
                data: {
                    imageID: imageId,
                    status,
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const removeSemaphore = new Semaphore(5);
    const remove = async (imageID: ImageID): Promise<boolean> => {
        const [value, release] = await removeSemaphore.acquire();
        try {
            const response = await request({
                method: Method.DELETE,
                path: `/IREngine/${imageID}`,
            });
            // console.log("response", response);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        } finally {
            release();
        }
    };

    const restore = async (imageID: ImageID): Promise<void> => {
        try {
            const response = await request({
                method: Method.PATCH,
                path: `/Removed/image/${imageID}`,
            });
        } catch (error) {
            console.error('Error during restore:', error);
        }
    };

    const stats = async (): Promise<HomeStats> => {
        const response = await request({
            method: Method.GET,
            path: '/Statistics/homeScreen',
        });

        const prepareData = (data: Record<string, number>[]): { key: string; value: number }[] => {
            return data
                .sort((a, b) => (Object.keys(a)[0] > Object.keys(b)[0] ? 1 : -1))
                .map(stat => {
                    return {
                        key: Object.keys(stat)[0],
                        value: Object.values(stat)[0] === 0 ? 1 : Object.values(stat)[0],
                    };
                });
        };

        const homeStats = response.data.Results;
        return {
            TotalViews: homeStats.TotalViews,
            UniqueViews: homeStats.UniqueViews,
            TopViews: homeStats.TopViews,
            TotalBars: prepareData(homeStats.TotalBars),
            UniqueBars: prepareData(homeStats.UniqueBars),
        };
    };

    const upcoming = async (): Promise<{}> => {
        try {
            const response = await request({
                method: Method.GET,
                path: '/UAT/upcoming',
            });
            console.log('response', response);

            // const prepareData = (data: Record<string, number>[]): { key: string; value: number; }[] => {
            //     return data
            //         // .sort(() => Math.random() - 0.5)
            //         .sort((a, b) => (
            //             Object.keys(a)[0] > Object.keys(b)[0]
            //                 ? 1
            //                 : -1
            //         ))
            //         .map(stat => {
            //             // console.log('key', Object.keys(stat)[0]);
            //             return {
            //                 key: Object.keys(stat)[0],
            //                 value: Object.values(stat)[0] === 0 ? 1 : Object.values(stat)[0], //  / 100,
            //             }
            //         });
            // };

            // const homeStats = response.data.Results;

            return {
                // TotalViews: homeStats.TotalViews,
                // UniqueViews: homeStats.UniqueViews,
                // TopViews: homeStats.TopViews,
                // TotalBars: prepareData(homeStats.TotalBars),
                // UniqueBars: prepareData(homeStats.UniqueBars),
            };
        } catch (error) {
            console.log('error', error);
            return {
                // TotalViews: 0,
                // UniqueViews: 0,
                // TopViews: [],
                // TotalBars: [],
                // UniqueBars: [],
            };
        }
    };

    const fetchHistory = async (page: number = 0, limit: number = 25): Promise<PagedResults<Image[]>> => {
        // try {
        const response = await request({
            method: Method.GET,
            path: `/Images/history?offset=${page * limit}&limit=${limit}`,
        });

        return {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Images,
        };

        // const responseData = response.data;
        // const parsedData = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData;

        // const historyData = parsedData.Results.Images.map((image: any) => {
        //     const createdMeta = image.metaArray.find((meta: any) => meta.metaType === 'Title' || meta.metaType === 'ArtistName');
        //     const created = createdMeta ? createdMeta.created : null;
        //     const title = image.metaContent ? image.metaContent.title : 'Untitled';
        //     return {
        //         imageID: image.imageID,
        //         imageUrl: image.imageUrl,
        //         imageCreated: image.imageCreated,
        //         created: created,
        //         metaContent: {
        //             title: title,
        //         },
        //     };
        // });

        // console.log("Fetched history data:", historyData);

        // return historyData as UserHistory[];

        // } catch (error) {
        //     console.log("Error fetching history:", error);
        //     return [];
        // }
    };

    const fetchImages = async (
        userId: number,
        page: number = 0,
        limit: number = 25,
        sortBy: ImageSortByField = 'internalID',
        sortOrder: ImageSortOrderField = 'DESC',
        hideCampaigns: boolean = false,
    ): Promise<PagedResults<Image[]>> => {
        const optionalParams = hideCampaigns ? '&hideCampaigns=true' : '';
        const response = await request({
            method: Method.GET,
            path: `/Images?user=${userId}&offset=${page * limit}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}${optionalParams}`,
        });

        return {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Images,
        };
    };

    const fetchImageList = async (imageIds: ImageID[]): Promise<Image[]> => {
        const response = await request({
            method: Method.GET,
            path: '/Images/imageList',
            axiosParams: {
                params: {
                    imageID: imageIds,
                },
            },
        });

        return response.data.Results.Images;
    };

    const fetchRemoved = async (page: number = 0, limit: number = 25): Promise<PagedResults<Image[]>> => {
        const response = await request({
            method: Method.GET,
            path: `/Removed/images?offset=${page * limit}&limit=${limit}`,
        });

        const result = {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Images,
        };

        return result;
    };

    const fetchSaved = async (page: number = 0, limit: number = 25): Promise<PagedResults<Image[]>> => {
        const response = await request({
            method: Method.GET,
            path: `/Images/saved?offset=${page * limit}&limit=${limit}`,
        });

        return {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Images,
        };
    };

    const fetchSimilarImages = async (image: Image): Promise<PagedResults<Image[]>> => {
        const response = await request({
            method: Method.GET,
            path: `/IREngine/similar?imageID=${image.imageID}`,
        });

        return {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Images,
        };
    };

    const fetchContest = async (from: number, to: number): Promise<Image[]> => {
        const response = await request({
            method: Method.GET,
            path: `/Statistics/mostScans?startDate=${from}&endDate=${to}`,
        });

        return response.data.Results?.Images;
    };

    const save = async (imageId: ImageID): Promise<void> => {
        try {
            await request({
                method: Method.POST,
                path: `/Images/save/${imageId}`,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const unsave = async (imageId: ImageID): Promise<void> => {
        try {
            await request({
                method: Method.DELETE,
                path: `/Images/save/${imageId}`,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const meta = async (imageId: ImageID, type: string, content: object): Promise<void> => {
        const response = await request({
            method: Method.PUT,
            path: `/ImageMeta?imageID=${imageId}`,
            data: {
                type,
                content: JSON.stringify(content),
            },
        });
        // console.log("response", response);
    };

    const initiateTransfer = async (
        imageIDToTransfer: string,
        emailOfReceivingUser: string,
        password: string,
        checkTwoFA: string,
    ): Promise<void> => {
        if (nullUndefinedOrEmpty(user?.email)) {
            throw new Error('User email is null, undefined, or empty');
        }

        // TODO: is email verified?

        // TODO: Which auth method?
        const result = await signInWithEmailAndPassword(user!.email!, password);
        console.log('result', result);

        if (result.status === SignInStatus.Error) {
            throw new Error('Error signing in');
        }

        const response = await request({
            method: Method.POST,
            path: `/IRCode/transfer`,
            data: {
                imageIDToTransfer,
                emailOfReceivingUser,
                checkTwoFA,
            },
        });

        if (response.data.Completed === false) {
            throw new Error(response.data.Results.Error);
        }
        // console.log("response", response);
    };

    const respondToTransfer = async (imageID: ImageID, accept: boolean, checkTwoFA?: string): Promise<void> => {
        // if (nullUndefinedOrEmpty(user?.email)) {
        //     throw new Error("User email is null, undefined, or empty");
        // }

        // TODO: is email verified?

        // TODO: Which auth method?
        // const result = await signInWithEmailAndPassword(
        //     user!.email!,
        //     password,
        // );
        // console.log("result", result);

        // if (result.status === SignInStatus.Error) {
        //     throw new Error("Error signing in");
        // }

        const response = await request({
            method: Method.PUT,
            path: `/IRCode/transfer`,
            data: {
                imageID,
                accept,
                cancel: !accept,
                // emailOfReceivingUser,
                checkTwoFA,
            },
        });

        if (response.data.Completed === false) {
            throw new Error(response.data.Results.Error);
        }
        console.log('response', response);
    };

    return (
        <MediaContext.Provider
            value={{
                init,
                prep,
                foveate,
                crop,
                upload,
                query,
                add,
                replace,
                load,
                status,
                remove,
                restore,
                stats,
                upcoming,

                fetchHistory,
                fetchImages,
                fetchImageList,
                fetchRemoved,
                fetchSaved,
                fetchSimilarImages,
                fetchContest,

                save,
                unsave,

                meta,
                initiateTransfer,
                respondToTransfer,
            }}
        >
            {children}
        </MediaContext.Provider>
    );
};

const MediaContext = createContext<TMedia | undefined>(undefined);

export default MediaContext;
