import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';

export function useCapturedStateWorkaround<T>(arg: T): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>];
export function useCapturedStateWorkaround<T>(
    arg?: T | undefined,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, MutableRefObject<T | undefined>];
export function useCapturedStateWorkaround<T>(
    t?: T,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>, MutableRefObject<T | undefined>] {
    // export function useCapturedStateWorkaround<T>(t?: T): [T | undefined, Dispatch<SetStateAction<T | undefined>>, MutableRefObject<T | undefined>] {

    const [state, setState] = useState<T | undefined>(t);
    const ref = useRef<T | undefined>(t);
    useEffect(() => {
        ref.current = state;
    }, [state]);

    return [state, setState, ref];
}
