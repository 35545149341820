import Typography from '@mui/material/Typography';
import { Button, Stack } from '@mui/material';
import { Color } from '../../Color';
import TextField from '../general/TextField';
import { useContext, useState } from 'react';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';

interface Props {
    onSelection: (field: string) => void;
    onClose: () => void;
}

export default function AddCustom({ onSelection, onClose }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const [customField, setCustomField] = useState('');

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                p: 4,
                width: '100%',
                // height: '100%',
                backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                overflow: 'scroll',
            }}
        >
            <Typography
                // variant="mainFont6"
                sx={{
                    mb: 2,

                    fontFamily: 'Nocturne Serif',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '40px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                }}
            >
                Add custom field
            </Typography>

            <Stack
                direction="column"
                spacing={2}
                sx={{
                    // mt: 2,
                    alignItems: 'center',
                }}
            >
                <TextField
                    label="Metadata Label"
                    value={customField}
                    onChange={value => {
                        setCustomField(value);
                    }}
                    wrapperSx={{
                        flexGrow: 1,
                        flexShrink: 0,
                    }}
                    sx={{}}
                />
                <Button
                    variant="irdbGray"
                    sx={{
                        // Don't let it try to be 100%
                        // width: 'auto',
                        // Don't let it totally colapse either
                        flexGrow: 1,

                        height: '56px',
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        onSelection(customField);
                    }}
                >
                    <i className="fa-regular fa-check"></i>
                    Add field
                </Button>
            </Stack>

            <Button
                type="submit"
                variant="irdbText"
                // autoCapitalize={false}
                sx={{
                    height: '56px',
                    textTransform: 'none',
                }}
                onClick={async () => {
                    onClose();
                }}
            >
                Cancel
            </Button>
        </Stack>
    );
}
