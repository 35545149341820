import React, { useState, useContext } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    CircularProgress,
} from '@mui/material';
import AdminContext from '../../contexts/AdminContext';
import useAuth from '../../hooks/useAuth';

interface AnalyticsData {
    Anonymous: { [key: string]: number }[];
    SignedUp: { [key: string]: number }[];
    Images: { [key: string]: number }[];
    Scans: { [key: string]: number }[];
}

const timePeriods = ['oneHour', 'sixHours', 'twelveHours', 'oneDay', 'sevenDays', 'thirtyDays', 'oneYear', 'total'];
const formatDisplayName = (name: string) => {
    const formattedName = name
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .replace(/^./, str => str.toUpperCase());
    return formattedName;
};

const formatNumber = (value: number | string): string => {
    if (typeof value === 'string') return value;
    return value.toLocaleString();
};

const calculateDifference = (v1: number, v2: number): string => {
    if (v1 === 0 && v2 === 0) return '-';

    const difference = v1 - v2;
    if (v1 === 0 || v2 === 0 || difference <= 0) return formatNumber(difference);

    const percentage = (v2 / v1) * 100;

    return `${formatNumber(difference)} (${percentage.toFixed(1)}%)`;
};

export default function AdminAnalytics() {
    const [data, setData] = useState<AnalyticsData | null>(null);
    const adminContext = useContext(AdminContext);
    const [isLocalLoading, setIsLocalLoading] = useState(false);

    useAuth(() => {
        async function loadData() {
            setIsLocalLoading(true);
            if (adminContext) {
                const analyticsData = await adminContext.fetchAnalyticsData();
                setData(analyticsData);
            } else {
                console.error('AdminContext is undefined');
            }
            setIsLocalLoading(false);
        }
        loadData();
    }, []);

    return data ?
            <TableContainer
                component={Paper}
                sx={{ maxWidth: { xs: '100%', sm: '95%', md: '90%' }, margin: 'auto', padding: 2, borderRadius: 4 }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="proVerifyFont2">Time Period</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="proVerifyFont2">Anonymous Users</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="proVerifyFont2">Members</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="proVerifyFont2">Difference(%)</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="proVerifyFont2">Scans Performed</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="proVerifyFont2">Images Uploaded</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="proVerifyFont2">Difference(%)</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timePeriods.map(period => (
                            <TableRow key={period}>
                                <TableCell>
                                    <Typography variant="notificationSettingInfo">
                                        {formatDisplayName(period)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="webAccountType">
                                        {formatNumber(data.Anonymous[0]?.[period] ?? '-')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="webAccountType">
                                        {formatNumber(data.SignedUp[0]?.[period] ?? '-')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="webAccountType">
                                        {calculateDifference(
                                            data.Anonymous[0]?.[period] ?? 0,
                                            data.SignedUp[0]?.[period] ?? 0,
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="webAccountType">
                                        {formatNumber(data.Scans[0]?.[period] ?? '-')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="webAccountType">
                                        {formatNumber(data.Images[0]?.[period] ?? '-')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="webAccountType">
                                        {calculateDifference(
                                            data.Scans[0]?.[period] ?? 0,
                                            data.Images[0]?.[period] ?? 0,
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        :   <CircularProgress />;
}
