import { isInRange } from './number';
import { isEqual } from './objects';

export const addOrRemove = (array: any[], value: any) => {
    const index = array.indexOf(value);

    if (index === -1) {
        array.push(value);
    } else {
        array.splice(index, 1);
    }
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
    if (!isInRange(startIndex, 0, list.length - 1) || !isInRange(endIndex, 0, list.length - 1)) {
        return list;
    }
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const merge = <T>(array1: T[], array2: T[], equalityCheck: (a: T, b: T) => boolean = isEqual): T[] => {
    const result = [...array1];
    const arr2Len = array2.length;
    for (let i = 0; i < arr2Len; i++) {
        const item = array2[i];
        for (let j = 0; j < result.length; j++) {
            if (equalityCheck(result[j], item)) {
                break;
            } else if (j === result.length - 1) {
                result.push(item);
            }
        }
    }
    return result;
};

export function replaceItem<T>(
    array: T[],
    produceNewValue: (index: number) => T,
    predicate: (item: T, index: number) => boolean,
    pushIfNotFound?: boolean,
) {
    const index = array.findIndex(predicate);
    const result = [...array];
    if (index === -1 && pushIfNotFound) {
        result.push(produceNewValue(index));
    } else {
        result[index] = produceNewValue(index);
    }

    return result;
}

export function removeItem<T>(array: T[], predicate: (item: T, index: number) => boolean) {
    const index = array.findIndex(predicate);
    if (index === -1) {
        return array;
    }

    const result = [...array];
    result.splice(index, 1);
    return result;
}

export function dedupe<T>(array: T[], equalityCheck: (a: T, b: T) => boolean = isEqual): T[] {
    return array.reduce<T[]>((acc, item) => {
        if (!acc.some(accItem => equalityCheck!(accItem, item))) {
            acc.push(item);
        }
        return acc;
    }, []);
}

export function getAtIndexNested<T>(arrays: Array<T[]>, index: number) {
    const combinedLength = arrays.reduce((acc, curr) => acc + curr.length, 0);
    if (!isInRange(index, 0, combinedLength - 1)) {
        return undefined;
    }
    let currentIndex = 0;
    for (let i = 0; i < arrays.length; i++) {
        const arr = arrays[i];
        if (index < currentIndex + arr.length) {
            return arr[index - currentIndex];
        }
        currentIndex += arr.length;
    }
}

declare global {
    interface Array<T> {
        unique(): Array<T>;
    }
}

Array.prototype.unique = function <T>(): Array<T> {
    return Array.from(new Set(this));
};
