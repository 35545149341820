import { useState, useRef, useEffect } from 'react';
import { Dialog, Stack, Typography, Button } from '@mui/material';
import TextField from '../../general/TextField';

export interface TwoFactorAuthDialogProps {
    open: boolean;
    title: string;
    phoneNumber: string;
    confirm: string;
    onComplete: (code: string) => void;
    onClose: () => void;
}

export default function TwoFactorAuthDialog({
    open,
    title,
    phoneNumber,
    confirm,
    onComplete,
    onClose,
}: TwoFactorAuthDialogProps): JSX.Element {
    // const [promise, setPromise] = useState<any>();
    // const [title, setTitle] = useState('');
    // const [confirm, setConfirm] = useState('');

    // const showTwoFactorAuthForm: TwoFactorAuthParams = (
    //     title,
    //     confirm,
    // ) => new Promise<string>((resolve, reject) => {
    //         setTitle(title);
    //         setConfirm(confirm);
    //         setPromise({ resolve, reject });
    //     });

    // const handleClose = () => {
    //     setPromise(undefined);
    // };

    // const handleConfirm = () => {
    //     promise?.resolve(true);
    //     handleClose();
    // };

    // const handleCancel = () => {
    //     promise?.resolve(false);
    //     handleClose();
    // };

    // const [open, setOpen] = useState(false);

    const inputRef = useRef([null, null, null, null, null, null] as Array<HTMLInputElement | null>);
    // const [code, setCode, codeRef] = useCapturedStateWorkaround('      ');
    const [code, setCode] = useState(['', '', '', '', '', '']);

    const cleanedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '').replace(/^1/, '');
    const maskedPhoneNumber = cleanedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '(***) ***-$3');
    const enteredCode = code.join('');

    useEffect(() => {
        if (!open) {
            setCode(['', '', '', '', '', '']);
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
            }}
            keepMounted={false}
            sx={{
                zIndex: 999999999999,
            }}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    p: 8,
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '32px',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.03em',
                        textAlign: 'left',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    Verification code sent to your phone number {maskedPhoneNumber}
                </Typography>
                {/* <InputLabel>Verification code</InputLabel> */}
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        display: 'flex',
                        alignContent: 'center',
                    }}
                >
                    {[0, 1, 2, 3, 4, 5].map(i => {
                        return (
                            <TextField
                                key={`${i}`}
                                inputRef={input => {
                                    inputRef.current[i] = input;
                                }}
                                value={code[i]}
                                onChange={value => {
                                    const newCode = [...code];
                                    newCode[i] = value.charAt(0);
                                    setCode(newCode);

                                    if (value && i < 5) {
                                        inputRef.current[i + 1]?.focus();
                                    }
                                }}
                                // onKeyDown={(event: React.KeyboardEvent) => {
                                //     if (event.key === 'Backspace' && i > 0) {
                                //         inputRef.current[i - 1]?.focus();
                                //     }
                                // }}
                                sx={{
                                    width: '50px',
                                }}
                            />
                        );
                    })}
                </Stack>
                <Button
                    variant="irdbGradient"
                    disabled={enteredCode.length < 6}
                    onClick={async () => {
                        onComplete(enteredCode);
                    }}
                >
                    {confirm}
                </Button>
                {/* <Button variant="irdbText" onClick={async () => {}}>
                    Didn't get a code?
                </Button> */}
            </Stack>
        </Dialog>
    );
}
