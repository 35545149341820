import { Box, Button, Stack, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { Color } from 'src/Color';
import FileDropArea from 'src/components/general/FileDropArea';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import useProducts from 'src/hooks/useProducts';

interface Props {
    campaignID: number;
}

export const Products = ({ campaignID }: Props) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const { products, loadProducts, clearProducts } = useProducts(campaignID);

    const onDrop = useCallback(async (files: any[]) => {
        loadProducts(files[0]);
    }, []);

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                p: 2,
            }}
        >
            {products.length > 0 ?
                <Button onClick={() => clearProducts()}>Clear Products</Button>
            :   <FileDropArea
                    dropzoneOptions={{
                        onDrop,
                        accept: { 'text/csv': ['.csv'] },
                    }}
                    dragInvalidText={null}
                    dragValidText={null}
                    className="dashboard-uploader"
                >
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            p: 4,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <i className="fa-light fa-images fa-2xl" style={{ color: Color.Purple }}></i>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',
                                textAlign: 'left',
                                color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            }}
                        >
                            Drop a CSV of Products here
                        </Typography>
                    </Stack>
                </FileDropArea>
            }

            <Stack direction="column" spacing={2}>
                {products.map(product => (
                    <Stack
                        key={product.index + product.name}
                        direction="row"
                        spacing={2}
                        sx={{
                            p: 2,
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                        }}
                    >
                        <Box
                            component="img"
                            src={product.imageUrl}
                            sx={{
                                flexShrink: 0,
                                width: { xs: '100%', sm: 140 },
                                height: { xs: 200, sm: 140 },
                                objectFit: 'contain',
                                backgroundColor: Color.White,
                            }}
                            loading="lazy"
                        />
                        <Stack
                            direction="column"
                            sx={{
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    // flexGrow: 1,
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '1.25em',
                                    fontWeight: 400,
                                    lineHeight: '1.25em',
                                    wordBreak: 'break-word',
                                    textOverflow: 'ellipsis',

                                    maxHeight: '3.75em', // 3x line height, seems to work...
                                    overflow: 'hidden',
                                    msTextOverflow: 'ellipsis',
                                    // fontStyle: isUntitled ? 'italic' : undefined,
                                    pr: 1,
                                }}
                            >
                                {product.index + 1}. {product.name}
                            </Typography>
                            <a
                                href={product.url}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                                }}
                            >
                                {product.url}
                            </a>
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};
