export const findRejected = (results: PromiseSettledResult<any>[]) =>
    results.filter(r => r.status === 'rejected') as PromiseRejectedResult[];

export const getRejectReasonString = (result: PromiseRejectedResult): string => {
    if (!result.reason) {
        return 'Unknown error';
    }
    if (result.reason instanceof Error) {
        return result.reason.message.trim();
    }
    return result.reason.toString().trim();
};

export const flattenRejectReasons = (errors: PromiseRejectedResult[]): PromiseRejectedResult[] => {
    return errors.reduce<PromiseRejectedResult[]>((prev, curr) => {
        if (Array.isArray(curr.reason)) {
            return [...prev, ...flattenRejectReasons(curr.reason)];
        }
        return [...prev, curr];
    }, []);
};
// counts the number of times an error appears in the list of errors and returns a string with all the errors and their counts
// e.g. "Error A (2 files), Error B (1 file)"
export const getPromiseRejectErrorString = (errors: PromiseRejectedResult[]) =>
    // TODO: Figure out a better way to handle this
    errors
        .map(err => getRejectReasonString(err))
        .reduce<[string, number][]>((prev, curr) => {
            const index = prev.findIndex(([error]) => error === curr);
            if (index === -1) {
                return [...prev, [curr, 1]];
            }
            prev[index][1]++;
            return prev;
        }, [])
        .map(([error, count]) => `${error} ${count > 1 ? `(${count} files)` : ''}`)
        .join(', ');
