import React, { FC, createContext, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { getDesignTokens } from '../Theme';
import { CssBaseline, useMediaQuery, useTheme } from '@mui/material';

export interface TTheme {
    // This is xs, but more readable
    isMobile: boolean;
    isSmall: boolean;
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
}

interface Props {
    children: React.ReactNode;
    // TODO: What was this for?
    // theme?: 'darkMode' | 'lightMode';
}

export const ThemeProvider: FC<Props> = ({ children }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode, setDarkMode] = useLocalStorage('darkMode', prefersDarkMode);
    const muiTheme = useMemo(() => createTheme(getDesignTokens(darkMode ? 'dark' : 'light')), [darkMode]);

    return (
        <ThemeContext.Provider
            value={{
                isMobile,
                isSmall,
                darkMode,
                setDarkMode,
            }}
        >
            <MuiThemeProvider theme={muiTheme}>
                <CssBaseline />
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
};

const ThemeContext = createContext<TTheme | undefined>(undefined);

export default ThemeContext;
