import { Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useState } from 'react';
import TextField from '../general/TextField';
import { Color } from '../../Color';
import { TDescription } from '../../types/MetaTypes';
import { nullUndefinedOrEmpty } from '../../util/string';

const Description = ({ metaField }: MetaFieldProps) => {
    const content = metaField?.metaContent as TDescription | undefined;
    const [showMore, setShowMore] = useState(false);

    if (!content || nullUndefinedOrEmpty(content?.description)) {
        return null;
    }

    const cutOff = 250;
    const wiggleRoom = 50;

    return (
        <Typography
            variant="metaDataValue"
            sx={{
                py: 1,
                px: 3,

                // Wrapped text was busting into the padding
                boxDecorationBreak: 'clone',
            }}
        >
            {showMore || content.description.length < cutOff + wiggleRoom ?
                content.description
            :   `${content.description.substring(0, cutOff)}...`}
            {content.description.length >= cutOff + wiggleRoom && (
                <span
                    style={{
                        cursor: 'pointer',
                        color: Color.Purple,
                    }}
                    onClick={() => setShowMore(!showMore)}
                >
                    {showMore ? ' See less' : ' See more'}
                </span>
            )}
        </Typography>
    );
};

const DescriptionForm = ({ metaField, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TDescription;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                justifyItems: 'center',
                px: 2,
            }}
        >
            <TextField
                label="Description"
                placeholder="Description"
                value={content?.description ?? ''}
                onChange={value => handleChange({ name: 'description', value })}
                rows={3}
                wrapperSx={{
                    flexGrow: 1,
                }}
            />
        </Stack>
    );
};

export { Description, DescriptionForm };
