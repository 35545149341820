import { Button, Dialog, Stack, Typography } from '@mui/material';
import { Image } from 'src/contexts/MediaContext';

export interface IRCodeOwnedDialogProps {
    open: boolean;
    onCancel: () => void;
    handleViewExisting?: (image: Image) => void;
    image?: Image;
    ownedByUser?: boolean;
}

const IRCodeOwnedDialog = ({ open, onCancel, handleViewExisting, image, ownedByUser }: IRCodeOwnedDialogProps) => {
    if (!image) {
        return null;
    }

    const message =
        ownedByUser ?
            'You have already registered an IRCODE with the same image.'
        :   'This IRCODE has already been registered by someone else.';
    const buttonProps =
        handleViewExisting ?
            { onClick: () => handleViewExisting(image) }
        :   {
                href: `/dashboard/${ownedByUser ? 'myircodes' : 'ircodes'}/${image.imageID}`,
                target: '_blank',
                rel: 'noopener noreferrer',
            };

    return (
        <Dialog
            open={open}
            sx={{
                zIndex: Number.MAX_SAFE_INTEGER,
                '& .MuiDialog-paper': {
                    minWidth: '30vw',
                    p: 4,
                    borderRadius: 2,
                },
            }}
        >
            <Stack direction="column" spacing={2}>
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '32px',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.03em',
                        textAlign: 'left',
                    }}
                >
                    IRCode already owned
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    {message}
                </Typography>
                <img
                    style={{
                        maxHeight: '50vh',
                        width: 'auto',
                        objectFit: 'contain',
                    }}
                    src={image.imageUrl}
                    alt="IRCode"
                />
                <Button
                    {...buttonProps}
                    variant="irdbGradient"
                    sx={{
                        height: 56,
                    }}
                >
                    View Existing Image
                </Button>
                <Button
                    variant="irdbGray"
                    sx={{
                        height: 56,
                    }}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </Stack>
        </Dialog>
    );
};

export default IRCodeOwnedDialog;
