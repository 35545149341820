import React, { useContext, useEffect } from 'react';
import { Button, Container, Stack, Typography } from '@mui/material';
import { Color } from '../../Color';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { useProfileActions, useProfileInfo } from 'src/stores/useProfileState';
import ProfileInfoForm from '../shared/Profile/ProfileInfoForm';
import UserAvatarForm from '../shared/Profile/UserAvatarForm';
import ChangePasswordForm from '../shared/Profile/ChangePasswordForm';

type EditProfileProps = {
    onClose: () => void;
};

const EditProfile: React.FC<EditProfileProps> = ({ onClose }) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { onSave, onDeleteUser, updateInfoFromCurrentUser, clearInfo } = useProfileActions();
    const isSaveDisabled = useProfileInfo(
        state =>
            !!(
                state.reauthenticationRequired &&
                !state.currentPassword &&
                (state.newPassword || state.confirmNewPassword)
            ),
    );

    useEffect(() => {
        updateInfoFromCurrentUser();
        return clearInfo;
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const didSave = await onSave();
            if (didSave) {
                onClose();
            }
        } catch (error) {
            console.error('Failed to update profile:', error);
        }
    };

    return (
        <Container
            sx={{
                backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderLightMode,
                borderRadius: '0 0 4 4',
                py: 1,
                flexGrow: 1,
            }}
        >
            <Stack textAlign="center" direction="row" justifyContent="space-between">
                <Button onClick={onClose} sx={{ textTransform: 'none', px: 1 }} variant="irdbText">
                    Cancel
                </Button>
                <Typography variant="webHeadlineSmall" textAlign="center" lineHeight={2}>
                    Edit Profile
                </Typography>
                <Button
                    disabled={isSaveDisabled}
                    type="submit"
                    form="edit-profile-form"
                    variant="irdbText"
                    sx={{ textTransform: 'none', px: 1, fontWeight: 700 }}
                >
                    Save
                </Button>
            </Stack>

            <UserAvatarForm mb={3} />
            <ProfileInfoForm id="edit-profile-form" mobileView onSubmit={handleSubmit} />
            <ChangePasswordForm mobileView mb={2} />
            <Button color="error" type="button" fullWidth onClick={onDeleteUser}>
                Delete account
            </Button>
        </Container>
    );
};

export default EditProfile;
