import { MouseEvent } from 'react';
import { IconButton as MuiIconButton, SxProps, Theme, Tooltip } from '@mui/material';
import { Color } from '../../Color';

interface Props {
    icon: string;
    tooltip?: string;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    sx?: SxProps<Theme>;
    disabled?: boolean;
}

export const IconButton = ({ icon, tooltip, onClick, sx, disabled }: Props) => {
    return (
        <Tooltip title={tooltip}>
            <MuiIconButton
                disabled={disabled}
                sx={{
                    backgroundColor: '#DBDBEB !important',
                    width: '1.42em',
                    height: '1.42em',
                    borderRadius: '50%',
                    transition: 'background-color 0.3s',
                    '&:hover': {
                        backgroundColor: '#C4C4D9 !important',
                    },
                    ...sx,
                }}
                onClick={onClick}
            >
                <i className={`fa-solid ${icon} fa-2xs`} style={{ color: Color.Black }}></i>
            </MuiIconButton>
        </Tooltip>
    );
};
