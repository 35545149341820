// 1000 * 60 * 60 * 24
const DAY_IN_MS = 86400000;
// 1000 * 60 * 60
const HOUR_IN_MS = 3600000;
// 1000 * 60
const MINUTE_IN_MS = 60000;
const SECOND_IN_MS = 1000;

export interface Timespan {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    ms: number;
}

export default function calculateTimespan(ms: number): Timespan {
    const days = Math.floor(ms / DAY_IN_MS);
    ms -= days * DAY_IN_MS;

    const hours = Math.floor(ms / HOUR_IN_MS);
    ms -= hours * HOUR_IN_MS;

    const minutes = Math.floor(ms / MINUTE_IN_MS);
    ms -= minutes * MINUTE_IN_MS;

    const seconds = Math.floor(ms / SECOND_IN_MS);
    ms -= seconds * SECOND_IN_MS;

    return {
        days,
        hours,
        minutes,
        seconds,
        ms,
    };
}
