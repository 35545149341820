import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Color } from '../../Color';

export const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    // backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    backgroundColor: theme.palette.mode === 'dark' ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));
