import { FC, createContext } from 'react';
import { Image, PagedResults } from './MediaContext';
import useApi, { Method } from 'src/hooks/useApi';
import { Semaphore } from 'async-mutex';
import { User } from './UserContext';
import { ImageSortByField, ImageSortOrderField } from '../types/Image';

export enum Permission {
    None = 'None',
    User = 'User',
    SuperAdmin = 'SuperAdmin',
}

const flagsForPermission = (permission: Permission): Record<string, number> => {
    // "superUser": 0,
    // "editAllowed": 1,
    // "createCampaign": 0,
    // "removeAllowed": 0,
    // "transferAllowed": 0,
    // "transferNoAsk": 0,
    // "abilityToSetFlags": 0,

    switch (permission) {
        case Permission.None:
            return {};
        case Permission.User:
            return {
                editAllowed: 1,
            };
        case Permission.SuperAdmin:
            return {
                superUser: 1,
                editAllowed: 1,
                // createCampaign: 1,
                removeAllowed: 1,
                transferAllowed: 1,
                transferNoAsk: 1,
            };
        default:
            return {};
    }
};

export const DefaultCampaignImageUrl =
    'https://firebasestorage.googleapis.com/v0/b/ircode-1a662.appspot.com/o/campaigns%2Fdownload.jpeg?alt=media&token=1c4d62de-4308-424f-b4ee-2c0679285957';

export interface Campaign {
    campaignID: number;
    campaignName: string;
    campaignDescription: string;
    campaignImageUrl?: string;
    campaignStatus?: string;
    created?: number;
    members?: number;
    media?: number;
    user?: User[];
}

// TODO: Can I make this global? Result<T>
export interface Result<T> {
    Completed: boolean;
    Results: T;
    Environment: string;
    GCFVersion: string;
}

export interface TEnterprise {
    fetch(): Promise<PagedResults<Campaign[]>>;
    load(campaignID: number): Promise<Campaign>;
    create(campaign: Omit<Campaign, 'campaignID'>): Promise<any>;
    update(campaign: Campaign): Promise<any>;
    deleteCampaign(campaignID: number): Promise<any>;
    fetchImages(
        campaignID: number,
        page?: number,
        limit?: number,
        sortBy?: ImageSortByField,
        sortOrder?: ImageSortOrderField,
    ): Promise<PagedResults<Image[]>>;
    removeImage(imageID: string): Promise<any>;
    addImage(campaignID: number, imageID: string): Promise<any>;
    users(campaignID: number): Promise<any>;
    invite(campaignID: number, email: string, permission: Permission): Promise<any>;
    updatePermission(
        campaignID: number,
        userID: number,
        controllingUserID: number,
        permission: Permission,
    ): Promise<any>;
    deletePermission(id: number, campaignID: number, userID: number, controllingUserID: number): Promise<any>;
}

interface Props {
    children: React.ReactNode;
}

export const EnterpriseProvider: FC<Props> = ({ children }) => {
    const { request } = useApi();

    const fetch = async (): Promise<PagedResults<Campaign[]>> => {
        const response = await request({
            method: Method.GET,
            path: `/Campaign`,
        });
        // console.log("response", response);

        return {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Campaigns,
        };
    };

    const load = async (campaignID: number): Promise<Campaign> => {
        const response = await request({
            method: Method.GET,
            path: `/Campaign/${campaignID}`,
        });
        // console.log("response", response);

        if (response.data.Results.error) {
            throw new Error(response.data.Results.error);
        }
        if (response.data.Results.Campaigns.length === 0) {
            throw new Error(`Campaign ${campaignID} not found`);
        }

        return response.data.Results.Campaigns[0];
    };

    const create = async (campaign: Omit<Campaign, 'campaignID'>): Promise<string> => {
        try {
            const response = await request({
                method: Method.POST,
                path: `/Campaign`,
                data: {
                    name: campaign.campaignName,
                    description: campaign.campaignDescription,
                    campaignImageUrl: campaign.campaignImageUrl,
                    campaignStatus: campaign.campaignStatus,
                },
            });
            // console.log("response", response);
            if (response.data.Results.error === 'duplicate name') {
                throw new Error('Campaign name already exists. Please choose a different name.');
            }

            return response.data.Results.campaignID;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const update = async (campaign: Campaign): Promise<any> => {
        const response = await request({
            method: Method.PUT,
            path: `/Campaign`,
            data: {
                campaignID: campaign.campaignID,
                name: campaign.campaignName,
                description: campaign.campaignDescription,
                campaignImageUrl: campaign.campaignImageUrl,
                campaignStatus: campaign.campaignStatus,
            },
        });
        // console.log("response", response);
        if (response.data.Results.error === 'duplicate name') {
            throw new Error('Campaign name already exists. Please choose a different name.');
        }

        return {
            Completed: true,
            Results: response.data,
        };
    };

    const deleteCampaign = async (campaignID: number): Promise<any> => {
        const response = await request({
            method: Method.DELETE,
            path: `/Campaign/${campaignID}`,
        });
        // console.log("response", response);

        return {
            Completed: true,
            Results: response.data,
        };
    };

    const fetchImages = async (
        campaignID: number,
        page: number = 0,
        limit: number = 25,
        sortBy: ImageSortByField = 'internalID',
        sortOrder: ImageSortOrderField = 'DESC',
    ): Promise<PagedResults<Image[]>> => {
        const response = await request({
            method: Method.GET,
            path: `/Campaign/IRCODES/${campaignID}?offset=${page * limit}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
        });

        return {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Images,
        };
    };

    const removeImage = async (imageID: string): Promise<any> => {
        const response = await request({
            method: Method.DELETE,
            path: `/Campaign/IRCODE/${imageID}`,
        });
        // console.log("response", response);

        return {
            Completed: true,
            Results: response.data,
        };
    };

    const addImageSemaphore = new Semaphore(3);
    const addImage = async (campaignID: number, imageID: string): Promise<any> => {
        const [value, release] = await addImageSemaphore.acquire();

        const response = await request({
            method: Method.POST,
            path: `/Campaign/IRCODE`,
            data: {
                imageID,
                campaignID,
            },
        });
        // console.log("response", response);

        release();

        return {
            Completed: true,
            Results: response.data,
        };
    };

    const users = async (campaignID: number): Promise<PagedResults<any[]>> => {
        const response = await request({
            method: Method.GET,
            path: `/AccountManagement/${campaignID}`,
        });

        // "superUser": 0,
        // "editAllowed": 1,
        // "createCampaign": 0,
        // "removeAllowed": 0,
        // "transferAllowed": 0,
        // "transferNoAsk": 0,
        // "abilityToSetFlags": 0,

        response.data.Results.List = response.data.Results.List.map((user: any) => {
            let permission = Permission.None;

            if (user.superUser === 1) {
                permission = Permission.SuperAdmin;
            } else if (user.editAllowed === 1) {
                permission = Permission.User;
            }

            return {
                ...user,
                permission,
            };
        });

        console.log('response', response);

        return {
            ...response.data,
            Results: response.data.Results.List,
        };
    };

    const invite = async (campaignID: number, email: string, permission: Permission): Promise<any> => {
        // curl -i -X POST ‘https://us-central1-ircode-1a662.cloudfunctions.net/development-v1_28/AccountManagement' \
        // -H "Content-Type: application/json" \
        // -H "testuserid: 1200" \
        // -d '{
        //   "email": "steve@axelrod.net",
        //   "userID": 0,
        //   "campaignID": 0,
        //   "imageID": "091B7B4194A84F3885855F9FE0CECFC5",
        //   "editAllowed": 1,
        //   "removeAllowed": 0,
        //   "transferAllowed": 0,
        //   "abilityToSetFlags": 0,
        //   "transferNoAsk": 0,
        //   "expirationDate": 0,
        //   "superUser": 0,
        //   "createCampaign": 0
        // }'

        const response = await request({
            method: Method.POST,
            path: `/AccountManagement`,
            data: {
                campaignID,
                email,
                ...flagsForPermission(permission),
            },
        });
        console.log('response', response);

        if (response.data.Completed) {
            return {
                // Completed: true,
                // Results: response.data,
            };
        } else {
            throw new Error(response.data.Results.error);
        }
    };

    const updatePermission = async (
        campaignID: number,
        userID: number,
        controllingUserID: number,
        permission: Permission,
    ): Promise<any> => {
        // "controllingUserID": 4634,
        // "userID": 1200,
        // "campaignID": 0,
        // "imageID": "3269E4BEBD214E1EA1B45F86DFB06C38",
        // "editAllowed": 0,
        // "removeAllowed": 0,
        // "transferAllowed": 0,
        // "abilityToSetFlags": 0,
        // "transferNoAsk": 0,
        // "expirationDate": 0,
        // "superUser": 0

        const response = await request({
            method: Method.PUT,
            path: `/AccountManagement`,
            data: {
                campaignID,
                userID,
                // controllingUserID: userID,
                ...flagsForPermission(permission),
            },
        });
    };

    const deletePermission = async (
        id: number,
        campaignID: number,
        userID: number,
        controllingUserID: number,
    ): Promise<any> => {
        // "amID": 1784,
        // "controllingUserID": 612,
        // "userID": 4634,
        // "campaignID": 0,
        // "imageID": "F469B6F935414A84811C3BCFB7FB1EB8"

        const response = await request({
            method: Method.DELETE,
            path: `/AccountManagement`,
            data: {
                amID: id,
                campaignID,
                userID,
                controllingUserID,
            },
        });
        console.log('response', response);

        return {
            Completed: true,
            Results: response.data,
        };
    };

    return (
        <EnterpiseContext.Provider
            value={{
                fetch,
                load,
                create,
                update,
                deleteCampaign,
                fetchImages,
                removeImage,
                addImage,
                users,
                invite,
                updatePermission,
                deletePermission,
            }}
        >
            {children}
        </EnterpiseContext.Provider>
    );
};

const EnterpiseContext = createContext<TEnterprise | undefined>(undefined);

export default EnterpiseContext;
