import { Box, Chip, Grid, IconButton, InputLabel, TextField as MuiTextField, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext, useState } from 'react';
import { MetaDataLabel, MetaDataRow, MetaDataValue } from '../../../../theme/units';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { Color } from '../../../../Color';
import { TTags } from '../../../../types/MetaTypes';

const Tags = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TTags | undefined;

    if (!content || content.tags.length === 0) {
        return null;
    }

    return (
        <>
            <Grid item container direction="row" {...MetaDataRow} sx={{ padding: 0 }}>
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Tags
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue} sx={{}}>
                    {content.tags.map((option: string, index: number) => (
                        <Chip
                            key={`${option}-${index}`}
                            variant="outlined"
                            label={option}
                            // {...getTagProps({ index })}
                            // color="primary"
                            deleteIcon={<i className="fa-solid fa-x fa-2xs"></i>}
                            sx={{
                                borderRadius: '2px',
                                borderColor: 'transparent',
                                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                marginRight: '4px',
                                marginBottom: '4px',
                                fontFamily: 'Nunito Sans',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '16px',
                                letterSpacing: '1.8px',
                                textTransform: 'uppercase',
                            }}
                        />
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

const TagsForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TTags;
    const [currentTag, setCurrentTag] = useState('');

    const addTag = (tag: string) => {
        const tags = content?.tags ?? [];
        const newSelectedTags = Array.from(new Set(tags.concat([tag])));
        onChange?.({
            tags: newSelectedTags,
        });
    };

    const removeTag = (tag: string) => {
        const tags = content?.tags ?? [];
        const newSelectedTags = tags.filter(splitTag => splitTag !== tag);
        onChange?.({
            tags: newSelectedTags,
        });
    };

    return (
        <Stack direction="row" spacing={2}>
            <Box
                sx={{
                    flexGrow: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }}
            >
                <InputLabel
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '10px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        letterSpacing: '0.15em',
                        // TODO: Color.ts
                        color: darkMode ? '#E6E6E6' : '#12121A99',
                        textTransform: 'uppercase',
                    }}
                >
                    Tags
                </InputLabel>
                {(content?.tags ?? []).map((option: string, index: number) => (
                    <Chip
                        key={`${option}-${index}`}
                        variant="outlined"
                        label={option}
                        deleteIcon={<i className="fa-solid fa-xmark"></i>}
                        onDelete={() => removeTag(option)}
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '16px',
                            letterSpacing: '1.8px',
                            mr: 1,
                            mb: 1,
                            py: 2.3,
                            borderRadius: '2px',
                            borderColor: 'transparent',
                            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                        }}
                    />
                ))}
                <MuiTextField
                    type="string"
                    value={currentTag}
                    placeholder="Add tag"
                    onKeyDown={event => {
                        switch (event.key) {
                            case 'Enter':
                            case ' ':
                                // console.log('Space or Enter')
                                event.preventDefault();
                                if (currentTag.trim().length === 0) {
                                    return;
                                }

                                addTag(currentTag.trim().toUpperCase());
                                setCurrentTag('');
                                break;
                            default:
                                break;
                        }
                    }}
                    onChange={event => {
                        const value = event.target.value;
                        setCurrentTag(value);
                    }}
                    sx={{
                        '.MuiOutlinedInput-root': {
                            p: 0,
                        },
                        '.MuiOutlinedInput-input': {
                            p: 1,
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                        },
                    }}
                />
            </Box>
            {onCopy && (
                <IconButton
                    sx={{
                        fontSize: '1rem',
                    }}
                    onClick={() => {
                        onCopy(content);
                    }}
                >
                    <i className="fa-regular fa-copy"></i>
                </IconButton>
            )}
        </Stack>
    );
};

export { Tags, TagsForm };
