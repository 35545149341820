import createImageOperationsSlice, {
    imageOperationsComputedSelectors,
    ImageOperationsComputedState,
    ImageOperationsSlice,
} from './slices/imageOperationsSlice';
import createProgressSlice, { ProgressSlice } from './slices/progressSlice';
import createEventHandlersSlice, { EventHandlersSlice } from './slices/eventHandlersSlice';
import { createStore } from 'zustand/vanilla';
import { TMeta } from '../../contexts/MetaContext';
import { TMedia } from '../../contexts/MediaContext';
import PromiseQueue from '../../util/classes/PromiseQueue';
import OperationsTimeEstimation from '../../util/classes/OperationsTimeEstimation';
import { TFeedback } from '../../contexts/FeedbackContext';
import { flattenRejectReasons, getPromiseRejectErrorString, getRejectReasonString } from '../../util/promise';
import GeneralErrorMessages from '../../constants/generalErrorMessages.json';
import { SetMetaErrors } from '../../hooks/metaErrors/useMetaErrors';
import { createComputedState } from '../../util/zustand';

export interface BulkUploaderComputedState extends ImageOperationsComputedState {}

export interface BulkUploaderBaseState extends ImageOperationsSlice, ProgressSlice, EventHandlersSlice {}

export interface BulkUploaderStore extends BulkUploaderBaseState, BulkUploaderComputedState {}

export interface BulkUploaderStoreDependencies {
    Meta: TMeta;
    Media: TMedia;
    Feedback: TFeedback;
    setMetaErrors: SetMetaErrors;
}

export const getBulkUploadErrorMessage = (errors: PromiseRejectedResult[]) => {
    return getPromiseRejectErrorString(
        flattenRejectReasons(errors).filter(err => getRejectReasonString(err) !== GeneralErrorMessages.PromiseAborted),
    );
};

export const createBulkUploaderStore = ({ Meta, Media, Feedback, setMetaErrors }: BulkUploaderStoreDependencies) =>
    createStore<BulkUploaderStore>()((...args) => {
        const [set, get, storeApi] = args;
        const promiseQueue = new PromiseQueue<void, PromiseRejectedResult[]>(async (results, errors) => {
            const { notify } = Feedback;
            // replaces the 'processFiles' call's 'finally' block
            console.log('All finished');
            set({
                processingCount: 0,
                processedCount: 0,
                isProcessing: false,
                isCanceling: false,
            });
            operationsTimeEstimation.stopEstimation();
            promiseQueue.clearQueue();
            const flattenedErrors = errors.flat();
            const errorMessage = getBulkUploadErrorMessage(flattenedErrors);
            if (errorMessage) {
                await notify('Upload Error', errorMessage);
            }
            // If all files failed, clear image operations
            const { imageOperationsCount, clearImageOperations } = get();
            if (flattenedErrors.length === imageOperationsCount) {
                clearImageOperations();
            }
        });
        const operationsTimeEstimation = new OperationsTimeEstimation(estimation =>
            get().setTimeEstimation(estimation),
        );
        const baseState: BulkUploaderBaseState = {
            ...createImageOperationsSlice(...args, {
                Meta,
                Media,
                Feedback,
                promiseQueue,
                operationsTimeEstimation,
                setMetaErrors,
            }),
            ...createProgressSlice(...args),
            ...createEventHandlersSlice(...args, { Feedback }),
        };
        return createComputedState<BulkUploaderBaseState, BulkUploaderComputedState>(
            baseState,
            storeApi,
            imageOperationsComputedSelectors,
        );
    });
