import { Grid, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext } from 'react';
import { MetaDataLabel, MetaDataValue } from '../../../../theme/units';
import TextField from '../../../general/TextField';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { Color } from '../../../../Color';
import { TSize } from '../../../../types/MetaTypes';

const Size = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TSize | undefined;

    if (!content) {
        return null;
    }

    return (
        <>
            <Grid item container direction="row">
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Size
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue}>
                    <Typography variant="metaDataValue" sx={{}}>
                        {content.size}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const SizeForm = ({ metaField, onChange, onCopy }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TSize;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                justifyItems: 'center',
            }}
        >
            <TextField
                label="Size"
                value={content?.size ?? ''}
                onChange={value => handleChange({ name: 'size', value })}
                onCopy={
                    onCopy ?
                        () => {
                            onCopy(content);
                        }
                    :   undefined
                }
                wrapperSx={{
                    flexGrow: 1,
                }}
            />
        </Stack>
    );
};

export { Size, SizeForm };
