import {
    getLinksErrorsGeneric,
    validateAndNormalizeUrl,
    LinkErrors,
    LinksListErrors,
    LinkListValidatorOptions,
} from './linkUtils';
import { ValidationResult } from '../../types/MetaValidation';
import { ILink, TLink } from '../../types/Link';

export type ILinkErrors = LinkErrors<ILink>;
export type TLinkErrors = LinksListErrors<ILink>;
export type TLinkValidatorOptions = LinkListValidatorOptions<ILink>;

function getLinkErrors(link: ILink, fields?: Array<keyof ILink>): ValidationResult<ILink, ILinkErrors> {
    const linkError: ILinkErrors = {};
    const results = { ...link };
    let isInvalid = false,
        validateUrl = true;

    if (fields) {
        validateUrl = fields.includes('url');
    }

    if (validateUrl) {
        const urlValidation = validateAndNormalizeUrl(link.url);
        if (urlValidation.errors) {
            linkError.url = urlValidation.errors;
            isInvalid = true;
        } else {
            results.url = urlValidation.results!;
        }
    }
    if (isInvalid) {
        return { errors: linkError };
    }
    return { results };
}

function shouldFilterAndRemoveLink(link: ILink, _: LinkErrors<ILink>): boolean {
    return !link.title && !link.url;
}

export function getLinksErrors(link: TLink, options?: TLinkValidatorOptions): ValidationResult<TLink, TLinkErrors> {
    return getLinksErrorsGeneric(link, getLinkErrors, shouldFilterAndRemoveLink, options);
}
