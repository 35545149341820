import React, { useContext, useState } from 'react';
import {
    Stack,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import { Color } from '../../Color';

interface ModAdminProps {
    initialValue: string;
    handleAdminChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setOpenAdminModDialog: (open: boolean) => void;
    setSelectedUserId: React.Dispatch<React.SetStateAction<number | null>>;
    setSelectedUserFBId: React.Dispatch<React.SetStateAction<string>>;
    selectedUser: UserSearchResults | null;
    incomingValue: string;
}

export default function ModAdmin({ initialValue, setOpenAdminModDialog, selectedUser, incomingValue }: ModAdminProps) {
    const { modifyAdminType, analyticsRefresh } = useContext(AdminContext) as TAdmin;
    const [value, setValue] = useState(initialValue);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const isSubmitDisabled = value === incomingValue;

    const handleSubmit = async () => {
        if (isSubmitDisabled) {
            alert(`${selectedUser?.userName} is already an ${value}.`);
        } else {
            setOpenConfirmationDialog(true);
        }
    };

    const handleConfirmation = async () => {
        if (selectedUser && selectedUser.userID && selectedUser.FBuserID) {
            const adminType =
                value === 'support' ? 'support'
                : value === 'admin' ? 'admin'
                : 'none';
            const updated = await modifyAdminType(selectedUser.userID, selectedUser.FBuserID, adminType);
            if (updated) {
                console.log('Admin type updated successfully.', adminType);
                analyticsRefresh();
                alert('Admin type updated successfully.');
            } else {
                console.error('Failed to update admin type.', selectedUser.userID, selectedUser.FBuserID, adminType);
            }
            setOpenAdminModDialog(false);
            setOpenConfirmationDialog(false);
        } else {
            console.error('Selected user ID or Firebase ID is null.');
        }
    };

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                m: 'auto',
                p: 4,
                borderRadius: '8px',
                backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                boxShadow: 1,
            }}
        >
            <FormControl>
                <FormLabel sx={{ color: Color.PrimaryLavender, variant: 'mainFont9' }}>
                    Upgrade/Downgrade Support/Admin
                </FormLabel>
                <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                    Current Admin Type: {incomingValue}
                </Typography>
                <RadioGroup value={value} onChange={e => setValue(e.target.value)}>
                    <FormControlLabel
                        value="none"
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                None
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value="support"
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Support
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value="admin"
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Admin
                            </Typography>
                        }
                    />
                </RadioGroup>
                <Button
                    onClick={handleSubmit}
                    sx={{
                        color: Color.White,
                        backgroundColor: Color.PrimaryLavender,
                        '&:hover': { backgroundColor: Color.PalePurple },
                    }}
                >
                    Submit
                </Button>
            </FormControl>
            <Button
                onClick={() => setOpenAdminModDialog(false)}
                sx={{
                    color: Color.White,
                    backgroundColor: Color.PrimaryLavender,
                    '&:hover': { backgroundColor: Color.PalePurple },
                }}
            >
                Close
            </Button>

            <Dialog open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
                <DialogTitle>Confirm Admin Type Change</DialogTitle>
                <DialogContent>
                    <Typography>
                        Upgrade {selectedUser?.userName} to {value}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmation}>Yes</Button>
                    <Button onClick={handleCloseConfirmationDialog}>No</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
