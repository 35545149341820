const hashString = (str: string): number => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};

const seededRandom = (seed: number) => {
    const x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
};

export const randomColor = (seed?: string) => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    const seedValue = seed ? hashString(seed) : Math.random();

    for (let i = 0; i < 6; i++) {
        const randomValue = seed ? seededRandom(seedValue + i) : Math.random();
        color += letters[Math.floor(randomValue * 16)];
    }

    return color;
};
