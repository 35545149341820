import { createContext, FC } from 'react';
import { Image, PagedResults } from './MediaContext';
import {
    CardType,
    MetaContent,
    MetaType,
    TArtistName,
    TCardType,
    TCustom,
    TDescription,
    TDisplayLocation,
    TEmail,
    TGalleryName,
    TMedium,
    TPhone,
    TPrice,
    TProvenance,
    TSize,
    TTags,
    TTitle,
    TYear,
} from '../types/MetaTypes';
import { nullUndefinedOrEmpty } from '../util/string';
import useApi, { Method } from 'src/hooks/useApi';
import { TLink } from '../types/Link';
import { TProductLink } from '../types/ProductLink';
import { ImageSortByField, ImageSortOrderField } from '../types/Image';
import { MetaField } from '../types/MetaField';
import { useSaveMeta } from 'src/hooks/meta/useSaveMeta';
import { MetaErrors, MetaValidatorOptions, ValidationResult } from '../types/MetaValidation';
import { getProductLinksErrors } from '../util/metaValidation/productLink';
import { getLinksErrors } from '../util/metaValidation/link';

export interface MetaData {
    Meta: MetaField[];
    EditingAllowed: boolean;
}

export const EmptyMetaData: MetaData = {
    Meta: [],
    EditingAllowed: false,
};

// TODO: Can I make this global? Result<T>
export interface Result<T> {
    Completed: boolean;
    Results: T;
    Environment: string;
    GCFVersion: string;
}

export interface TMeta {
    fetch(imageId: string, filterEmpty?: boolean): Promise<MetaData>;
    save(
        imageId: string,
        data: MetaField[],
        onProgress?: (status: string) => void,
        abortController?: AbortController,
        twoFA?: string,
    ): Promise<void>;
    search(query: string, owner?: boolean): Promise<PagedResults<Image[]>>;
    requestEdit(imageId: string): Promise<void>;
}

// TODO: Genericize
// export function metaFieldForMetaType(meta: MetaField[], metaType: MetaType): MetaField | undefined {
export function metaFieldForMetaType(meta: MetaField[], metaType: MetaType): MetaField | undefined {
    const metaField = meta.find(m => m.metaType === metaType);
    if (metaField === undefined && metaType === MetaType.CardType) {
        // CardType is required, General is the default
        return {
            key: crypto.randomUUID(),
            metaID: 0,
            metaType: MetaType.CardType,
            metaContent: {
                cardType: CardType.General,
            } as TCardType,
        };
    }

    return metaField;
}

// TODO: Genericize
export function metaContentForMetaType(image: Image, metaType: MetaType): MetaContent | undefined {
    const meta = image.metaArray?.find(meta => meta.metaType === metaType);
    if (meta === undefined && metaType === MetaType.CardType) {
        // CardType is required, General is the default
        return {
            cardType: CardType.General,
        } as TCardType;
    }

    return meta?.metaContent;
}

export function indexForMetaType(meta: MetaField[], metaType: MetaType): number {
    return meta.findIndex(meta => meta.metaType === metaType);
}

export function metaIncludes(metaFields: MetaField[], metaTypes: MetaType[]) {
    return metaFields.some(metaField => metaTypes.includes(metaField.metaType));
}

export const newMetaField = (metaType: MetaType, metaContent: MetaContent): MetaField => {
    return {
        key: crypto.randomUUID(),
        metaID: 0,
        metaType,
        metaContent,
    };
};

export const addOrMergeCustom = (metaField: MetaField | undefined, field: string): MetaField => {
    if (metaField) {
        (metaField.metaContent as TCustom) = {
            custom: {
                ...(metaField.metaContent as TCustom).custom,
                [field]: '',
            },
        };

        metaField.key = crypto.randomUUID();
    } else {
        metaField = newMetaField(MetaType.Custom, {
            custom: {
                [field]: '',
            },
        });
    }
    // console.log('newMeta', newMeta);

    return metaField;
};

export function validateMetaField<T extends MetaType>(
    metaType: T,
    metaContent: MetaContent<T>,
    options?: MetaValidatorOptions[T],
): ValidationResult<MetaContent<T>, MetaErrors[T]> {
    switch (metaType) {
        case MetaType.ProductLink:
            return getProductLinksErrors(metaContent as TProductLink, options) as ValidationResult<
                MetaContent<T>,
                MetaErrors[T]
            >;
        case MetaType.Link:
            return getLinksErrors(metaContent as TLink, options) as ValidationResult<MetaContent<T>, MetaErrors[T]>;
    }
    return { results: metaContent };
}

export function validateMeta(
    metaFields: MetaField[],
    optionsMap?: { [key in MetaType]?: MetaValidatorOptions[key] },
): ValidationResult<MetaField[], MetaErrors> {
    const results: MetaField[] = [];
    const errors: MetaErrors = {};
    for (const metaField of metaFields) {
        const { errors: fieldErrors, results: fieldResults } = validateMetaField(
            metaField.metaType,
            metaField.metaContent,
            optionsMap?.[metaField.metaType],
        );
        if (fieldErrors) {
            (errors[metaField.metaType] as MetaErrors[MetaType]) = fieldErrors;
        } else {
            results.push({ ...metaField, metaContent: fieldResults });
        }
    }
    if (Object.keys(errors).length > 0) {
        return { errors };
    }
    return { results };
}

export function replaceMetaContent(metaFields: MetaField[], metaType: MetaType, metaContent: MetaContent): MetaField[] {
    const result = [...metaFields];
    const index = indexForMetaType(result, metaType);
    if (index !== -1) {
        result[index].metaContent = metaContent;
    } else {
        result.push(newMetaField(metaType, metaContent));
    }
    return result;
}

const removeLinkIds = <T extends { id: string }>(links: T[]): T[] => links.map(({ id: _, ...rest }) => rest as T);
const normalizeMetaContent = (metaField: MetaField): MetaContent => {
    switch (metaField.metaType) {
        case MetaType.Link:
        case MetaType.ProductLink: {
            // Remove id
            const metaContent = structuredClone(metaField.metaContent);
            metaContent.links = removeLinkIds(metaContent.links);
            return metaContent;
        }
        default:
            return metaField.metaContent;
    }
};

interface Props {
    children: React.ReactNode;
}

export const MetaProvider: FC<Props> = ({ children }) => {
    const { request } = useApi();
    const { save } = useSaveMeta();

    const fetch = async (imageId: string, filterEmpty: boolean = true): Promise<MetaData> => {
        const response = await request({
            method: Method.GET,
            path: `/ImageMeta/${imageId}`,
        });

        const metaData = (response.data as Result<MetaData>).Results.Meta.filter(meta => {
            if (filterEmpty === false) {
                return true;
            }

            switch (meta.metaType) {
                case MetaType.ArtistName:
                    return !nullUndefinedOrEmpty((meta.metaContent as TArtistName).name);
                case MetaType.CardType:
                    return true;
                case MetaType.Custom:
                    return Object.keys((meta.metaContent as TCustom).custom).length > 0;
                case MetaType.Description:
                    return !nullUndefinedOrEmpty((meta.metaContent as TDescription).description);
                case MetaType.DisplayLocation:
                    return !nullUndefinedOrEmpty((meta.metaContent as TDisplayLocation).displayLocation);
                case MetaType.Email:
                    return !nullUndefinedOrEmpty((meta.metaContent as TEmail).email);
                case MetaType.GalleryName:
                    return !nullUndefinedOrEmpty((meta.metaContent as TGalleryName).galleryName);
                case MetaType.Link:
                    return (meta.metaContent as TLink).links.length > 0;
                case MetaType.Medium:
                    return !nullUndefinedOrEmpty((meta.metaContent as TMedium).medium);
                case MetaType.Phone:
                    return !nullUndefinedOrEmpty((meta.metaContent as TPhone).phone);
                case MetaType.Price:
                    return !nullUndefinedOrEmpty((meta.metaContent as TPrice).price);
                case MetaType.Provenance:
                    return !nullUndefinedOrEmpty((meta.metaContent as TProvenance).provenance);
                case MetaType.Size:
                    return !nullUndefinedOrEmpty((meta.metaContent as TSize).size);
                case MetaType.Tags:
                    return (meta.metaContent as TTags).tags.length > 0;
                case MetaType.Title:
                    return !nullUndefinedOrEmpty((meta.metaContent as TTitle).title);
                case MetaType.Year:
                    return !nullUndefinedOrEmpty((meta.metaContent as TYear).year);

                case MetaType.ArtCard:
                case MetaType.ContactInfo:
                case MetaType.ImageInfo:
                case MetaType.NftLink:
                case MetaType.SocialLinks:
                case MetaType.Unknown:
                case MetaType.Urls:
                case MetaType.UserProvidedInfo:
                    return false;

                default:
                    return true;
            }
        });
        // console.log("response", response);

        return {
            ...(response.data as Result<MetaData>).Results,
            Meta: metaData,
        };
    };

    const search = async (
        query: string,
        owner: boolean = true,
        page: number = 0,
        limit: number = 25,
        sortBy: ImageSortByField = 'internalID',
        sortOrder: ImageSortOrderField = 'DESC',
    ): Promise<PagedResults<Image[]>> => {
        const response = await request({
            method: Method.GET,
            path: `/Search/metaData/${query}?owner=${owner}&offset=${page * limit}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
        });

        return {
            NextOffset: response.data.NextOffset,
            Count: response.data.Count,
            Pages: response.data.Pages,
            Results: response.data.Results.Images,
        };
    };

    const requestEdit = async (imageId: string): Promise<void> => {
        await request({
            method: Method.POST,
            path: `/User/requestEdit/${imageId}`,
        });
    };

    return (
        <MetaContext.Provider
            value={{
                fetch,
                save,
                search,
                requestEdit,
            }}
        >
            {children}
        </MetaContext.Provider>
    );
};

const MetaContext = createContext<TMeta | undefined>(undefined);

export default MetaContext;
