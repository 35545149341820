import { FC, createContext, useCallback, useState } from 'react';

export enum Event {
    ProfileScrolledToBottom,
    RefreshImages,
    RefreshSaved,
    RefreshCampaigns,
    RefreshNotifications,
}
export type Listener = () => void;
export type EventMap = Map<Event, Set<Listener>>;

export interface TEvent {
    subscribe: (eventName: Event, listener: Listener) => Listener;
    unsubscribe: (eventName: Event, listener: Listener) => void;
    publish: (eventName: Event) => void;
}

interface Props {
    children: React.ReactNode;
}

export const EventProvider: FC<Props> = ({ children }) => {
    const [listenersMap, setListenersMap] = useState<EventMap>(new Map());

    const subscribe: TEvent['subscribe'] = useCallback((eventName, listener) => {
        setListenersMap(prev => {
            const listeners = new Set(prev.get(eventName) || []);
            listeners.add(listener);
            return new Map(prev).set(eventName, listeners);
        });
        return () => unsubscribe(eventName, listener);
    }, []);

    const unsubscribe: TEvent['unsubscribe'] = useCallback((eventName, listener) => {
        setListenersMap(prev => {
            const listeners = new Set(prev.get(eventName) || []);
            listeners.delete(listener);
            return new Map(prev).set(eventName, listeners);
        });
    }, []);

    const publish: TEvent['publish'] = useCallback(
        eventName => {
            const listeners = listenersMap.get(eventName);
            if (listeners) {
                listeners.forEach(listener => listener());
            }
        },
        [listenersMap],
    );

    return (
        <EventContext.Provider
            value={{
                subscribe,
                unsubscribe,
                publish,
            }}
        >
            {children}
        </EventContext.Provider>
    );
};

const EventContext = createContext<TEvent | undefined>(undefined);

export default EventContext;
