import { useCallback, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { AnalyticsBrowser } from '@segment/analytics-next';
import KeenAnalysis from 'keen-analysis';
import useApi, { Method } from 'src/hooks/useApi';
import { Image } from 'src/contexts/MediaContext';
import { metaContentForMetaType } from 'src/contexts/MetaContext';
import { Category, MetaType } from 'src/types/MetaTypes';
import { ILink } from 'src/types/Link';
import { LRUCache } from 'lru-cache';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const cache = new LRUCache({
    max: 1000,
    ttl: 1000 * 60 * 60, // 1 hour
});

const analyticsWrite = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY ?? '' });
const analyticsRead = new KeenAnalysis({
    projectId: process.env.REACT_APP_KEEN_PROJECT_ID,
    readKey: process.env.REACT_APP_KEEN_READ_KEY,
});

export enum Event {
    AppOpened = 'App Opened',
    ImageScanned = 'Image Scanned',
    ImageSaved = 'Image Saved',
    ImageShared = 'Image Shared',
    UserAccountCreated = 'User Account Created',
    ImageViewed = 'Image Viewed',
    ImageLinkOpened = 'Image Link Opened',
    UserProfileViewed = 'User Profile Viewed',
}

export enum Parameter {
    CampaignID = 'campaign_id',
    Category = 'category',
    ImageID = 'image_id',
    IsOwner = 'is_owner',
    ImageTitle = 'image_title',
    ImageURL = 'image_url',
    Latitude = 'latitude',
    LinkTitle = 'link_title',
    LinkURL = 'link_url',
    Longitude = 'longitude',
    Match = 'match',
    OwnerUserID = 'owner_user_id',
    OwnerUsername = 'owner_username',
    ViewerUserID = 'viewer_user_id',
    ViewerUsername = 'viewer_username',
    TrackingID = 'tracking_id',
    ViewerUserProfileURL = 'viewer_user_profile_url',
}

export enum Type {
    Count = 'count',
    CountUnique = 'count_unique',
    Extraction = 'extraction',
    MultiAnalysis = 'multi_analysis',
}

// These can be a lot of different values, add as needed
// ThisNUnits = "this_n_units",
// PreviousNUnits = "previous_n_units",
// ThisUnit = "this_unit",
// PreviousUnit = "previous_unit",
// Today = "today",
// Yesterday = "yesterday",
export enum Timeframe {
    This1Days = 'this_1_days',
    This3Days = 'this_3_days',
    This5Days = 'this_5_days',
    This7Days = 'this_7_days',
    This30Days = 'this_30_days',
    This90Days = 'this_90_days',
    This180Days = 'this_180_days',
    ThisYear = 'this_year',
    All = 'all',
}

// EveryNUnits = every_N_units
export enum Interval {
    Minutely = 'minutely',
    Hourly = 'hourly',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
}

export interface QueryResult<T> {
    value: number | T;
    timeframe?: string;
}

export interface QueryAndResult<T> {
    query: any;
    result: QueryResult<T>[];
}

export interface QueryParameters {
    analyses?: Record<string, any>;

    event_collection?: Event;
    timeframe?:
        | Timeframe
        | {
              start: string;
              end: string;
          };
    // Keep for debugging
    // | string;

    interval?: Interval | string;

    // target_property?: string;
    // max_age?: string;
    // timezone?: string;

    filters?: Array<{
        property_name: Parameter;
        operator: string;
        property_value: string | number | boolean;
    }>;
    group_by?: string[];
    latest?: number;

    // order_by: [{ property_name: 'result', direction: 'DESC' }]
    order_by?: any;

    limit?: number;
}

export interface ExtractionResult<T> {
    result: T[];
}

export interface TAnalytics {
    imageViewed: (image: Image) => void;
    imageLinkOpened: (image: Image, link: ILink) => void;
    query: <T>(type: Type, params: QueryParameters) => Promise<QueryResult<T>[]>;
    extraction: <T>(params: QueryParameters) => Promise<T[]>;
}

export const useGoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        const gaID = process.env.REACT_APP_GA_MEASUREMENT_ID;
        if (gaID) ReactGA.initialize(gaID);
    }, []);

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: 'pageview', page: path });
    }, [location]);
};

const useAnalytics = (): TAnalytics => {
    const auth = getAuth();
    const { request } = useApi();

    const trackEvent = useCallback((event: Event, properties: Record<Parameter, any>) => {
        console.log('trackEvent event', event, properties);
        analyticsWrite
            .track(event, {
                // TODO: See AnalyticsEvent.swift
                // "image_id": imageModel.imageID,
                // "is_owner": imageModel.imageUser.isMe,
                // "image_title": imageModel.title ?? "",
                // "image_url": imageModel.imageUrl ?? "",
                // "viewer_user_id": UserData.shared.me.userID,
                // "viewer_username": UserData.shared.me.userName,
                // "owner_user_id": imageModel.imageUser.userID,
                // "owner_username": imageModel.imageUser.userName,
                // "tracking_id": UserData.shared.trackingId ?? "",
                // "viewer_user_profile_url": UserData.shared.me.profileUrl,
                // "latitude": UserData.shared.currentLocation.coordinate.latitude,
                // "longitude": UserData.shared.currentLocation.coordinate.longitude,
                ...properties,
            })
            .then(res => {
                console.log('res', res);
            });
    }, []);

    const imageViewed = useCallback(
        async (image: Image) => {
            try {
                trackEvent(Event.ImageViewed, {
                    [Parameter.CampaignID]: image.campaignID,
                    [Parameter.Category]:
                        metaContentForMetaType(image, MetaType.Category)?.category ?? Category.Unknown,
                    [Parameter.ImageID]: image.imageID,
                } as Record<Parameter, any>);
                // case .imageViewed(let imageModel):
                //     return [
                //         "image_id": imageModel.imageID,
                //         "is_owner": imageModel.imageUser.isMe,
                //         "image_title": imageModel.title ?? "",
                //         "image_url": imageModel.imageUrl ?? "",
                //         "viewer_user_id": UserData.shared.me.userID,
                //         "viewer_username": UserData.shared.me.userName,
                //         "owner_user_id": imageModel.imageUser.userID,
                //         "owner_username": imageModel.imageUser.userName,
                //         "tracking_id": UserData.shared.trackingId ?? "",
                //         "viewer_user_profile_url": UserData.shared.me.profileUrl,
                //         "latitude": UserData.shared.currentLocation.coordinate.latitude,
                //         "longitude": UserData.shared.currentLocation.coordinate.longitude,
                //     ]

                await request({
                    method: Method.POST,
                    path: `/Images/view/${image.imageID}`,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [request, trackEvent],
    );

    // case .imageScanned(let queryTimeInSeconds, let wasMatched, let matchedImage, let similarImagesCount):
    //     var params: [String: Any] = [
    //         "time": queryTimeInSeconds,
    //         "match": wasMatched,
    //         "tracking_id": UserData.shared.trackingId ?? "",
    //         "query_image_url": getQueryImageUrlForTrackingId(UserData.shared.trackingId ?? ""),
    //         "user_id": UserData.shared.me.userID,
    //         "username": UserData.shared.me.userName,
    //         "latitude": UserData.shared.currentLocation.coordinate.latitude,
    //         "longitude": UserData.shared.currentLocation.coordinate.longitude
    //     ]
    //     if let matchedImage = matchedImage {
    //         params["image_id"] = matchedImage.imageID
    //         params["is_owner"] = matchedImage.imageUser.isMe
    //         params["image_title"] = matchedImage.title ?? ""
    //         params["image_url"] = matchedImage.imageUrl ?? ""
    //         params["campaign_id"] = matchedImage.campaignID
    //         params["campaign_name"] = matchedImage.campaignName
    //         params["category"] = matchedImage.category ?? ""
    //     }
    //     if let similarImagesCount = similarImagesCount {
    //         params["similar_images"] = similarImagesCount
    //     }
    //     return params
    const imageScanned = useCallback(
        async (image: Image) => {
            try {
                trackEvent(Event.ImageScanned, {
                    [Parameter.CampaignID]: image.campaignID,
                    [Parameter.Category]:
                        metaContentForMetaType(image, MetaType.Category)?.category ?? Category.Unknown,
                    [Parameter.ImageID]: image.imageID,
                } as Record<Parameter, any>);
                // case .imageViewed(let imageModel):
                //     return [
                //         "image_id": imageModel.imageID,
                //         "is_owner": imageModel.imageUser.isMe,
                //         "image_title": imageModel.title ?? "",
                //         "image_url": imageModel.imageUrl ?? "",
                //         "viewer_user_id": UserData.shared.me.userID,
                //         "viewer_username": UserData.shared.me.userName,
                //         "owner_user_id": imageModel.imageUser.userID,
                //         "owner_username": imageModel.imageUser.userName,
                //         "tracking_id": UserData.shared.trackingId ?? "",
                //         "viewer_user_profile_url": UserData.shared.me.profileUrl,
                //         "latitude": UserData.shared.currentLocation.coordinate.latitude,
                //         "longitude": UserData.shared.currentLocation.coordinate.longitude,
                //     ]

                await request({
                    method: Method.POST,
                    path: `/Images/view/${image.imageID}`,
                });
            } catch (error) {
                console.error(error);
            }
        },
        [request, trackEvent],
    );

    const imageLinkOpened = useCallback(
        (image: Image, link: ILink) => {
            trackEvent(Event.ImageLinkOpened, {
                [Parameter.CampaignID]: image.campaignID,
                [Parameter.ImageID]: image.imageID,
                [Parameter.ImageTitle]: metaContentForMetaType(image, MetaType.Title)?.title ?? '',

                [Parameter.LinkTitle]: link.title,
                [Parameter.LinkURL]: link.url,
            } as Record<Parameter, any>);
            // case .imageLinkOpened(let imageModel, let linkModel):
            //     return [
            //         "image_id": imageModel.imageID,
            //         "image_title": imageModel.title ?? "",
            //         "image_url": imageModel.imageUrl ?? "",
            //         "viewer_user_id": UserData.shared.me.userID,
            //         "viewer_username": UserData.shared.me.userName,
            //         "owner_user_id": imageModel.imageUser.userID,
            //         "owner_username": imageModel.imageUser.userName,
            //         "viewer_user_profile_url": UserData.shared.me.profileUrl,
            //         "latitude": UserData.shared.currentLocation.coordinate.latitude,
            //         "longitude": UserData.shared.currentLocation.coordinate.longitude,
            //         "link_id": linkModel.id,
            //         "link_title": linkModel.title,
            //         "link_url": linkModel.urlString,
            //         "link_on_touch_display": linkModel.onTouchDisplay,
            //         "link_on_scan_display": linkModel.onScanDisplay,
            //         "tracking_id": UserData.shared.trackingId ?? "",
            //     ]
        },
        [trackEvent],
    );

    const query = useCallback(async <T>(type: Type, params: QueryParameters): Promise<QueryResult<T>[]> => {
        // console.log('query', type, params);

        const cacheKey = `${type}-${JSON.stringify(params)}`;

        return new Promise((resolve, reject) => {
            const value = cache.get(cacheKey) as QueryResult<T>[] | undefined;
            if (value) {
                console.log('cache hit', cacheKey);
                resolve(value);
                return;
            }

            analyticsRead
                .query(type, {
                    ...params,
                    // refresh_rate: 4 * 60 * 60,
                })
                .then((queryAndResult: QueryAndResult<T>) => {
                    // console.log('queryAndResult', queryAndResult);

                    if (type === Type.MultiAnalysis) {
                        queryAndResult.result = queryAndResult.result.map((result: any) => {
                            result.value = Object.keys(result.value).reduce((acc: any, key: any) => {
                                // The IRCODE id comes back snake-cased :/
                                acc[key.replaceAll('_', '').toUpperCase()] = result.value[key];
                                return acc;
                            }, {});

                            return result;
                        });
                    }

                    cache.set(cacheKey, queryAndResult.result);
                    resolve(queryAndResult.result);
                })
                .catch((error: any) => {
                    console.warn(error);
                    reject(error);
                });
        });
    }, []);

    const extraction = useCallback(async <T>(params: QueryParameters): Promise<T[]> => {
        // console.log('query', type, params);

        const cacheKey = JSON.stringify(params);

        return new Promise((resolve, reject) => {
            const value = cache.get(cacheKey) as T[] | undefined;
            if (value) {
                console.log('cache hit', cacheKey);
                resolve(value);
                return;
            }

            analyticsRead
                .query(Type.Extraction, {
                    ...params,
                    // refresh_rate: 4 * 60 * 60,
                })
                .then((result: ExtractionResult<T>) => {
                    // ExtractionResult<
                    // console.log('queryAndResult', queryAndResult);

                    // if (type === Type.MultiAnalysis) {
                    //     queryAndResult.result = queryAndResult.result.map((result: any) => {
                    //         result.value = Object
                    //             .keys(result.value)
                    //             .reduce((acc: any, key: any) => {
                    //                 // The IRCODE id comes back snake-cased :/
                    //                 acc[key.replaceAll('_', '').toUpperCase()] = result.value[key];
                    //                 return acc;
                    //             }, {});

                    //         return result;
                    //     });
                    // }

                    cache.set(cacheKey, result.result);
                    resolve(result.result);
                })
                .catch((error: any) => {
                    console.warn(error);
                    reject(error);
                });
        });
    }, []);

    return {
        imageViewed,
        imageLinkOpened,
        query,
        extraction,
    };
};

export default useAnalytics;
