export const formatDate = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    const currentDate = new Date();

    date.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    if (date.getTime() === currentDate.getTime()) {
        return 'Today';
    }

    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    return date.toLocaleDateString(undefined, dateOptions);
};

export const formatDateWebApp = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    const currentDate = new Date();

    const timeDifference = Math.floor((currentDate.getTime() - date.getTime()) / 1000);

    if (timeDifference < 3600) {
        const minutes = Math.floor(timeDifference / 60);
        return `${minutes}m ago`;
    } else if (timeDifference < 21600) {
        const hours = Math.floor(timeDifference / 3600);
        return `${hours}h ago`;
    } else {
        date.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        if (date.getTime() === currentDate.getTime()) {
            return 'Today';
        } else if (currentDate.getTime() - date.getTime() === 86400000) {
            return 'Yesterday';
        } else if (currentDate.getTime() - date.getTime() < 604800000) {
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            return daysOfWeek[date.getDay()];
        } else {
            return formatDate(unixTimestamp);
        }
    }
};

export default function formatTime(unixTimestamp: number) {
    const date = new Date(unixTimestamp * 1000);
    const hours = date.getHours();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (((hours + 11) % 12) + 1).toString().padStart(2, '0');
    const formattedMinutes = date.getMinutes().toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${amPm}`;
}
