import { useState } from 'react';
import { Stack, Box, FormControl, Grid, Typography, Divider } from '@mui/material';
import { Color } from '../../../../Color';
import { Switch } from '../../../general/Switch';

interface AppField {
    title: string;
    description: string;
    onClick: () => void;
}

interface EmailField {
    title: string;
    description: string;
    onClick: () => void;
}

interface NotificationEmailProps {
    onChangeTab: () => void;
}

export function Notification({ onChangeTab }: NotificationEmailProps) {
    const emailFields: EmailField[] = [
        {
            title: 'Account notifications',
            description: 'PLAN UPDATES, CHANGES TO YOUR ACCOUNT',
            onClick: () => {
                console.log('Updates toggled');
            },
        },
        {
            title: 'Updates to your IRCODES',
            description: 'CHANGES MADE TO IRCODES, OFFERS, AND MORE',
            onClick: () => {
                console.log('Likes toggled');
            },
        },
        {
            title: 'Product updates',
            description: 'NEW FEATURES AND CHANGES TO THE PALTFORM',
            onClick: () => {
                console.log('Saves toggled');
            },
        },
        {
            title: 'New product and partner offers',
            description: 'RELEVANT OFFERS FROM IRCODE OR OUR PARTNERS',
            onClick: () => {
                console.log('Saves toggled');
            },
        },
    ];

    const appFields: AppField[] = [
        {
            title: 'Updates to your IRDCODES',
            description: 'CHANGES MADE TO IRCODES, OFFERS, AND MORE',
            onClick: () => {
                console.log('Updates toggled');
            },
        },
        {
            title: 'Liked IRDCODES',
            description: 'GET A NOTIFICATION WHEN YOUR IRCODE IS LIKED',
            onClick: () => {
                console.log('Likes toggled');
            },
        },
        {
            title: 'Saved IRDCODES',
            description: 'GET A NOTIFICATION WHEN YOUR IRCODE IS SAVED',
            onClick: () => {
                console.log('Saves toggled');
            },
        },
        {
            title: 'Shared IRDCODES',
            description: 'GET A NOTIFICATION WHEN YOUR IRCODE IS SHARED',
            onClick: () => {
                console.log('Saves toggled');
            },
        },
        {
            title: 'New product and partner offers',
            description: 'RELEVANT OFFERS FROM IRCODE OR OUR PARTNERS',
            onClick: () => {
                console.log('Saves toggled');
            },
        },
    ];

    const [toggled, setToggled] = useState<{ [key: string]: boolean }>({});

    const handleToggle = (type: 'app' | 'email', index: number) => {
        const key = `${type}_${index}`;
        const newState = { ...toggled, [key]: !toggled[key] };
        setToggled(newState);

        if (type === 'app') {
            appFields[index].onClick();
        } else if (type === 'email') {
            emailFields[index].onClick();
        }
    };

    return (
        <>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={6}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="webHeadlineSmall" sx={{ padding: 2 }}>
                            App notifications
                        </Typography>
                        {appFields.map((field, index) => (
                            <Stack key={index} direction="row" spacing={2} alignItems="center" sx={{ p: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="webHistoryTitle">{field.title}</Typography>
                                        <Typography variant="webFont8">{field.description}</Typography>
                                    </Box>
                                    <FormControl>
                                        <Switch
                                            checked={!!toggled[`app_${index}`]}
                                            onChange={() => handleToggle('app', index)}
                                        />
                                    </FormControl>
                                </Box>
                            </Stack>
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="center">
                    <Box sx={{ width: '368px', paddingTop: '5.5rem' }}>
                        <Stack direction="column" spacing={2} alignItems="flex-start">
                            <Typography variant="notificationSettingInfo">
                                These settings affect both the web dashboard and mobile app notifications
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
            <>
                <Divider />
            </>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} md={6}>
                    <Stack direction="column" spacing={2}>
                        <Typography variant="webHeadlineSmall" sx={{ padding: 2 }}>
                            Email notifications
                        </Typography>
                        {emailFields.map((field, index) => (
                            <Stack key={index} direction="row" spacing={2} alignItems="center" sx={{ p: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Typography variant="webHistoryTitle">{field.title}</Typography>
                                        <Typography variant="webFont8">{field.description}</Typography>
                                    </Box>
                                    <FormControl>
                                        <Switch
                                            checked={!!toggled[`email_${index}`]}
                                            onChange={() => handleToggle('email', index)}
                                        />
                                    </FormControl>
                                </Box>
                            </Stack>
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="center">
                    <Box sx={{ width: '368px', paddingTop: '5.5rem' }}>
                        <Stack direction="column" spacing={2} alignItems="flex-start">
                            <Typography variant="notificationSettingInfo">
                                Email notifications are sent to john.smith@gmail.com . To change this go to
                                <Typography
                                    variant="notificationSettingInfo"
                                    onClick={onChangeTab}
                                    sx={{
                                        cursor: 'pointer',
                                        color: Color.PrimaryLavender,
                                        textDecoration: 'underline',
                                    }}
                                >
                                    Account settings
                                </Typography>
                            </Typography>
                            <Typography variant="notificationSettingInfo" sx={{ paddingTop: 2 }}>
                                Notifications are only sent as a result of an action you take or need to take, or when
                                updates or changes are made to your account or IRCODES.
                            </Typography>
                            <Typography variant="notificationSettingInfo" sx={{ paddingTop: 2 }}>
                                Transaction receipts will always be sent
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
