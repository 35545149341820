import { useEffect } from 'react';

/** Ensures all items in a list have an id */
const useEnsureIds = <T extends { id: string }>(items: Array<T>, setItems: (items: Array<T>) => void) => {
    useEffect(() => {
        const hasMissingId = items.some(item => !item.id);
        if (hasMissingId) {
            setItems(items.map(item => (item.id ? item : { ...item, id: crypto.randomUUID() })));
        }
    }, [
        items,
        // TODO: uncomment this after MetaFields' onChange is memoized
        //setItems
    ]);
};

export default useEnsureIds;
