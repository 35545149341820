import * as React from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import MediaContext, { Image, PagedResults, TMedia } from '../../../contexts/MediaContext';
import { useParams } from 'react-router-dom';
import PageHeader from './PageHeader';
import UserContext, { TUser } from '../../../contexts/UserContext';
import IrcodeRow from './IrcodeRow';
import Pagination from '../../general/Pagination';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { useNavigateAndScrollToTop } from 'src/hooks/useNavigateAndScrollToTop';
import usePageTitle from 'src/hooks/usePageTitle';

export default function Profile() {
    const { id } = useParams();
    const navigateAndScrollToTop = useNavigateAndScrollToTop();
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { user } = useContext(UserContext) as TUser;
    const { fetchImages } = useContext(MediaContext) as TMedia;

    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [images, setImages] = useState<PagedResults<Image[]>>();
    const [userName, setUserName] = useState('');

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        setIsLoading(true);
        fetchImages(parseInt(id), page - 1)
            .then(images => {
                setImages(images);
                setPageCount(images.Pages);
                // TODO: Ideally we'd have a separate API call for this
                if (images.Results.length > 0) {
                    setUserName(images.Results[0].ImageUser.userName);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [user, page]);

    usePageTitle(userName);

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                flexGrow: 1,
                p: 4,
            }}
        >
            <PageHeader title={userName} />

            {/* <Stack
                direction="column"
                spacing={2}
                sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White, // Color.LightLavenderLightMode,
                    // backgroundColor: 'red',
                }}
            > */}
            {isLoading && (
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress />
                </Grid>
            )}
            {(images?.Results ?? []).map(image => {
                return (
                    <IrcodeRow
                        key={image.imageID}
                        image={image}
                        onClick={() => {
                            navigateAndScrollToTop(`/dashboard/ircodes/${image.imageID}`);
                        }}
                    />
                );
            })}

            {pageCount > 1 && (
                <Pagination
                    count={pageCount}
                    page={page}
                    onChange={(event, page) => {
                        setPage(page);
                        window.scrollTo(0, 0);
                    }}
                />
            )}
            {/* </Stack> */}
        </Stack>
    );
}
