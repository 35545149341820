import { create } from 'zustand';

export interface VideoStreamStore {
    stream: MediaStream | null;
    setStream: (media: MediaStream | null) => void;
}

const useWebcamStreamStore = create<VideoStreamStore>()((set, get) => ({
    stream: null,
    setStream: stream => {
        const current = get().stream;
        if (current) {
            current.getTracks().forEach(track => track.stop());
        }
        set({ stream });
    },
}));

const useWebcamStream = () => {
    const stream = useWebcamStreamStore(state => state.stream);
    const setStream = useWebcamStreamStore(state => state.setStream);

    return { stream, setStream };
};

export default useWebcamStream;
