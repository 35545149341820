import { Box, BoxProps, FormHelperText, FormHelperTextProps } from '@mui/material';

export interface HelperTextBoxProps extends BoxProps {
    helperText?: string;
    error?: boolean;
    formHelperTextProps?: FormHelperTextProps;
}

const HelperTextBox = ({ helperText, error, formHelperTextProps, children, ...props }: HelperTextBoxProps) => {
    return (
        <Box
            {...props}
            sx={{
                borderRadius: 1,
                ...props.sx,
            }}
        >
            <Box
                sx={{
                    border: error ? '1px solid' : 'none',
                    borderColor: 'error.main',
                    borderRadius: 'inherit',
                }}
            >
                {children}
            </Box>
            {helperText && (
                <FormHelperText {...formHelperTextProps} error={error}>
                    {helperText}
                </FormHelperText>
            )}
        </Box>
    );
};

export default HelperTextBox;
