import { useContext } from 'react';
import { Image } from 'src/contexts/MediaContext';
import UserContext, { TUser } from 'src/contexts/UserContext';

/** Returns undefined when image is undefined */
const useIsImageOwner = (image?: Image) => {
    const { user } = useContext(UserContext) as TUser;
    if (!image) return undefined;
    if (!user) return false;
    if (!user || !image.ImageUser) return false;
    return user.userID === image.ImageUser.userID;
};

export default useIsImageOwner;
