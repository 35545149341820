import { CircularProgress, Modal } from '@mui/material';
import React from 'react';
import { create } from 'zustand';
import { createStoreSelectors } from '../../util/zustand';

export interface LoadingStore {
    showLoading: boolean;
    setShowLoading: (showLoading: boolean) => void;
}

const useLoadingStore = createStoreSelectors(
    create<LoadingStore>()(set => ({
        showLoading: false,
        setShowLoading: showLoading => set({ showLoading }),
    })),
);

/** Don't use this component directly. Instead, use the 'setShowLoading' function from the feedback context*/
export const LoadingDialog = () => {
    const showLoading = useLoadingStore.use.showLoading();
    return (
        <Modal
            open={showLoading}
            sx={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                backdropFilter: 'blur(4px)',
                WebkitBackdropFilter: 'blur(4px)',
                // TODO: Need a way to keep this on top...
                zIndex: 10000,
            }}
        >
            <CircularProgress
                sx={{
                    color: '#fff',
                    outline: 0,
                }}
            />
        </Modal>
    );
};

export const useSetShowLoading = () => useLoadingStore.use.setShowLoading();

const useShowLoading = () => {
    const showLoading = useLoadingStore.use.showLoading();
    const setShowLoading = useLoadingStore.use.setShowLoading();
    return [showLoading, setShowLoading] as const;
};

export default useShowLoading;
