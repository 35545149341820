import { useCallback } from 'react';
import useApi, { Method } from 'src/hooks/useApi';
import { QueryResult } from './useAnalytics';

export interface Totals {
    totalRecent: number;
    totalUnique: number;
    totalOlder: number;
    totalOlderUnique: number;
    percentTotal: number;
    percentUnique: number;
}

export interface TStatistics {
    fetch: <T>(params: {
        campaignID: number;
        stat: 'views' | 'scans';
        timeframe?: number;
        interval?: string;
        start?: string;
        end?: string;
        aggregated?: boolean;
        totals?: boolean;
    }) => Promise<QueryResult<T>[]>;
    fetchTotals: (params: {
        campaignID: number;
        stat: 'views' | 'scans';
        timeframe?: number;
        interval?: string;
        start?: string;
        end?: string;
        aggregated?: boolean;
        totals?: boolean;
    }) => Promise<Totals>;
}

const useStatistics = (): TStatistics => {
    const { request } = useApi();

    const fetch = useCallback(
        async <T>({
            campaignID,
            timeframe,
            interval,
            start,
            end,
            aggregated = false,
            totals = false,
            stat,
        }: {
            campaignID: number;
            timeframe?: number;
            interval?: string;
            start?: string;
            end?: string;
            aggregated?: boolean;
            totals?: boolean;
            stat: string;
        }): Promise<QueryResult<T>[]> => {
            const response = await request({
                method: Method.GET,
                path: '/Statistics/fetch',
                data: {
                    campaignid: campaignID,
                    stat,
                    timeframe,
                    interval,
                    start: timeframe ? undefined : start,
                    end:
                        timeframe ? undefined
                        : end ? end
                        : start,
                    aggregated,
                    totals,
                },
            });
            return response.data.Results.views;
        },
        [request],
    );

    const fetchTotals = useCallback(
        async <T>({
            campaignID,
            timeframe,
            interval,
            start,
            end,
            aggregated = false,
            totals = false,
            stat,
        }: {
            campaignID: number;
            timeframe?: number;
            interval?: string;
            start?: string;
            end?: string;
            aggregated?: boolean;
            totals?: boolean;
            stat: string;
        }): Promise<Totals> => {
            const response = await request({
                method: Method.GET,
                path: '/Statistics/fetch',
                data: {
                    campaignid: campaignID,
                    stat,
                    timeframe,
                    interval,
                    start: timeframe ? undefined : start,
                    end:
                        timeframe ? undefined
                        : end ? end
                        : start,
                    aggregated,
                    totals,
                },
            });
            return response.data.Results;
        },
        [request],
    );

    return {
        fetch,
        fetchTotals,
    };
};

export default useStatistics;
