export enum Color {
    Black = '#000000',
    DarkModeBlack = '#181820',
    PalePurple = '#958EC1',
    PalerPurple = '#A5A5D1',
    Pink = 'rgba(255, 51, 222, 0.2)',
    PrimaryDarkGrayBlue = '#12121A',
    PrimaryLavender = 'rgba(128, 89, 227, 1)',

    LightGray = '#EDEDF6',

    // TODO: Remove these notes once this color situation is resolved
    // Ax has quite a few version of this color (there's another below for TermsGray)
    // #A5A5D114 -> Dark Mode
    // #A5A5D129 // Dark Mode
    // #A5A5D133 // Light Mode
    // #A5A5D140 -> Light Mode
    // #A5A5D180 -> Light Mode
    LightLavenderDarkMode = '#A5A5D129',
    LightLavenderLightMode = '#A5A5D133',

    Purple = '#8059E3',

    WebNotificationGreen = '#DBEF25',
    White = '#FFFFFF',

    // TODO: Switch to LightLavender
    TermsGray = '#A5A5D180',

    AnalyticsRed = '#D60067',
    AnalyticsGreen = '#5DA600',
}

export const purpleGradientCSS = 'linear-gradient(106.88deg, #5B60C1 37.55%, #882EF1 76.92%)';
