import {
    Box,
    Card as MuiCard,
    Drawer,
    Fade,
    IconButton,
    Stack,
    Typography,
    SwipeableDrawer,
    Badge,
} from '@mui/material';
import {
    Dispatch,
    FC,
    MutableRefObject,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import useAuth from 'src/hooks/useAuth';
import { ZIndex } from '../../App';
import { Color } from '../../Color';
import EventContext, { Event, TEvent } from '../../contexts/EventContext';
import MediaContext, { Image, PagedResults, TMedia } from '../../contexts/MediaContext';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import UserContext, { PagedNotifications, TUser } from '../../contexts/UserContext';
import { useCapturedStateWorkaround } from '../../hooks/useCapturedStateWorkaround';
import { Puller } from '../general/Puller';
import ImageList from '../imageList/ImageList';
import Pricing from '../Pricing';
import ProfileSubMenu from './ProfileSubMenu';
import NotificationsList from '../imageList/NotificationsList';
import { merge } from 'src/util/array';
import usePageTitle from 'src/hooks/usePageTitle';
import UserAvatar from 'src/components/general/UserAvatar';

export enum ProfileSection {
    SearchHistory = 'History',
    Notifications = 'Notifications',
    MyIRCodes = 'My IRCODES',
    Saved = 'Saved',
    Billing = 'Billing',
    Removed = 'Trash',
}

const Card = ({
    label,
    icon,
    isSelected,
    onClick,
    tabCount,
}: {
    label: string;
    icon: string;
    isSelected: boolean;
    tabCount?: number;
    onClick: () => void;
}) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <MuiCard
            elevation={0}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: '.5rem',
                paddingLeft: '1rem',
                paddingBottom: '1rem',
                paddingTop: '1rem',
                minWidth: '8.75rem',
                height: '7rem',
                borderRadius: '0.75rem',
                background: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                color:
                    isSelected ? Color.Purple
                    : darkMode ? Color.White
                    : Color.Black,
            }}
            onClick={onClick}
        >
            <span
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <i
                    className={`fa-solid ${icon}`}
                    style={{
                        fontSize: '1.5rem',
                        lineHeight: '1.625rem',
                        textAlign: 'left',
                    }}
                />
                <Fade in={tabCount !== undefined} timeout={500}>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontWeight: 400,
                            lineHeight: '1.25rem',
                            letterSpacing: '0',
                        }}
                        mr={1}
                    >
                        {tabCount}
                    </Typography>
                </Fade>
            </span>
            <Typography
                sx={{
                    fontFamily: 'Nunito Sans',
                    fontWeight: 400,
                    lineHeight: '1.25rem',
                    letterSpacing: '0',
                }}
            >
                {label}
            </Typography>
        </MuiCard>
    );
};

interface NavTilesProps {
    selected: ProfileSection;
    onClick: (profileSection: ProfileSection) => void;
    historyTabCount?: number;
    imagesTabCount?: number;
    savedTabCount?: number;
    removedTabCount?: number;
}

function NavTiles({
    selected,
    onClick,
    historyTabCount,
    imagesTabCount,
    savedTabCount,
    removedTabCount,
}: NavTilesProps) {
    return (
        <Box
            id="navTiles"
            sx={{
                display: 'flex',
                gap: '1rem',
                overflowX: 'auto',
                padding: '1rem',
                '&::-webkit-scrollbar': {
                    height: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    borderRadius: '4px',
                },
            }}
            // onScrollCapture={(e) => {
            //     e.stopPropagation();
            // }}
        >
            <Card
                label={ProfileSection.MyIRCodes}
                icon="fa-list"
                isSelected={selected === ProfileSection.MyIRCodes}
                onClick={() => onClick(ProfileSection.MyIRCodes)}
                tabCount={imagesTabCount}
            />
            <Card
                label={ProfileSection.Saved}
                icon="fa-star"
                isSelected={selected === ProfileSection.Saved}
                onClick={() => onClick(ProfileSection.Saved)}
                tabCount={savedTabCount}
            />
            <Card
                label={ProfileSection.SearchHistory}
                icon="fa-history"
                isSelected={selected === ProfileSection.SearchHistory}
                onClick={() => onClick(ProfileSection.SearchHistory)}
                tabCount={historyTabCount}
            />
            <Card
                label={ProfileSection.Removed}
                icon="fa-trash"
                isSelected={selected === ProfileSection.Removed}
                onClick={() => onClick(ProfileSection.Removed)}
                tabCount={removedTabCount}
            />
            {/*
            <Card
                label="Billing"
                icon="fa-credit-card"
                isSelected={selected === ProfileSection.Billing}
                onClick={() => onClick(ProfileSection.Billing)}
            />
            */}
        </Box>
    );
}

const getMergeResults = (newResults: PagedResults<Image[]>) => {
    return function mergeResults(oldResults?: PagedResults<Image[]>): PagedResults<Image[]> {
        if (!oldResults?.Results?.length) {
            return newResults;
        }
        return {
            ...newResults,
            Results: merge(oldResults.Results, newResults.Results, (a, b) => a.imageID === b.imageID),
        };
    };
};
const getNotificationMergeResults = (newResults: PagedNotifications) => {
    return function notificationMergeResults(oldResults?: PagedNotifications): PagedNotifications {
        if (!oldResults?.Results?.Notifications?.length) {
            return newResults;
        }
        return {
            ...newResults,
            Results: {
                ...newResults.Results,
                Notifications: merge(
                    oldResults.Results.Notifications,
                    newResults.Results.Notifications,
                    (a, b) => a.notificationID === b.notificationID,
                ),
            },
        };
    };
};

type PageCounts = { [key in 'images' | 'history' | 'saved' | 'removed' | 'notifications']: number };
type PageCountsKey = keyof PageCounts;

const updateCounts = (
    results: PagedResults<unknown> | undefined,
    category: PageCountsKey,
    setCount: (count: number) => void,
    pageCounts: MutableRefObject<PageCounts>,
) => {
    if (results) {
        setCount(results.Count);
        pageCounts.current[category] = results.Pages;
    }
};

type ProfileProps = {
    toggle: () => void;
    setAuthOpen: (open: boolean) => void;
    selectedImageId?: string;
    setSelectedImageId: (imageId?: string) => void;
};

const Profile: FC<ProfileProps> = ({ toggle, setAuthOpen, selectedImageId, setSelectedImageId }) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { subscribe } = useContext(EventContext) as TEvent;
    const { user, fetchUserNotifications } = useContext(UserContext) as TUser;
    const { fetchHistory, fetchImages, fetchRemoved, fetchSaved } = useContext(MediaContext) as TMedia;

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [pricingMenuOpen, setPricingMenuOpen] = useState(false);
    const [profileSection, setProfileSection, profileSectionRef] = useCapturedStateWorkaround<ProfileSection>(
        ProfileSection.MyIRCodes,
    );
    const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [history, setHistory] = useState<PagedResults<Image[]>>();
    const [historyPage, setHistoryPage, historyPageRef] = useCapturedStateWorkaround(1);
    const [notification, setNotification] = useState<PagedNotifications>();
    const [notificationPage, setNotificationPage, notificationPageRef] = useCapturedStateWorkaround(1);
    const [images, setImages] = useState<PagedResults<Image[]>>();
    const [imagesPage, setImagesPage, imagesPageRef] = useCapturedStateWorkaround(1);
    const [removed, setRemoved] = useState<PagedResults<Image[]>>();
    const [removedPage, setRemovedPage, removedPageRef] = useCapturedStateWorkaround(1);
    const [saved, setSaved] = useState<PagedResults<Image[]>>();
    const [savedPage, setSavedPage, savedPageRef] = useCapturedStateWorkaround(1);
    const [historyCount, setHistoryCount] = useState<number>();
    const [imagesCount, setImagesCount] = useState<number>();
    const [savedCount, setSavedCount] = useState<number>();
    const [notificationCount, setNotificationCount] = useState<number>();
    const [notificationsOpened, setNotificationsOpened] = useState(false);
    const [removedCount, setRemovedCount] = useState<number>();
    const pagesCounts = useRef<PageCounts>({ history: 1, images: 1, saved: 1, removed: 1, notifications: 1 });

    useEffect(() => updateCounts(history, 'history', setHistoryCount, pagesCounts), [history]);
    useEffect(() => updateCounts(images, 'images', setImagesCount, pagesCounts), [images]);
    useEffect(() => updateCounts(saved, 'saved', setSavedCount, pagesCounts), [saved]);
    useEffect(() => updateCounts(removed, 'removed', setRemovedCount, pagesCounts), [removed]);
    useEffect(() => updateCounts(notification, 'notifications', setNotificationCount, pagesCounts), [notification]);

    useEffect(() => {
        if (user) {
            // Since the user object along with its nested counts don't update as often, we want to make sure the value is updated from the user only if the list wasn't fetched before
            const replaceIfUndefined = (newValue: number) => (value: number | undefined) => value ?? newValue;

            setSavedCount(replaceIfUndefined(user.totalSavedCount ?? 0));
            setImagesCount(replaceIfUndefined(user.totalImagesCount ?? 0));
            setHistoryCount(replaceIfUndefined(user.totalHistoryCount ?? 0));
            setRemovedCount(replaceIfUndefined(user.totalArchivedCount ?? 0));
            setNotificationCount(replaceIfUndefined(user.totalNotificationsCount ?? 0));
        }
    }, [user]);

    const handleFetch = useCallback(
        async (
            handleFetch: () => Promise<PagedResults<Image[]> | undefined | false>,
            setState: Dispatch<SetStateAction<PagedResults<Image[]> | undefined>>,
            refresh?: boolean,
        ) => {
            try {
                const nextImages = await handleFetch();
                if (!nextImages) return;
                setState(refresh ? nextImages : getMergeResults(nextImages));
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [],
    );

    const fetchAndSetImages = useCallback(
        (refresh = false) =>
            handleFetch(
                async () => user?.userID !== undefined && (await fetchImages(user.userID, imagesPageRef.current - 1)),
                setImages,
                refresh,
            ),
        [user, fetchImages, handleFetch],
    );

    const fetchAndSetHistory = useCallback(
        (refresh = false) => handleFetch(async () => fetchHistory(historyPageRef.current - 1), setHistory, refresh),
        [fetchHistory, handleFetch],
    );

    const fetchAndSetSaved = useCallback(
        (refresh = false) => handleFetch(async () => fetchSaved(savedPageRef.current - 1), setSaved, refresh),
        [fetchSaved, handleFetch],
    );

    const fetchAndSetRemoved = useCallback(
        (refresh = false) => handleFetch(async () => fetchRemoved(removedPageRef.current - 1), setRemoved, refresh),
        [fetchRemoved, handleFetch],
    );

    const fetchAndSetNotifications = useCallback(
        async (refresh = false) => {
            try {
                const nextNotifications = await fetchUserNotifications(notificationPageRef.current - 1);
                setNotification(refresh ? nextNotifications : getNotificationMergeResults(nextNotifications));
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchUserNotifications],
    );

    interface ResetPagesExcludeOptions {
        history?: boolean;
        images?: boolean;
        saved?: boolean;
        removed?: boolean;
        notifications?: boolean;
    }

    const resetPages = (exclude?: ResetPagesExcludeOptions) => {
        const { history, images, saved, removed, notifications } = exclude ?? {};
        if (!history) {
            setHistoryPage(1);
        }
        if (!images) {
            setImagesPage(1);
        }
        if (!saved) {
            setSavedPage(1);
        }
        if (!removed) {
            setRemovedPage(1);
        }
        if (!notifications) {
            setNotificationPage(1);
        }
    };

    useEffect(() => {
        if (user?.userID === undefined) {
            return;
        }

        setIsLoading(true);
        switch (profileSectionRef.current) {
            case ProfileSection.SearchHistory: {
                resetPages({ history: true });
                const pages = pagesCounts.current.history ?? 1;
                if (historyPageRef.current > pages) {
                    setIsLoading(false);
                    return;
                }
                fetchAndSetHistory();
                break;
            }
            case ProfileSection.MyIRCodes: {
                resetPages({ images: true });
                const pages = pagesCounts.current.images ?? 1;
                if (imagesPageRef.current > pages) {
                    setIsLoading(false);
                    return;
                }
                fetchAndSetImages();
                break;
            }
            case ProfileSection.Saved: {
                resetPages({ saved: true });
                const pages = pagesCounts.current.saved ?? 1;
                if (savedPageRef.current > pages) {
                    setIsLoading(false);
                    return;
                }
                fetchAndSetSaved();
                break;
            }
            case ProfileSection.Removed: {
                resetPages({ removed: true });
                const pages = pagesCounts.current.removed ?? 1;
                if (removedPageRef.current > pages) {
                    setIsLoading(false);
                    return;
                }
                fetchAndSetRemoved();
                break;
            }
            case ProfileSection.Notifications: {
                resetPages({ notifications: true });
                const pages = pagesCounts.current.notifications ?? 1;
                if (notificationPageRef.current > pages) {
                    setIsLoading(false);
                    return;
                }
                fetchAndSetNotifications();
                break;
            }
        }
    }, [user, profileSection]);

    const handleFetchNextPage = useCallback(
        (category: PageCountsKey, page: number, fetch: (refresh?: boolean) => Promise<unknown>, refresh?: boolean) => {
            if (user?.userID === undefined) {
                return;
            }
            const pages = pagesCounts.current[category] ?? 1;
            if (page > pages) {
                return;
            }
            fetch(refresh);
        },
        [user],
    );

    useEffect(
        () => handleFetchNextPage('images', imagesPage, fetchAndSetImages),
        [fetchAndSetImages, handleFetchNextPage, imagesPage],
    );
    useEffect(
        () => handleFetchNextPage('history', historyPage, fetchAndSetHistory),
        [fetchAndSetHistory, handleFetchNextPage, historyPage],
    );
    useEffect(
        () => handleFetchNextPage('saved', savedPage, fetchAndSetSaved),
        [fetchAndSetSaved, handleFetchNextPage, savedPage],
    );
    useEffect(
        () => handleFetchNextPage('removed', removedPage, fetchAndSetRemoved),
        [fetchAndSetRemoved, handleFetchNextPage, removedPage],
    );
    useEffect(
        () => handleFetchNextPage('notifications', notificationPage, fetchAndSetNotifications),
        [fetchAndSetNotifications, handleFetchNextPage, notificationPage],
    );

    useAuth(() => {
        const scrolledToBottomUnsubscribe = subscribe(Event.ProfileScrolledToBottom, () => {
            if (selectedImageId !== undefined) {
                // Info is open
                return;
            }
            // TODO: Scrolling the cards seems to trigger this too
            if (isLoading) {
                console.log('Still loading...');
                return;
            }

            switch (profileSectionRef.current) {
                case ProfileSection.SearchHistory:
                    if ((historyPageRef.current ?? 0) < (pagesCounts.current.history ?? 0)) {
                        console.log('Increment History page to', historyPageRef.current + 1);
                        setHistoryPage(page => page + 1);
                    }
                    break;
                case ProfileSection.MyIRCodes:
                    if ((imagesPageRef.current ?? 0) < (pagesCounts.current.images ?? 0)) {
                        console.log('Increment Images page to', imagesPageRef.current + 1);
                        setImagesPage(page => page + 1);
                    }
                    break;
                case ProfileSection.Saved:
                    if ((savedPageRef.current ?? 0) < (pagesCounts.current.saved ?? 0)) {
                        console.log('Increment Saved page to', savedPageRef.current + 1);
                        setSavedPage(page => page + 1);
                    }
                    break;
                case ProfileSection.Removed:
                    if ((removedPageRef.current ?? 0) < (pagesCounts.current.removed ?? 0)) {
                        console.log('Increment Removed page to', removedPageRef.current + 1);
                        setRemovedPage(page => page + 1);
                    }
                    break;
                case ProfileSection.Notifications:
                    if ((notificationPageRef.current ?? 0) < (pagesCounts.current.notifications ?? 0)) {
                        console.log('Increment Notifications page to', notificationPageRef.current + 1);
                        setNotificationPage(page => page + 1);
                    }
                    break;
            }
        });
        return scrolledToBottomUnsubscribe;
    }, [selectedImageId]);

    useEffect(() => {
        const refreshSavedListenerUnsubscribe = subscribe(Event.RefreshSaved, () => fetchAndSetSaved(true));
        return refreshSavedListenerUnsubscribe;
    }, [subscribe, savedPage, saved?.Pages, fetchAndSetSaved]);

    useEffect(() => {
        const refreshImagesListenerUnsubscribe = subscribe(Event.RefreshImages, () => fetchAndSetImages(true));
        return refreshImagesListenerUnsubscribe;
    }, [subscribe, fetchAndSetImages]);

    const toggleSubMenu = (open: boolean) => {
        setDrawerOpen(open);
    };

    const togglePricingMenu = (open: boolean) => {
        setPricingMenuOpen(open);
    };

    const toggleEditProfile = () => {
        setIsEditProfileOpen(!isEditProfileOpen);
    };

    const handleNotificationClick = () => {
        setProfileSection(ProfileSection.Notifications);
        setNotificationsOpened(true);
    };

    usePageTitle(profileSectionRef.current, { sendPageview: true, revertOnUnmount: true });

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                mb: 2,
                display: 'flex',
                flexGrow: 1,
                // backgroundColor: 'blue',
            }}
        >
            <Puller />
            <Box
                sx={{
                    // position: "relative",
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingX: 2,
                    // padding: "1rem",
                    // gap: "1rem",
                }}
            >
                <IconButton
                    sx={{
                        // fontSize: '1rem',
                        width: 30,
                        height: 30,
                    }}
                    onClick={toggle}
                    // onClick={() => onDeleteCustom(key)}
                >
                    <i
                        className="fa-regular fa-xmark"
                        style={{
                            color: darkMode ? Color.White : Color.Black,
                            // position: 'absolute',
                            // top: "1rem",
                            // right: "1rem",
                            // cursor: "pointer"
                        }}
                        // onClick={toggle}
                    ></i>
                </IconButton>
            </Box>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    paddingX: 2,
                    alignItems: 'center',
                }}

                // sx={{
                //     width: "100%",
                //     padding: "2rem",
                //     display: "flex",
                //     alignItems: "center",
                //     // justifyContent: "space-between",
                //     gap: "1rem",
                // }}
            >
                <UserAvatar
                    profileUrl={user?.profileUrl}
                    userName={user?.userName}
                    sx={{
                        width: '66px',
                        height: '66px',
                    }}
                />
                <Typography
                    // variant="mainFont6"
                    sx={{
                        flexGrow: 1,
                        // fontSize: "2rem",

                        fontFamily: 'Nocturne Serif',
                        fontSize: '32px',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.03em',
                        textAlign: 'left',

                        color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                    }}
                >
                    {user?.userName || `IRCODE USER ${user?.userID ?? ''}`}
                </Typography>

                {/*
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    />
                */}
                <IconButton onClick={handleNotificationClick}>
                    <Badge color="error" variant="dot" invisible={!user?.countUnRead || notificationsOpened}>
                        <i
                            className="fa-sharp fa-regular fa-bell"
                            style={{
                                color: darkMode ? Color.White : 'inherit',
                            }}
                        ></i>
                    </Badge>
                </IconButton>
                <IconButton onClick={() => toggleSubMenu(true)}>
                    {/* <MoreVertIcon sx={{ color: "#888888" }} /> */}
                    <i
                        className="fa-regular fa-ellipsis-vertical"
                        style={{
                            // color: theme.palette.primary.main,
                            color: darkMode ? Color.White : Color.Black,
                        }}
                        // onClick={(event) => setAnchorEl(event.currentTarget)}
                    ></i>
                </IconButton>
            </Stack>
            <Box
                sx={
                    {
                        // width: "100%",
                        // alignItems: "center",
                        // justifyContent: "space-between",
                        // gap: "1.5rem"
                    }
                }
            >
                <NavTiles
                    historyTabCount={historyCount}
                    imagesTabCount={imagesCount}
                    savedTabCount={savedCount}
                    removedTabCount={removedCount}
                    selected={profileSection}
                    onClick={profileSection => {
                        setSelectedImageId(undefined);
                        setProfileSection(profileSection);
                    }}
                />
            </Box>

            {/*
                <Button
                    variant="irdbGradient"
                    sx={{
                        height: '56px',
                        textTransform: 'none',
                    }}
                    onClick={() => togglePricingMenu(true)}
                >
                    <i className="fa-regular fa-gem"></i> Upgrade your account
                </Button>
            */}

            {/* Search History */}
            {user && profileSection === ProfileSection.SearchHistory && (
                // TODO: Switch to event
                <ImageList
                    selectedImageId={selectedImageId}
                    setSelectedImageId={setSelectedImageId}
                    images={history?.Results ?? []}
                    showLoading={isLoading}
                    emptyMessage="No search history"
                    onRefresh={() => {
                        // fetchUserImages()
                    }}
                    onUnmount={() => {
                        setHistory(undefined);
                    }}
                    when={image => image.timeScanned}
                />
            )}

            {/* My IRCODEs */}
            {user && profileSection === ProfileSection.MyIRCodes && (
                // TODO: Switch to event
                <ImageList
                    selectedImageId={selectedImageId}
                    setSelectedImageId={setSelectedImageId}
                    images={images?.Results ?? []}
                    showLoading={isLoading}
                    emptyMessage="No IRCODES"
                    onRefresh={() => {
                        // fetchUserImages()
                    }}
                    onUnmount={() => {
                        setImages(undefined);
                    }}
                    when={image => image.imageCreated}
                />
            )}

            {/* Saved */}
            {user && profileSection === ProfileSection.Saved && (
                // TODO: Switch to event
                <ImageList
                    selectedImageId={selectedImageId}
                    setSelectedImageId={setSelectedImageId}
                    images={saved?.Results ?? []}
                    showLoading={isLoading}
                    emptyMessage="No saved IRCODES"
                    onRefresh={() => {
                        // fetchUserImages()
                    }}
                    onUnmount={() => {
                        setSaved(undefined);
                    }}
                    when={image => image.timeSaved}
                />
            )}

            {/* Removed IRCODES */}
            {user && profileSection === ProfileSection.Removed && (
                // TODO: Switch to event
                <ImageList
                    selectedImageId={selectedImageId}
                    setSelectedImageId={setSelectedImageId}
                    images={removed?.Results ?? []}
                    showLoading={isLoading}
                    emptyMessage="You have no deleted IRCODES"
                    onRefresh={() => {
                        // fetchUserImages()
                    }}
                    onUnmount={() => {
                        setRemoved(undefined);
                    }}
                    when={image => image.timeArchived}
                />
            )}

            {/* Sub Menu */}
            <Drawer
                anchor="top"
                open={drawerOpen}
                onClose={() => toggleSubMenu(false)}
                style={{
                    zIndex: ZIndex.ProfileSubMenu,
                }}
                PaperProps={{
                    style: {
                        maxHeight: '45vh',
                        backgroundColor: 'rgba(255, 255, 255, .25)',
                        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.35)',
                        borderRadius: '0px 0px 10px 10px',
                        backdropFilter: 'blur(20px)',
                        WebkitBackdropFilter: 'blur(20px)',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '1.5rem',
                        justifyContent: 'space-between',
                    },
                }}
                ModalProps={{
                    BackdropProps: {
                        style: {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        },
                    },
                }}
            >
                <ProfileSubMenu
                    setAuthOpen={setAuthOpen}
                    closeMenu={(closeProfile?: boolean) => {
                        toggleSubMenu(false);
                        if (closeProfile) {
                            toggle();
                        }
                    }}
                />
            </Drawer>

            {/* Pricing */}
            <Drawer
                anchor="top"
                open={pricingMenuOpen}
                onClose={() => togglePricingMenu(false)}
                style={{
                    zIndex: ZIndex.ProfileSubMenu,
                }}
                PaperProps={{
                    style: {
                        maxHeight: '100%',
                        backgroundColor: 'rgba(255, 255, 255)',
                        borderRadius: 8,
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <Pricing closePricing={() => togglePricingMenu(false)} />
            </Drawer>

            <SwipeableDrawer
                anchor="bottom"
                open={profileSection === ProfileSection.Notifications}
                onOpen={() => {}}
                onClose={() => {}}
                ModalProps={{
                    keepMounted: false,
                }}
                style={{
                    zIndex: ZIndex.ProfileSheet,
                }}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        height: '100%',
                        overflow: 'scroll',
                        background: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                    },
                }}
            >
                <Puller />
                <NotificationsList
                    notifications={notification?.Results?.Notifications ?? []}
                    emptyMessage="No Notifications"
                    onClose={() => {
                        setNotification(undefined);
                        setProfileSection(ProfileSection.MyIRCodes);
                    }}
                    onUnmount={() => setNotification(undefined)}
                    showLoading={isLoading}
                />
            </SwipeableDrawer>
        </Stack>
    );
};

export default Profile;
