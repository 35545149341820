import ProfileTextField, { ProfileTextFieldProps } from './ProfileTextField';
import { TAccount, useProfileInfo } from '../../../stores/useProfileState';

export interface ProfileEmailFieldProps extends Omit<ProfileTextFieldProps, 'onChange' | 'value'> {}

const helperTextSelector = (state: TAccount) => {
    const authProviders = state.authProviders;
    let helperText = 'Your email address is used for signing in and cannot be changed.';
    if (authProviders.password) {
        return helperText;
    }
    const provider = Object.entries(authProviders).find(([, value]) => value);
    const providerID = provider?.[0];
    if (providerID) {
        helperText = `Your email address is tied to your ${providerID.charAt(0).toUpperCase()}${providerID.split('.')[0].slice(1)} account and cannot be changed.`;
    }
    return helperText;
};

const cb = () => {};

const ProfileEmailField = (props: ProfileEmailFieldProps) => {
    const email = useProfileInfo.use.email();
    const helperText = useProfileInfo(helperTextSelector);
    return <ProfileTextField {...props} value={email} onChange={cb} disabled helperText={helperText} />;
};

export default ProfileEmailField;
