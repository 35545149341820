import { ComponentType, createContext, PropsWithChildren, useEffect, useState } from 'react';
import useMetaErrors from '../../hooks/metaErrors/useMetaErrors';

export interface MetaErrorsContextType {
    scopeId: string | null | undefined;
    setScopeId: (id: string | null | undefined) => void;
}

export const MetaErrorsContext = createContext<MetaErrorsContextType>({ scopeId: undefined, setScopeId: () => {} });

export const MetaErrorsProvider = ({ id: propsId, children }: PropsWithChildren<{ id?: string | null }>) => {
    const [scopeId, setScopeId] = useState(propsId);

    useEffect(() => {
        if (propsId) {
            setScopeId(propsId);
        }
    }, [propsId, setScopeId]);

    useEffect(() => {
        if (!scopeId) return;
        if (!useMetaErrors.getState().errors[scopeId]) {
            useMetaErrors.getState().setErrors(scopeId, {});
        }
        return () => {
            useMetaErrors.getState().clearErrors(scopeId);
        };
    }, [scopeId]);
    return <MetaErrorsContext.Provider value={{ scopeId, setScopeId }}>{children}</MetaErrorsContext.Provider>;
};

// A bit of an old-school trick, but it's meant to be used as a way to wrap a component with a provider without modifying the component tree
export const WithMetaErrorsProvider = <T extends Record<string, any>>(Component: ComponentType<T>) => {
    function Render(props: T & { validationId?: string }) {
        const { validationId, ...rest } = props;
        return (
            <MetaErrorsProvider id={validationId}>
                <Component {...(rest as T)} />
            </MetaErrorsProvider>
        );
    }
    // For debugging purposes
    Render.displayName = `WithMetaErrorsProvider(${Component.displayName || Component.name})`;
    return Render;
};
