import { useCallback } from 'react';
import useApi, { Method } from 'src/hooks/useApi';

export const useImageFetcher = () => {
    const { request } = useApi();

    const fetchImage = useCallback(
        async (url: string, filename: string): Promise<File> => {
            const fetchImageDirectly = async (): Promise<Blob> => {
                const response = await fetch(url, { mode: 'cors' });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return await response.blob();
            };

            const fetchImageViaProxy = async (): Promise<Blob> => {
                const response = await request({
                    method: Method.GET,
                    path: `/proxy?url=${encodeURIComponent(url)}`,
                    axiosParams: {
                        responseType: 'blob',
                    },
                });
                return response.data;
            };

            let blob: Blob;
            try {
                blob = await fetchImageDirectly();
            } catch (error) {
                console.warn('Direct fetch failed, falling back to proxy:', error);
                try {
                    blob = await fetchImageViaProxy();
                } catch (error) {
                    blob = new Blob();
                    console.warn('Proxy fetch failed:', error);
                }
            }

            return new File([blob], filename, { type: blob.type });
        },
        [request],
    );

    return { fetchImage };
};
