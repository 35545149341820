import { useContext } from 'react';
import { BulkUploaderContext } from '../contexts/specialized/BulkUploaderContext';

const useBulkUploaderState = () => {
    const storeApi = useContext(BulkUploaderContext);
    if (!storeApi) {
        throw new Error('useBulkUploaderStoreApi must be used within a BulkUploaderProvider');
    }
    return storeApi;
};

export default useBulkUploaderState;
