import React, { useContext, useState } from 'react';
import {
    Stack,
    FormControl,
    FormLabel,
    RadioGroup,
    Typography,
    FormControlLabel,
    Radio,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import AdminContext, { TAdmin } from '../../contexts/AdminContext';
import { Color } from '../../Color';

enum AccountType {
    Anonymous = 'Anonymous',
    Basic = 'Basic',
    Pro = 'Pro',
    Enterprise = 'Enterprise',
}

interface ModAccountProps {
    userName: string;
    accountType: string;
    value: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setOpenAccountModDialog: (open: boolean) => void;
    selectedUserId: number | null;
    selectedUserFBId: string;
    setSelectedUserId: React.Dispatch<React.SetStateAction<number | null>>;
    setSelectedUserFBId: React.Dispatch<React.SetStateAction<string>>;
}

export default function ModAccount({
    value,
    accountType,
    userName,
    handleChange,
    setOpenAccountModDialog,
    selectedUserFBId,
    selectedUserId,
}: ModAccountProps) {
    const { modifyAccountType, analyticsRefresh } = useContext(AdminContext) as TAdmin;
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [newType, setNewType] = useState('');

    const isSubmitDisabled = value === accountType;

    const handleSubmit = async () => {
        if (isSubmitDisabled) {
            alert(`${userName} is already an ${value}.`);
        } else {
            setNewType(value);
            setConfirmDialog(true);
        }
    };

    const handleConfirm = async () => {
        if (selectedUserId !== null && selectedUserFBId) {
            const updated = await modifyAccountType(selectedUserId, selectedUserFBId, value);
            console.log(selectedUserFBId, selectedUserId, value);
            if (updated) {
                alert('Account type updated successfully.');
            } else {
                console.error('Failed to update account type.', selectedUserId, selectedUserFBId, value);
            }
            analyticsRefresh();
            setNewType('');
            setOpenAccountModDialog(false);
            setConfirmDialog(false);
        } else {
            console.error('Selected user ID or Firebase ID is null.');
        }
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                m: 'auto',
                p: 4,
                borderRadius: '8px',
                backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                boxShadow: 1,
            }}
        >
            <FormControl>
                <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    sx={{ color: Color.PrimaryLavender, variant: 'mainFont9' }}
                >
                    Upgrade/Downgrade Account Type
                </FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="account-type"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value={AccountType.Anonymous}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Anonymous
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value={AccountType.Basic}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Basic
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value={AccountType.Pro}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Pro
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        value={AccountType.Enterprise}
                        control={
                            <Radio
                                sx={{ color: Color.PrimaryLavender, '&.Mui-checked': { color: Color.PrimaryLavender } }}
                            />
                        }
                        label={
                            <Typography variant="notificationSettingInfo" sx={{ color: Color.PrimaryLavender }}>
                                Enterprise
                            </Typography>
                        }
                    />
                </RadioGroup>
                <Button
                    onClick={handleSubmit}
                    sx={{
                        color: Color.White,
                        backgroundColor: Color.PrimaryLavender,
                        '&:hover': { backgroundColor: Color.PalePurple },
                    }}
                >
                    Submit
                </Button>
            </FormControl>
            <Button
                onClick={() => setOpenAccountModDialog(false)}
                sx={{
                    color: Color.White,
                    backgroundColor: Color.PrimaryLavender,
                    '&:hover': { backgroundColor: Color.PalePurple },
                }}
            >
                Close
            </Button>

            <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
                <DialogTitle sx={{ color: Color.PrimaryLavender, variant: 'mainFont9' }}>
                    Confirm Account Type Change
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: Color.PrimaryLavender, variant: 'notificationSettingInfo' }}>
                        Are you sure you want to change {userName} to {newType}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmDialog(false)}
                        sx={{
                            color: Color.White,
                            backgroundColor: Color.PrimaryLavender,
                            '&:hover': { backgroundColor: Color.PalePurple },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        color="primary"
                        sx={{
                            color: Color.White,
                            backgroundColor: Color.PrimaryLavender,
                            '&:hover': { backgroundColor: Color.PalePurple },
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
