import { Box, BoxProps, Fade, Typography, useTheme } from '@mui/material';
import React, { ComponentPropsWithoutRef } from 'react';
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';
import { Color } from 'src/Color';

type RenderWithStateParams = Omit<DropzoneState, 'getRootProps' | 'getInputProps'>;

export interface FileDropAreaBaseProps {
    dropzoneOptions?: DropzoneOptions;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    dragValidText?: string | null;
    dragInvalidText?: string | null;
    renderWithState?: (params: RenderWithStateParams) => React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FileDropAreaProps<T extends React.ElementType<any>> = FileDropAreaBaseProps & { component?: T } & Omit<
        BoxProps<T, T extends React.ElementType<infer P> ? P : ComponentPropsWithoutRef<T>>,
        keyof FileDropAreaBaseProps
    >;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function FileDropArea<T extends React.ElementType<any> = 'div'>({
    children,
    dropzoneOptions,
    inputProps,
    dragValidText = 'Drop here',
    dragInvalidText = 'Invalid file',
    renderWithState,
    ...rest
}: FileDropAreaProps<T>) {
    const { getRootProps, getInputProps, ...dropzoneRest } = useDropzone(dropzoneOptions);
    const { isDragAccept, isDragReject } = dropzoneRest;
    const { palette } = useTheme();

    const rootProps = {
        ...rest,
        sx: {
            position: 'relative',
            ...rest.sx,
        },
    } as const;

    const dragTextProps = {
        sx: {
            backgroundColor: Color.DarkModeBlack,
            px: 2,
            py: 1,
            color: palette.primary.contrastText,
            borderRadius: 1,
        },
    };

    return (
        <Box {...getRootProps(rootProps)}>
            <input {...getInputProps(inputProps)} />
            {children}
            {renderWithState?.(dropzoneRest)}
            <Box
                component="span"
                sx={{
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 'inherit',
                    '&::before': {
                        content: '""',
                        borderRadius: 'inherit',
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor:
                            isDragAccept ? '#a5a5d146'
                            : isDragReject ? '#ff000046'
                            : 'transparent',
                        transition: 'background-color 0.3s',
                    },
                }}
            >
                <Fade in={!!dragValidText && isDragAccept} unmountOnExit style={{ zIndex: 0 }} timeout={300}>
                    <Typography {...dragTextProps}>{dragValidText}</Typography>
                </Fade>
                <Fade in={!!dragInvalidText && isDragReject} unmountOnExit style={{ zIndex: 0 }} timeout={300}>
                    <Typography {...dragTextProps}>{dragInvalidText}</Typography>
                </Fade>
            </Box>
        </Box>
    );
}
