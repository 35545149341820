import { Avatar, AvatarProps } from '@mui/material';

export interface UserAvatarProps extends Omit<AvatarProps, 'src' | 'children'> {
    profileUrl?: string;
    userName?: string;
}

export default function UserAvatar({ profileUrl, userName, ...rest }: UserAvatarProps) {
    return (
        <Avatar
            {...rest}
            src={profileUrl}
            alt={userName}
            sx={{
                backgroundColor: '#ddd',
                color: '#aaa',
                ...rest.sx,
            }}
        />
    );
}
