import { Callback } from 'src/types/util';
import { AbortablePromise } from '../AbortablePromise/AbortablePromise';

type PromiseOrPromiseCallback = Promise<any> | (() => Promise<any>);

export interface TPromiseQueue<R, E = unknown> {
    isExecuting: Readonly<boolean>;
    addToQueue(promise: PromiseOrPromiseCallback): Promise<void>;
    clearQueue(): void;
    awaitQueueEnd(): Promise<void>;
    onQueueFinished: (results: R[], errors: E[]) => void;
}

class PromiseQueue<R, E = unknown> implements TPromiseQueue<R, E> {
    private _queue: Array<PromiseOrPromiseCallback> = [];
    private _promiseResolvers: Array<[resolve: Callback<void>, reject: Callback<void>]> = [];
    private _results: R[] = [];
    private _errors: E[] = [];
    private _abortController = new AbortController();
    public isExecuting = false;
    public onQueueFinished: (results: R[], errors: E[]) => void;
    public onError?: (error: E) => void;

    constructor(onQueueFinished: (results: R[], errors: E[]) => void, onError?: (error: E) => void) {
        this.onQueueFinished = onQueueFinished.bind(this);
        this.onError = onError?.bind(this);
        this.execute = this.execute.bind(this);
        this.addToQueue = this.addToQueue.bind(this);
        this.clearQueue = this.clearQueue.bind(this);
        this.awaitQueueEnd = this.awaitQueueEnd.bind(this);
    }

    async execute(promise: PromiseOrPromiseCallback) {
        try {
            let result: R;
            if ('then' in promise) {
                result = await promise;
            } else {
                result = await promise();
            }
            this._results.push(result);
        } catch (error) {
            this._errors.push(error as E);
            this.onError?.(error as E);
        }
        if (this._queue.length) {
            await this.execute(this._queue.shift()!);
            return;
        }
        this.onQueueFinished(this._results, this._errors);
        this.isExecuting = false;
        this._promiseResolvers.forEach(([resolve]) => resolve());
        this._promiseResolvers = [];
        this._results = [];
        this._errors = [];
    }

    addToQueue(promise: PromiseOrPromiseCallback): Promise<void> {
        if (this._abortController.signal.aborted) {
            this._abortController = new AbortController();
        }
        if (this.isExecuting) {
            this._queue.push(promise);
            return Promise.resolve();
        }
        this.isExecuting = true;
        return this.execute(promise);
    }

    clearQueue(): void {
        this._queue = [];
    }

    awaitQueueEnd(): Promise<void> {
        return new AbortablePromise<void>((resolve, reject) => {
            this._promiseResolvers.push([resolve, reject]);
        }, this._abortController);
    }
}

export default PromiseQueue;
