export enum Quality {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
    Original = 'original',
}

const forQuality = (quality: Quality, image: HTMLImageElement): { width: number; height: number; quality: number } => {
    switch (quality) {
        case Quality.Low:
            return {
                width: 640,
                height: 480,
                quality: 0.5,
            };
        case Quality.Medium:
            return {
                width: 1024,
                height: 768,
                quality: 0.7,
            };
        case Quality.High:
            return {
                width: 2048,
                height: 1536,
                quality: 0.9,
            };
        case Quality.Original:
            return {
                width: image.width,
                height: image.height,
                quality: 1.0,
            };
    }
};

const calculateSize = (img: HTMLImageElement, maxWidth: number, maxHeight: number) => {
    let width = img.width;
    let height = img.height;

    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
    }

    return [width, height];
};

function readableBytes(bytes: number) {
    const i = Math.floor(Math.log(bytes) / Math.log(1024)),
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

export interface Asset {
    file: File;
    width: number;
    height: number;
    preview: string;
    url?: string;
}

export interface CropArea {
    unit: '%' | 'px';
    width: number;
    height: number;
    x: number;
    y: number;
}

export const shrink = (file: File, q: Quality): Promise<Asset> => {
    const MIME_TYPE = 'image/jpeg';
    return new Promise<Asset>((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onerror = function () {
            URL.revokeObjectURL(this.src);
            reject('Failed to load image');
        };
        img.onload = function () {
            URL.revokeObjectURL(img.src);
            const { width, height, quality } = forQuality(q, img);
            const [newWidth, newHeight] = calculateSize(img, width, height);
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(
                blob => {
                    if (blob) {
                        console.log(`Original: ${readableBytes(file.size)}`);
                        console.log(`Compressed: ${readableBytes(blob.size)}`);

                        const resizedFile = new File([blob], file.name, { type: MIME_TYPE });
                        // imageOperation.file = new File([blob], file!.name, { type: "image/jpeg" });
                        // imageOperation.width = newWidth;
                        // imageOperation.height = newHeight;
                        // imageOperation.preview = URL.createObjectURL(imageOperation.file);
                        resolve({
                            file: resizedFile,
                            width: newWidth,
                            height: newHeight,
                            preview: URL.createObjectURL(resizedFile),
                        });
                    } else {
                        reject('Failed to resize image');
                    }
                },
                MIME_TYPE,
                quality,
            );
        };
    });
};

export const cropImage = (file: File, cropArea: CropArea): Promise<Asset & { cropArea: CropArea }> => {
    return new Promise<Asset & { cropArea: CropArea }>((resolve, reject) => {
        // console.log('file', file);
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onerror = function () {
            // console.log('this.src', this.src);
            URL.revokeObjectURL(this.src);
            reject('Failed to load image');
        };
        img.onload = function () {
            // console.log('img', img);
            // console.log('img.width', img.width);
            // console.log('img.height', img.height);

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            let newWidth = 0;
            let newHeight = 0;

            if (cropArea.unit === 'px') {
                canvas.width = cropArea.width;
                canvas.height = cropArea.height;
                newWidth = cropArea.width;
                newHeight = cropArea.height;
            } else {
                canvas.width = (cropArea.width / 100) * img.width;
                canvas.height = (cropArea.height / 100) * img.height;
                newWidth = (cropArea.width / 100) * img.width;
                newHeight = (cropArea.height / 100) * img.height;
            }

            ctx?.drawImage(
                img,
                cropArea.unit === 'px' ? cropArea.x : (cropArea.x / 100) * img.width,
                cropArea.unit === 'px' ? cropArea.y : (cropArea.y / 100) * img.height,
                newWidth,
                newHeight,
                0,
                0,
                newWidth,
                newHeight,
            );
            // console.log('canvas', canvas);

            canvas.toBlob(
                blob => {
                    // console.log('blob', blob);
                    if (blob) {
                        // console.log(`Original: ${readableBytes(image.file!.size)}`);
                        // console.log(`Compressed: ${readableBytes(blob.size)}`);
                        const croppedFile = new File([blob], file.name, { type: 'image/jpeg' });
                        resolve({
                            file: croppedFile,
                            width: newWidth,
                            height: newHeight,
                            preview: URL.createObjectURL(croppedFile),
                            cropArea,
                        });
                    } else {
                        reject('Failed to resize image');
                    }
                },
                'image/jpeg',
                0.8,
            );
        };
    });
};

export const convertToJpeg = async (file: File, quality?: number): Promise<File> => {
    return new Promise<File>((resolve, reject) => {
        if (file.type === 'image/jpeg' && quality === undefined) {
            resolve(file);
        } else {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onerror = function () {
                URL.revokeObjectURL(this.src);
                reject('Failed to load image');
            };
            img.onload = function () {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');

                ctx?.drawImage(img, 0, 0, img.width, img.height);
                canvas.toBlob(
                    blob => {
                        if (blob) {
                            // Change extension to .jpeg
                            const position = file.name.lastIndexOf('.');
                            const fileName =
                                file.name.substring(0, position === -1 ? file.name.length : position) + '.jpeg';

                            const newFile = new File([blob], fileName, { type: 'image/jpeg' });
                            resolve(newFile);
                        } else {
                            reject('Failed to convert to jpeg');
                        }
                    },
                    'image/jpeg',
                    quality ?? 0.8,
                );
            };
        }
    });
};

export const CAMERA_CAPTURE_FILENAME = 'camera_capture.jpeg';

export const dataUrlToFile = (dataUrl: string, fileName = 'screenshot.jpeg'): File => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    const blob = new Blob([u8arr], { type: mime });
    return new File([blob], fileName, { type: 'image/jpeg' });
};
