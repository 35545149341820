import { useMemo, useRef } from 'react';

function getTouchDistance(touches: React.TouchList) {
    return Math.hypot(touches[0].pageX - touches[1].pageX, touches[0].pageY - touches[1].pageY);
}

function getTouchVerticalDirection(touch: React.Touch, lastTouch: React.Touch) {
    return Math.sign(touch.pageY - lastTouch.pageY);
}

function getTouchHorizontalDirection(touch: React.Touch, lastTouch: React.Touch) {
    return Math.sign(touch.pageX - lastTouch.pageX);
}

function getIsSameDirection(touches: React.TouchList, lastTouches: React.TouchList) {
    const horizontalDirection1 = getTouchHorizontalDirection(touches[0], lastTouches[0]);
    const horizontalDirection2 = getTouchHorizontalDirection(touches[1], lastTouches[1]);
    const verticalDirection1 = getTouchVerticalDirection(touches[0], lastTouches[0]);
    const verticalDirection2 = getTouchVerticalDirection(touches[1], lastTouches[1]);
    return horizontalDirection1 === horizontalDirection2 && verticalDirection1 === verticalDirection2;
}

const PINCH_THRESHOLD = 0.05;

const usePinch = ({ onPinch, enabled = true }: { onPinch: (amount: number) => void; enabled?: boolean }) => {
    const lastDistance = useRef<number>(0);
    const lastTouches = useRef<React.TouchList | null>(null);

    const pinchProps = useMemo(
        () =>
            !enabled ?
                {}
            :   {
                    onTouchStart(e: React.TouchEvent) {
                        if (e.touches.length !== 2) return;
                        const dist = getTouchDistance(e.touches);
                        lastDistance.current = dist;
                    },
                    onTouchMove(e: React.TouchEvent) {
                        if (e.touches.length !== 2) return;
                        if (lastTouches.current && getIsSameDirection(e.touches, lastTouches.current)) {
                            return;
                        }
                        const dist = getTouchDistance(e.touches);
                        const diff = -1 * (lastDistance.current - dist);
                        if (Math.abs(diff) >= PINCH_THRESHOLD) {
                            onPinch(diff);
                        }
                        lastDistance.current = dist;
                        lastTouches.current = e.touches;
                    },
                    onTouchEnd(e: React.TouchEvent) {
                        lastDistance.current = 0;
                        lastTouches.current = null;
                    },
                },
        [enabled, onPinch],
    );

    return { pinchProps };
};

export default usePinch;
