import { useContext, useEffect, useState } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import UserContext, { TUser } from '../../../../contexts/UserContext';

interface AnalyticsTopProps {
    imageID: string;
    setTabInformation: () => void;
}

export function AnalyticsTop({ imageID, setTabInformation }: AnalyticsTopProps) {
    const { user } = useContext(UserContext) as TUser;

    useEffect(() => {
        // query(
        //     Type.Count,
        //     {
        //         // analysisType: 'count_unique',
        //         // eventCollection: 'view',
        //         // targetProperty: 'username',
        //         // timeframe: 'this_7_days',
        //     }
        // );
    }, []);

    const image = undefined; // imageFromImageOperation(imageOperation);

    const [chartHeight, setChartHeight] = useState(200);
    // useEffect(() => {
    //     if (stackRef.current) {
    //         const layout = stackRef.current?.getBoundingClientRect();
    //         // console.log(layout);
    //         setChartHeight(layout.height);
    //         setIsLoading(false);
    //     }
    // }, []);

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                // flexGrow: 1,
                // flexShrink: 0,
                p: 3,
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '24px',
                    fontWeight: 400,
                    lineHeight: '26px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                }}
            >
                {/* Securely transfer your IRCODE */}
            </Typography>

            <Box
                style={{
                    height: 250,
                    width: '100%',
                }}
            ></Box>
        </Stack>
    );
}

interface AnalyticsBottomProps {
    imageID: string;
    // transferIrcode: ITransferIrcode | undefined;
}

export function AnalyticsBottom({ imageID }: AnalyticsBottomProps) {
    return <></>;
}
