import { Box, Stack, Typography } from '@mui/material';
import { Color } from '../Color';
import WordMarkWhite from '../images/WordMarkWhite.svg';

export default function PageNotFound() {
    return (
        <Stack
            direction="column"
            spacing={8}
            sx={{
                display: 'flex',
                height: '100vh',
                p: 2,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: Color.PrimaryDarkGrayBlue,
            }}
        >
            <Box
                component="img"
                sx={{
                    objectFit: 'contain',
                    width: 'auto',
                    height: '56px',
                }}
                src={WordMarkWhite}
                alt=""
            />
            <Typography
                variant="mainFont6"
                sx={{
                    textAlign: 'center',
                    color: Color.White,
                }}
            >
                That page does not exist.
            </Typography>
        </Stack>
    );
}
