import React, { useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, TableContainer, Paper } from '@mui/material';
import { Color } from 'src/Color';

type Order = 'asc' | 'desc';

interface Column {
    label: string;
    sortValue?: string;
    collapse?: boolean;
}

interface Data {
    [key: string]: any;
}

interface TableProps {
    columns: Column[];
    rows: Data[];
    cellRenderers?: { [key: string]: (row: Data) => React.ReactNode };
    overflowCount?: number;
}

const SortableTable: React.FC<TableProps> = ({ columns, rows, cellRenderers, overflowCount }) => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('');

    const sortedRows = React.useMemo(() => {
        const comparator = (a: Data, b: Data) => {
            const getValue = (obj: Data, path: string): any => {
                return path.split('.').reduce((o, i) => o[i], obj);
            };
            let aValue = getValue(a, orderBy);
            let bValue = getValue(b, orderBy);
            if (typeof aValue === 'string') aValue = aValue.toLowerCase();
            if (typeof bValue === 'string') bValue = bValue.toLowerCase();
            return (
                aValue < bValue ?
                    order === 'asc' ?
                        -1
                    :   1
                : aValue > bValue ?
                    order === 'asc' ?
                        1
                    :   -1
                :   0
            );
        };
        return rows.slice().sort(comparator);
    }, [rows, order, orderBy]);

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                background: 'none',
                ...(overflowCount && {
                    maxHeight: `${80 + (overflowCount - 1) * 55}px`,
                    overflow: 'auto',
                }),
            }}
        >
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            background: Color.LightLavenderLightMode,
                            textTransform: 'uppercase',
                        }}
                    >
                        {columns.map(column => (
                            <TableCell
                                key={column.label}
                                sortDirection={orderBy === column.sortValue ? order : false}
                                style={column.collapse ? { whiteSpace: 'nowrap', width: '1%' } : {}}
                            >
                                {column.sortValue ?
                                    <TableSortLabel
                                        active={orderBy === column.sortValue}
                                        direction={orderBy === column.sortValue ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.sortValue || '')}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                :   column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.map((row, index) => (
                        <TableRow key={index}>
                            {columns.map(column => (
                                <TableCell key={column.label}>
                                    {cellRenderers && cellRenderers[column.label] ?
                                        cellRenderers[column.label](row)
                                    :   row[column.label]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SortableTable;
