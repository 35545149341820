import { useCallback, useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import Pending from './Pending';
import Available from './Available';
import Unavailable from './Unavailable';
import Adding from './Adding';
import Added from './Added';
import UploaderProgressBar from './UploaderProgressBar';
import { WithBulkUploaderProvider } from 'src/contexts/specialized/BulkUploaderContext';
import useBulkUploaderState from 'src/stores/useBulkUploaderState';
import useBeforeUnloadConfirm from 'src/hooks/useBeforeUnloadConfirm';
import { BulkUploaderStore } from 'src/stores/BulkUploader/store';
import UploaderFileDropArea from './UploaderFileDropArea';
import UploaderCancelButton from './UploaderCancelButton';

interface Props {
    onStart: () => void;
    onAdd?: (imageId: string) => Promise<void>;
    onComplete: () => void;
}

const selectors = {
    areAllAddedOrUnavailable: (state: BulkUploaderStore) =>
        state.addedIrcodes.length + state.unavailableIrcodes.length === state.imageOperationsCount,
    isCancelProgressEnabled: (state: BulkUploaderStore) =>
        state.isCanceling && !selectors.areAllAddedOrUnavailable(state) && !state.hasUploading,
    isAlternateCancelButton: (state: BulkUploaderStore) =>
        !state.hasAvailable && (state.hasAdded || state.hasUnavailable) && !state.hasUploading,
    isUploading: (state: BulkUploaderStore) =>
        !!state.files?.length && !state.hasPending && !state.hasAvailable && !state.hasUnavailable,
} as const;

const BulkUploader = WithBulkUploaderProvider(function BulkUploader({ onStart, onComplete, onAdd }: Props) {
    const { enable: enableUnloadConfirm, disable: disableUnloadConfirm } = useBeforeUnloadConfirm();
    const storeApi = useBulkUploaderState();
    const useSelectors = storeApi.use;
    const processFiles = useSelectors.processFiles();
    const registerImages = useSelectors.registerSelectedImages();

    const fileDropRef = useRef<HTMLDivElement>(null);

    useEffect(
        () =>
            storeApi.subscribe((state, prevState) => {
                const hasImageOperations = state.imageOperationsCount > 0;
                const hadImageOperations = prevState.imageOperationsCount > 0;
                if (hasImageOperations && !hadImageOperations) {
                    console.log('on Start');
                    onStart();
                } else if (!hasImageOperations && hadImageOperations) {
                    onComplete();
                }
            }),
        [storeApi, onStart, onComplete],
    );

    useEffect(
        () =>
            storeApi.subscribe(state => {
                if (
                    state.isProcessing ||
                    state.pendingIrcodes.length ||
                    state.availableIrcodes.length ||
                    state.addingIrcodes.length
                ) {
                    enableUnloadConfirm();
                } else {
                    disableUnloadConfirm();
                }
            }),
        [storeApi],
    );

    useEffect(
        () =>
            storeApi.subscribe((state, prevState) => {
                const { files } = state;
                const { files: prevFiles } = prevState;
                if (files.length === 0 || files === prevFiles) {
                    return;
                }
                void processFiles();
            }),
        [storeApi, processFiles],
    );

    const registerSelectedImages = useCallback(async () => {
        await registerImages(() => {
            if (fileDropRef.current) {
                fileDropRef.current.scrollIntoView({ block: 'start' });
            }
        }, onAdd);
    }, [registerImages]);

    return (
        <Stack direction="column" spacing={4}>
            <UploaderFileDropArea fileDropRef={fileDropRef} />

            <UploaderProgressBar />

            <Pending key="pending" />

            <Available key="available" registerSelectedImages={registerSelectedImages} />

            <Adding key="adding" />

            <Added key="added" />

            <Unavailable key="unavailable" />

            <UploaderCancelButton />
        </Stack>
    );
});

export default BulkUploader;
