import { Button, Dialog, Stack, Typography } from '@mui/material';
import { create } from 'zustand';
import { createStoreSelectors } from 'src/util/zustand';
import { RequiredPropertyNames } from 'src/types/util';

export interface ConfirmOptions {
    title: string;
    message: string;
    yes?: string;
    no?: string;
    destructive?: boolean;
    onConfirmRedirect?: {
        url: string;
        newTab?: boolean;
    };
}

export type ConfirmCallback = (options: ConfirmOptions) => Promise<boolean>;

export interface ConfirmStore extends RequiredPropertyNames<ConfirmOptions> {
    promise: { resolve: (value: boolean) => void; reject: (reason?: any) => void } | undefined;
    confirm: ConfirmCallback;
    handleClose: () => void;
    handleConfirm: () => void;
    handleCancel: () => void;
}

const useConfirmStore = createStoreSelectors(
    create<ConfirmStore>()((set, get) => ({
        promise: undefined,
        title: '',
        message: '',
        yes: 'Yes',
        no: 'No',
        destructive: false,
        onConfirmRedirect: undefined,
        confirm: options => {
            return new Promise<boolean>((resolve, reject) => {
                set({ ...options, promise: { resolve, reject } });
            });
        },
        handleClose: () => {
            set({ promise: undefined });
        },
        handleConfirm: () => {
            const { promise, handleClose } = get();
            promise?.resolve(true);
            handleClose();
        },
        handleCancel: () => {
            const { promise, handleClose } = get();
            promise?.resolve(false);
            handleClose();
        },
    })),
);

/** Don't use this component directly. Instead, use the 'confirm' function from the feedback context*/
export const ConfirmDialog = () => {
    const promise = useConfirmStore.use.promise();
    const title = useConfirmStore.use.title();
    const message = useConfirmStore.use.message();
    const yes = useConfirmStore.use.yes();
    const no = useConfirmStore.use.no();
    const destructive = useConfirmStore.use.destructive();
    const onConfirmRedirect = useConfirmStore.use.onConfirmRedirect();
    const handleConfirm = useConfirmStore.use.handleConfirm();
    const handleCancel = useConfirmStore.use.handleCancel();

    return (
        <Dialog
            open={promise !== undefined}
            sx={{
                // p: 4,
                zIndex: Number.MAX_SAFE_INTEGER,
                '& .MuiDialog-paper': {
                    minWidth: '30vw',
                    p: 4,
                    borderRadius: 2,
                },
            }}
        >
            <Stack direction="column" spacing={2}>
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '32px',
                        fontWeight: 600,
                        lineHeight: '40px',
                        letterSpacing: '-0.03em',
                        textAlign: 'left',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    {message}
                </Typography>
                <Button
                    {...(onConfirmRedirect && {
                        href: onConfirmRedirect.url,
                        target: onConfirmRedirect.newTab ? '_blank' : '_self',
                    })}
                    variant="irdbGradient"
                    sx={{
                        height: 56,
                        background: destructive ? '#cc0000' : 'undefined',
                    }}
                    onClick={handleConfirm}
                >
                    {yes}
                </Button>
                <Button
                    variant="irdbGray"
                    sx={{
                        height: 56,
                    }}
                    onClick={handleCancel}
                >
                    {no}
                </Button>
            </Stack>
        </Dialog>
    );
};

const useConfirm = (): ConfirmCallback => {
    return useConfirmStore.use.confirm();
};

export default useConfirm;
