import { Stack, Typography } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { Color } from 'src/Color';
import useAnalytics, { Interval } from 'src/hooks/useAnalytics';
import ResponsiveLineChart from './ResponsiveLineChart';
import useStatistics from 'src/hooks/useStatistics';
import { Metric, TimeframeAndInterval } from '../Analytics';

interface Props {
    campaignID: number;
    metric: Metric;
    timeframeAndInterval?: TimeframeAndInterval;

    // Keen
    // timeframe: Timeframe;
    // interval: Interval;

    // IRDB
    // days: number;
    // unit: string; // wanted to call this interval but it conflicts with the Keen interval
    start?: string;
    end?: string;
}

export default function ViewsHistoryChart({ campaignID, metric, timeframeAndInterval, start, end }: Props) {
    interface CampaignView {
        x: string | number;
        y: number;
    }

    const { query } = useAnalytics();
    const { fetch } = useStatistics();

    const [campaignViews, setCampaignViews] = useState<CampaignView[]>([]);

    const fetchAnalytics = useCallback(() => {
        console.log('[ANALYTICS] fetchAnalytics', campaignID, timeframeAndInterval, start, end, metric);

        // return new Promise<CampaignView[]>((resolve, reject) => {
        //     query(
        //         // Type.CountUnique,
        //         Type.Count,
        //         {
        //             event_collection: Event.ImageViewed,
        //             timeframe,
        //             interval,
        //             filters: [
        //                 {
        //                     property_name: Parameter.CampaignID,
        //                     operator: 'eq',
        //                     property_value: campaignID,
        //                 },
        //             ],
        //             // group_by: ['campaign_id']
        //         },
        //     )
        //         .then(results => {
        //             console.log('results', results);
        //             resolve(
        //                 results.map((result, index: number) => {
        //                     return {
        //                         // id: String(index),
        //                         // data: result.value,
        //                         // id: click..category,
        //                         // label: result.category,
        //                         // value: result.result,
        //                         // color: randomColor(),
        //                         // ...click,
        //                         // @ts-ignore
        //                         x: result.timeframe.start ?? index,
        //                         y: result.value as number,
        //                     };
        //                 }),
        //                 // .sort((a: Click, b: Click) => b.result - a.result)
        //             );
        //         })
        //         .catch((error: any) => {
        //             console.error('error', error);
        //             reject(error);
        //         })
        //         .finally(() => {});
        // });

        return new Promise<CampaignView[]>((resolve, reject) => {
            fetch<CampaignView[]>({
                campaignID,
                stat: metric,
                timeframe: timeframeAndInterval?.days,
                interval: timeframeAndInterval?.unit ?? 'days',
                start,
                end,
                aggregated: true,
            })
                .then(results => {
                    console.log('results', results);
                    resolve(
                        results.map((result, index: number) => {
                            return {
                                // id: String(index),
                                // data: result.value,
                                // id: click..category,
                                // label: result.category,
                                // value: result.result,
                                // color: randomColor(),
                                // ...click,
                                // @ts-ignore
                                x: result.timeframe.start ?? index,
                                y: result.value as number,
                            };
                        }),
                        // .sort((a: Click, b: Click) => b.result - a.result)
                    );
                })
                .catch((error: any) => {
                    console.error('error', error);
                    reject(error);
                })
                .finally(() => {});
        });
    }, [campaignID, timeframeAndInterval, start, end, metric]);

    useEffect(() => {
        if (!campaignID) {
            return;
        }

        fetchAnalytics()
            .then(campaignViews => {
                setCampaignViews(campaignViews);
            })
            .catch((error: any) => {
                console.error('error', error);
            });
    }, [fetchAnalytics, campaignID]);

    return (
        <Stack direction="column">
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '2em',
                    fontWeight: 700,
                    lineHeight: '1.25em',
                    letterSpacing: '-0.4px',
                    textAlign: 'left',
                }}
            >
                View History
            </Typography>
            <ResponsiveLineChart
                data={[
                    {
                        id: campaignID,
                        color: Color.Purple,
                        data: campaignViews,
                    },
                ]}
                interval={timeframeAndInterval?.interval ?? Interval.Daily}
            />
        </Stack>
    );
}
