import { Box, SxProps, Theme } from '@mui/material';
import { useContext } from 'react';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { Color } from '../../Color';

interface Props {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
    style?: React.CSSProperties;
    onClick: () => void;
}

const GrayBoxButton = ({ children, sx, style, onClick }: Props) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    return (
        <Box
            sx={{
                p: 4,
                // cornerRadius: 2,

                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,

                // borderColor: '#96969A',
                border: '1px solid #96969A',
                borderRadius: '4px',
                // boxShadow: 3,
                ...sx,
            }}
            style={style}
            // className="bordered"
            onClick={onClick}
        >
            {children}
        </Box>
    );
};

export default GrayBoxButton;
