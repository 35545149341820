import { ReactNode } from 'react';
import {
    DragDropContext,
    DragDropContextProps,
    Draggable,
    DraggableChildrenFn,
    Droppable,
    DroppableProps,
} from 'react-beautiful-dnd';

export interface DragDropListProps<TItem> extends Omit<DragDropContextProps, 'children'> {
    items: TItem[];
    listRender: (children: ReactNode[], ...rest: Parameters<DroppableProps['children']>) => JSX.Element;
    itemRender: (mapProps: [TItem, number, TItem[]], ...rest: Parameters<DraggableChildrenFn>) => JSX.Element;
    droppableProps?: Omit<DroppableProps, 'children'>;
    draggableProps?: Omit<Draggable['props'], 'children'>;
    disabled?: boolean;
}

function DragDropList<TItem>({
    items,
    listRender,
    itemRender,
    droppableProps,
    draggableProps,
    disabled,
    ...rest
}: DragDropListProps<TItem>) {
    return (
        <DragDropContext {...rest}>
            <Droppable
                droppableId="droppable"
                {...droppableProps}
                isDropDisabled={disabled ?? droppableProps?.isDropDisabled}
            >
                {(...props) => {
                    const children = items.map((item, index, items) => (
                        <Draggable
                            index={index}
                            key={`item-${index}`}
                            draggableId={`item-${index}`}
                            {...draggableProps}
                            isDragDisabled={disabled ?? draggableProps?.isDragDisabled}
                        >
                            {(...props) => itemRender([item, index, items], ...props)}
                        </Draggable>
                    ));
                    return listRender(children, ...props);
                }}
            </Droppable>
        </DragDropContext>
    );
}

export default DragDropList;
