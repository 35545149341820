import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    imageID: string;
}

export default function IrcodeContextMenu({ anchorEl, open, handleClose, imageID }: Props) {
    const navigate = useNavigate();
    const navigateTo = (path: string) => {
        handleClose();
        navigate(path);
    };

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: 2,
                    '& .MuiMenuItem-root': {
                        fontFamily: 'Nunito Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '14px',
                        letterSpacing: '0.15em',
                        textTransform: 'uppercase',
                        justifyContent: 'space-between',
                    },
                },
            }}
        >
            <MenuItem onClick={() => navigateTo(`/dashboard/myircodes/${imageID}#information`)}>
                Edit
                <i className="fa-light fa-pen" style={{ paddingLeft: 8 }}></i>
            </MenuItem>
        </Menu>
    );
}
