import GeneralErrorMessages from '../../constants/generalErrorMessages.json';
import { AbortError } from './AbortError';

export interface AbortablePromiseConstructor<T> {
    new (
        executor: (
            resolve: (value: PromiseLike<T> | T) => void,
            reject: (reason?: any) => void,
            abortController: AbortController,
        ) => void,
        abortController?: AbortController,
    ): AbortablePromise<T>;
}

export class AbortablePromise<T> extends Promise<T> {
    private _abortController: AbortController;

    public get abortController(): AbortController {
        return this._abortController;
    }

    public get aborted(): boolean {
        return this._abortController.signal.aborted;
    }

    public get abortReason(): string | undefined {
        return this._abortController.signal.reason;
    }

    constructor(
        executor: (
            resolve: (value: PromiseLike<T> | T) => void,
            reject: (reason?: any) => void,
            abortController: AbortController,
        ) => void,
        abortController = new AbortController(),
    ) {
        super(async (resolve, reject) => {
            const abort = () => {
                reject(new AbortError());
            };
            if (abortController.signal.aborted) {
                abort();
                return;
            }
            abortController.signal.addEventListener('abort', abort);
            await executor(resolve, reject, abortController);
            abortController.signal.removeEventListener('abort', abort);
        });
        this._abortController = abortController;
    }

    public static from<T>(promise: Promise<T>, abortController?: AbortController): AbortablePromise<T> {
        // If promise is already an AbortablePromise, return it directly.
        if (promise instanceof AbortablePromise) {
            return promise;
        }
        return new AbortablePromise<T>((resolve, reject) => {
            promise.then(resolve).catch(reject);
        }, abortController);
    }

    public then<TResult1 = T, TResult2 = never>(
        onFulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
    ): AbortablePromise<TResult1 | TResult2> {
        const newPromise = super.then(onFulfilled, onRejected) as AbortablePromise<TResult1 | TResult2>;
        // Use the same AbortController in the whole chain.
        newPromise._abortController = this._abortController;
        return newPromise;
    }

    public catch<TResult = never>(
        onRejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null,
    ): AbortablePromise<T | TResult> {
        const newPromise = super.catch(onRejected) as AbortablePromise<T | TResult>;
        // Use the same AbortController in the whole chain.
        newPromise._abortController = this._abortController;
        return newPromise;
    }

    public finally(onFinally?: (() => void) | undefined | null): AbortablePromise<T> {
        const newPromise = super.finally(onFinally) as AbortablePromise<T>;
        // Use the same AbortController in the whole chain.
        newPromise._abortController = this._abortController;
        return newPromise;
    }

    public abort(reason: string = GeneralErrorMessages.PromiseAborted): void {
        this._abortController.abort(reason);
    }

    public static all<T>(
        values: Iterable<T | PromiseLike<T>>,
        abortController?: AbortController,
    ): AbortablePromise<Awaited<T>[]> {
        return AbortablePromise.from(Promise.all(values), abortController);
    }

    public static allSettled<T>(
        values: Iterable<T | PromiseLike<T>>,
        abortController?: AbortController,
    ): AbortablePromise<PromiseSettledResult<Awaited<T>>[]> {
        return AbortablePromise.from(Promise.allSettled<T>(values), abortController);
    }

    public static any<T>(
        values: Iterable<T | PromiseLike<T>>,
        abortController?: AbortController,
    ): AbortablePromise<Awaited<T>> {
        return AbortablePromise.from(Promise.any<T>(values), abortController);
    }

    public static race<T>(
        values: Iterable<T | PromiseLike<T>>,
        abortController?: AbortController,
    ): AbortablePromise<Awaited<T>> {
        return AbortablePromise.from(Promise.race<T>(values), abortController);
    }

    public static resolve(abortController?: AbortController): AbortablePromise<void>;
    public static resolve<T>(value: T, abortController?: AbortController): AbortablePromise<Awaited<T>>;
    public static resolve<T>(
        value: T | PromiseLike<T>,
        abortController?: AbortController,
    ): AbortablePromise<Awaited<T>>;
    public static resolve(value?: any, abortController?: AbortController): AbortablePromise<any> {
        return AbortablePromise.from(Promise.resolve(value), abortController);
    }

    public static reject<T = never>(reason?: any): AbortablePromise<T> {
        return AbortablePromise.from(Promise.reject<T>(reason));
    }
}
