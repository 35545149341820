import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

interface RowData {
    imageUrl: string;
    timeDate: string;
    title: string;
    username: string;
    name: string;
    account: string;
}

export default function ScansViews() {
    const rows: RowData[] = [
        {
            imageUrl:
                'https://storage.googleapis.com/ircode-1a662.appspot.com/irdbAdd/dcf18e70-a144-409c-914e-9f5e823643c4.jpeg',
            timeDate: '2024-04-09 10:00',
            title: 'Sample Title 1',
            username: 'user1',
            name: 'John Doe',
            account: 'Account1',
        },
        {
            imageUrl: 'https://via.placeholder.com/40',
            timeDate: '2024-04-10 11:30',
            title: 'Sample Title 2',
            username: 'user2',
            name: 'Jane Doe',
            account: 'Account2',
        },
    ];

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Time/Date</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Account</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(row => (
                    <TableRow key={row.username}>
                        <TableCell>
                            <img src={row.imageUrl} alt="Row Image" style={{ height: '100px' }} />
                        </TableCell>
                        <TableCell>{row.timeDate}</TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.account}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
