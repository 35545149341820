import { useEffect, useState } from 'react';
import {
    containsAtLeastOneDigitRegex,
    containsAtLeastOneLowercaseLetterRegex,
    containsAtLeastOneSpecialCharacterRegex,
    containsAtLeastOneUppercaseLetterRegex,
} from '../util/string';

export interface PasswordValidState {
    length: boolean;
    lowerCaseLetter: boolean;
    upperCaseLetter: boolean;
    specialCharacter: boolean;
    number: boolean;
}

export const getPasswordValidState = (password: string): PasswordValidState => ({
    length: password.length >= MIN_PASSWORD_LENGTH,
    lowerCaseLetter: containsAtLeastOneLowercaseLetterRegex.test(password),
    upperCaseLetter: containsAtLeastOneUppercaseLetterRegex.test(password),
    specialCharacter: containsAtLeastOneSpecialCharacterRegex.test(password),
    number: containsAtLeastOneDigitRegex.test(password),
});

const usePasswordValidation = (password: string): PasswordValidState => {
    const [passwordValidState, setPasswordValidState] = useState<PasswordValidState>({
        length: false,
        lowerCaseLetter: false,
        upperCaseLetter: false,
        specialCharacter: false,
        number: false,
    });

    useEffect(() => {
        setPasswordValidState(getPasswordValidState(password));
    }, [password]);

    return passwordValidState;
};

export default usePasswordValidation;
export const MIN_PASSWORD_LENGTH = 8;
