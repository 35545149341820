import { FormEvent } from 'react';
import { Button, TextField, FormControlLabel, Checkbox, Grid, Box, Container, Select, MenuItem } from '@mui/material';
import UserContext, { SignInResult, TUser } from '../../contexts/UserContext';
import { useContext, useState } from 'react';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { SignInForm } from './SignIn';

const PHONE_NUMBER_PATTERN = '[-\\d\\s\\.\\(\\)]+?';

// const countryNames = require("../../data/names.json");
// const countryPhones = require("../../data/phone.json");
// const allCountries = Object
//     .keys(countryNames)
//     .map((key) => new Country(key, countryNames[key], countryPhones[key]))
//     .sort((a, b) => a.name.localeCompare(b.name));

export interface Country {
    code: string;
    name: string;
    phone: string;
}

const countries = require('../../data/countries.json');
const sortedCountries = countries.sort((a: Country, b: Country) => a.name.localeCompare(b.name));
const defaultCountry = countries.find((country: Country) => country.code === 'US') || {
    code: 'US',
    name: 'United States',
    phone: '1',
};

// const naCountries = Object
//     .keys(countryNames)
//     .filter((key) => ['US', 'CA', 'MX'].includes(key))
//     .map((key) => new Country(key, countryNames[key], countryPhones[key]))
//     .sort((a, b) => a.name.localeCompare(b.name));
// const Separator = 0;

// https://dev.to/jorik/country-code-to-flag-emoji-a21
const getFlagEmoji = (country: Country) => {
    const codePoints = country.code
        .toUpperCase()
        .split('')
        .map((char: string) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
};

export const longLabelMap = (country: Country) =>
    country.code === '' ? '' : `${getFlagEmoji(country)} +${country.phone} ${country.name}`;
// export const shortLabelMap = (country: Country) => (country.code === '') ? '' : `${getFlagEmoji(country)} +${country.phone}`;
export const shortLabelMap = (country: Country) => (country.code === '' ? '' : `${getFlagEmoji(country)}`);

interface Props {
    toggleSignInForm: () => void;
    setErrorMessage: (message: string) => void;
    setSignInForm: (form: SignInForm) => void;
    onComplete: (status: SignInResult) => void;
    theme?: any;
}

export default function SignInWithPhoneNumber({
    toggleSignInForm,
    setErrorMessage,
    setSignInForm,
    onComplete,
    theme,
}: Props) {
    const { setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { signInWithPhoneNumber } = useContext(UserContext) as TUser;

    const [openCountryCodeSelect, setOpenCountryCodeSelect] = useState(false);
    const [country, setCountry] = useState<Country>(defaultCountry);

    const submitPhoneNumber = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const phoneNumber = data.get('phoneNumber') as string;

        try {
            setShowLoading(true);

            const result = await signInWithPhoneNumber(`+${country.phone}${phoneNumber}`);
            console.log('result', result);

            setSignInForm(SignInForm.VerificationCode);

            // TODO: Resend
            // setSignInForm(SignInForm.PhoneNumber);

            // switch (result.status) {
            //     case SignInStatus.Success:
            //         onComplete(result);
            //         break;
            //     case SignInStatus.Error:
            //         setErrorMessage(result.error?.message);
            //         break;
            //     default:
            //         break;
            // }
        } catch (error) {
            setErrorMessage(error as string);
        } finally {
            setShowLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <Button variant="irdbText" onClick={toggleSignInForm}>
                Use Email Instead
            </Button>
            <Box component="form" onSubmit={submitPhoneNumber} noValidate>
                <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Select
                            sx={{ backgroundColor: '#26252F', width: '100%' }}
                            open={openCountryCodeSelect}
                            onClose={() => setOpenCountryCodeSelect(false)}
                            onOpen={() => setOpenCountryCodeSelect(true)}
                            value={country.code}
                            onChange={event => {
                                const newCountry = sortedCountries.find((c: Country) => c.code === event.target.value);
                                setCountry(newCountry);
                            }}
                        >
                            {sortedCountries.map((c: Country) => (
                                <MenuItem value={c.code}>
                                    {openCountryCodeSelect ? longLabelMap(c) : shortLabelMap(c)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            margin="normal"
                            required
                            id="phoneNumber"
                            label="Phone Number"
                            name="phoneNumber"
                            autoComplete="phoneNumber"
                            autoFocus
                        />
                    </Grid>
                </Grid>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                <Button type="submit" variant="irdbGradient">
                    Continue
                </Button>
            </Box>
        </Container>
    );
}
