import { ComponentType, createContext, ReactNode, useContext, useRef } from 'react';
import MediaContext, { ImageOperation, RegistrationStatus } from '../MediaContext';
import { StoreApi } from 'zustand';
import MetaContext from '../MetaContext';
import { createVanillaStoreSelectors } from '../../util/zustand';
import FeedbackContext from '../FeedbackContext';
import { IRCodes } from '../../stores/BulkUploader/slices/imageOperationsSlice';
import { BulkUploaderStore, createBulkUploaderStore } from '../../stores/BulkUploader/store';
import useMetaErrors from '../../hooks/metaErrors/useMetaErrors';
import { getAtIndexNested } from '../../util/array';

export const BulkUploaderContext = createContext(
    {} as ReturnType<typeof createVanillaStoreSelectors<StoreApi<BulkUploaderStore>>>,
);

const statusMap = new Map<RegistrationStatus, keyof IRCodes>([
    [RegistrationStatus.Pending, 'pendingIrcodes'],
    [RegistrationStatus.Available, 'availableIrcodes'],
    [RegistrationStatus.Unavailable, 'unavailableIrcodes'],
    [RegistrationStatus.Adding, 'addingIrcodes'],
    [RegistrationStatus.Added, 'addedIrcodes'],
]);

const statuses = Array.from(statusMap.keys());
const statusProperties = Array.from(statusMap.values());

const getCategory = (status: RegistrationStatus) => statusMap.get(status)!;

export const getAllImageOperations = (state: BulkUploaderStore) =>
    statuses.reduce((prev, status) => prev.concat(state[getCategory(status)]), [] as ImageOperation<any>[]);

export const getAllImageOperationsCount = (state: BulkUploaderStore) =>
    statuses.reduce((prev, status) => prev + state[getCategory(status)].length, 0);

export const getImageOperationAtIndex = (state: BulkUploaderStore, index: number) => {
    return getAtIndexNested(
        statusProperties.map(v => state[v]),
        index,
    );
};

export const BulkUploaderProvider = ({ children }: { children: ReactNode }) => {
    const Feedback = useContext(FeedbackContext)!;
    const Meta = useContext(MetaContext)!;
    const Media = useContext(MediaContext)!;
    const setMetaErrors = useMetaErrors.use.setErrors();
    const store = useRef(
        createVanillaStoreSelectors(createBulkUploaderStore({ Meta, Media, Feedback, setMetaErrors })),
    );
    return <BulkUploaderContext.Provider value={store.current}>{children}</BulkUploaderContext.Provider>;
};

export const WithBulkUploaderProvider = <T extends object>(Component: ComponentType<T>) => {
    function Render(props: T) {
        return (
            <BulkUploaderProvider>
                <Component {...props} />
            </BulkUploaderProvider>
        );
    }
    Render.displayName = `WithBulkUploaderProvider(${Component.displayName || Component.name})`;
    return Render;
};
