import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Stack, Dialog, Typography, Avatar, Button } from '@mui/material';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import EnterpriseContext, { Campaign, Permission, TEnterprise } from '../../../contexts/EnterpriseContext';
import { IconButton } from 'src/components/general/IconButton';
import TextField from '../../general/TextField';
import { Select } from 'src/components/general/Select';
import { PagedResults } from 'src/contexts/MediaContext';

const permissions = [
    {
        value: Permission.None,
        label: 'None',
    },
    {
        value: Permission.User,
        label: 'User',
    },
    {
        value: Permission.SuperAdmin,
        label: 'Super-Admin',
    },
];

type ManageMembersDialogProps = {
    open: boolean;
    onClose: () => void;
    campaign: Campaign;
};

const ManageMembersDialog = ({ open, onClose, campaign }: ManageMembersDialogProps) => {
    const { notify, setShowLoading, confirm } = useContext(FeedbackContext) as TFeedback;
    const {
        invite,
        updatePermission,
        deletePermission,
        users: fetchUsers,
    } = useContext(EnterpriseContext) as TEnterprise;
    const [invitationEmail, setInvitationEmail] = useState<string>('');
    const [invitationPermission, setInvitationPermission] = useState<Permission>(permissions[0].value);
    const [users, setUsers] = useState<PagedResults<any[]> | null>(null);

    const fetchCampaignUsersCallback = useCallback(
        (id: number) => {
            fetchUsers(id)
                .then(results => {
                    setUsers(results);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        [fetchUsers],
    );

    useEffect(() => {
        if (open) {
            fetchCampaignUsersCallback(campaign?.campaignID);
        }
    }, [open, fetchCampaignUsersCallback]);

    useEffect(() => {
        if (!open) {
            setInvitationEmail('');
            setInvitationPermission(permissions[0].value);
        }
    }, [open]);

    const handleInvite = async () => {
        if (!campaign) return;

        try {
            setShowLoading(true);
            await invite(campaign.campaignID, invitationEmail, invitationPermission);
            fetchCampaignUsersCallback(campaign.campaignID);
            setInvitationEmail('');
            setShowLoading(false);
        } catch (error) {
            setShowLoading(false);
            notify('Error', String(error));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: { xs: '100vw', md: '60vw' },
                    minHeight: '50vh',
                    maxHeight: '80vh',
                    p: { xs: 0, md: 1 },
                    borderRadius: 2,
                },
            }}
        >
            <Stack direction="column">
                <IconButton icon="fa-xmark" sx={{ backgroundColor: 'transparent' }} onClick={onClose} />
                <Stack direction="column" spacing={2} sx={{ p: 4 }}>
                    <Typography
                        sx={{
                            fontFamily: 'Nocturne Serif',
                            fontSize: '24px',
                            fontWeight: 700,
                            lineHeight: '24px',
                            letterSpacing: '-0.4px',
                            textAlign: 'left',
                        }}
                    >
                        Manage Members in {campaign?.campaignName}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '18px',
                            letterSpacing: '0.4px',
                            textAlign: 'left',
                        }}
                    >
                        When you give people permissions on your campaign, they can view, edit and take other actions.
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <TextField
                            label="Email Address"
                            value={invitationEmail}
                            onChange={value => setInvitationEmail(value)}
                            sx={{ pb: 2 }}
                        />
                        <Select
                            options={permissions}
                            value={invitationPermission}
                            onChange={value => {
                                const permission = value as Permission;
                                setInvitationPermission(permission);
                            }}
                            sx={{ flexGrow: 1 }}
                        />
                        <Button variant="irdbGradient" onClick={handleInvite}>
                            Invite
                            <i className="fa-regular fa-arrow-right"></i>
                        </Button>
                    </Stack>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '16px',
                            letterSpacing: '-0.4px',
                            textAlign: 'left',
                        }}
                    >
                        Members ({users?.Count ?? 0})
                    </Typography>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                        }}
                    >
                        {(users?.Results ?? []).map(user => (
                            <Stack
                                key={user.amID}
                                direction="row"
                                spacing={2}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar
                                    src={user.profileUrl}
                                    sx={{
                                        width: 26,
                                        height: 26,
                                        borderRadius: 13,
                                    }}
                                />
                                <Stack
                                    direction="column"
                                    sx={{
                                        display: 'flex',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Typography>{user.userName}</Typography>
                                    <Typography>{user.email}</Typography>
                                </Stack>
                                <Select
                                    options={permissions}
                                    value={user.permission}
                                    onChange={async value => {
                                        setShowLoading(true);
                                        await updatePermission(
                                            campaign?.campaignID,
                                            user.userID,
                                            user.controllingUserID,
                                            value as Permission,
                                        );
                                        await fetchCampaignUsersCallback(campaign?.campaignID);
                                        setShowLoading(false);
                                    }}
                                    sx={{
                                        width: 200,
                                    }}
                                />
                                <IconButton
                                    icon="fa-xmark"
                                    onClick={async () => {
                                        if (
                                            await confirm({
                                                title: 'Remove Member',
                                                message: 'Are you sure you want to remove this member?',
                                                yes: 'Remove',
                                                no: 'Cancel',
                                                destructive: true,
                                            })
                                        ) {
                                            setShowLoading(true);
                                            await deletePermission(
                                                user.amID,
                                                campaign.campaignID,
                                                user.userID,
                                                user.controllingUserID,
                                            );
                                            await fetchCampaignUsersCallback(campaign?.campaignID);
                                            setShowLoading(false);
                                        }
                                    }}
                                />
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Dialog>
    );
};

export default ManageMembersDialog;
