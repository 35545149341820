import { ImageOperation } from 'src/contexts/MediaContext';
import { MetaContent, MetaType } from 'src/types/MetaTypes';
import { BulkUploaderStore } from '../store';
import { copyImageOperationMetaData } from '../../../util/imageOperations/copyMetaData';
import { replaceMetaContent } from '../../../contexts/MetaContext';
import { TFeedback } from '../../../contexts/FeedbackContext';
import { StateCreatorWithDependencies } from '../../../util/zustand';

export interface EventHandlersSlice {
    onDrop(files: File[]): void;
    onCopy(imageOperation: ImageOperation<any>, metaType?: MetaType): void;
    onCopyAvailableMetaFieldData(metaType: MetaType, metaContent?: MetaContent): void;
}

export interface EventHandlersSliceDependencies {
    Feedback: TFeedback;
}

const createEventHandlersSlice: StateCreatorWithDependencies<
    BulkUploaderStore,
    [],
    [],
    EventHandlersSlice,
    EventHandlersSliceDependencies
> = (set, get, store, { Feedback }) => ({
    onDrop: (files: File[]) => {
        // TODO: Pull from user records
        const maxSimultaneousUploads = 10;

        // TODO: Maybe block further uploads until this is done?
        // TODO: Maybe only check for new files here...

        // TODO: Check if file is already in the system
        set({ files });
    },
    onCopy(imageOperation: ImageOperation<any>, metaType?: MetaType) {
        set(state => ({
            availableIrcodes: state.availableIrcodes.map(i => copyImageOperationMetaData(imageOperation, i, metaType)),
        }));
    },
    async onCopyAvailableMetaFieldData(metaType: MetaType, metaContent?: MetaContent) {
        const { confirm } = Feedback;
        if (
            await confirm({
                title: 'Confirm Copy',
                message: 'Copy this field to other uploads?',
                yes: 'Yes',
                no: 'No',
            })
        ) {
            set(state => ({
                availableIrcodes: state.availableIrcodes.map(imageOperation => ({
                    ...imageOperation,
                    bulkOperation: {
                        ...imageOperation.bulkOperation!,
                        meta: replaceMetaContent(imageOperation.bulkOperation!.meta, metaType, metaContent),
                    },
                })),
            }));
        }
    },
});

export default createEventHandlersSlice;
