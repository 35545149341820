import { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface Props {
    initialComponent: (pushToStack: (component: JSX.Element) => void, popFromStack: () => void) => JSX.Element;
}

export default function StackNav({ initialComponent }: Props) {
    const pushToStack = (component: any) => {
        setStack(prev => [...prev, component]);
    };

    const popFromStack = () => {
        setStack(prev => {
            const newStack = [...prev];
            newStack.pop();
            return newStack;
        });
    };

    const [stack, setStack] = useState([initialComponent(pushToStack, popFromStack)]);

    return (
        <TransitionGroup className="stack">
            {stack.map((component, index) => {
                return (
                    <CSSTransition key={index} timeout={300} classNames="slide">
                        {component}
                    </CSSTransition>
                );
            })}
        </TransitionGroup>
    );
}
