import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { useContext } from 'react';
import UserContext, { TUser } from '../../../contexts/UserContext';

interface Props {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
}

export default function ProfileContextMenu({ anchorEl, open, handleClose }: Props) {
    const navigate = useNavigate();
    const { darkMode, setDarkMode } = useContext(ThemeContext) as TTheme;
    const { signOut, user, userIsAnonymous } = useContext(UserContext) as TUser;

    const navigateTo = (path: string) => {
        handleClose();
        navigate(path);
    };

    const handleSignOut = async () => {
        try {
            await signOut();
            // TODO: Try replace = true
            // navigateTo('/dashboard', true);
            // Using navigater caused the page to sit at a spinner. This is a workaround.
            document.location.href = '/dashboard';
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: 2,
                    '& .MuiMenuItem-root': {
                        fontFamily: 'Nunito Sans',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '14px',
                        letterSpacing: '0.15em',
                        textTransform: 'uppercase',
                        justifyContent: 'space-between',
                    },
                },
            }}
        >
            <MenuItem onClick={() => navigateTo('/dashboard/settings#account')}>
                Edit Profile
                <i className="fa-light fa-pen" style={{ paddingLeft: 8 }}></i>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setDarkMode(!darkMode);
                    handleClose();
                }}
            >
                {darkMode ? 'Light Mode' : 'Dark Mode'}
                <i className={`fa-light fa-${darkMode ? 'sun' : 'moon'}`} style={{ paddingLeft: 8 }}></i>
            </MenuItem>
            {user ?
                userIsAnonymous ?
                    <MenuItem
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Sign In <i className="fa-light fa-right-to-bracket" style={{ paddingLeft: 5 }}></i>
                    </MenuItem>
                :   <MenuItem onClick={handleSignOut}>
                        Sign Out <i className="fa-light fa-arrow-right-from-bracket" style={{ paddingLeft: 5 }}></i>
                    </MenuItem>

            :   <MenuItem
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Sign In <i className="fa-light fa-right-to-bracket" style={{ paddingLeft: 5 }}></i>
                </MenuItem>
            }
        </Menu>
    );
}
