import { useNavigate } from 'react-router-dom';

export const useNavigateAndScrollToTop = (): ((url: string) => void) => {
    const navigate = useNavigate();

    const navigateAndScrollToTop = (url: string) => {
        navigate(url);
        window.scrollTo(0, 0);
    };

    return navigateAndScrollToTop;
};
