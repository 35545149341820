import { useContext, useState } from 'react';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import UserContext, { TUser } from '../../../contexts/UserContext';
import ProfileContextMenu from '../tools/ProfileContextMenu';
import UserAvatar from 'src/components/general/UserAvatar';

interface Props {
    title: string | (() => JSX.Element);
}

export default function PageHeader({ title }: Props) {
    const { user } = useContext(UserContext) as TUser;

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const useTitle =
        typeof title === 'string' ?
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '40px',
                    fontWeight: 600,
                    lineHeight: '56px',
                    letterSpacing: '-0.03em',
                }}
            >
                {title}
            </Typography>
        :   title();

    return (
        <Stack
            direction="row"
            spacing={4}
            sx={{
                justifyContent: 'space-between',
            }}
        >
            {useTitle}
            <Stack
                direction="row"
                spacing={2}
                display={{ xs: 'none', sm: 'flex' }}
                sx={{
                    alignItems: 'flex-start',
                }}
            >
                <Stack direction="column">
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                        }}
                    >
                        {user?.userName || `IRCODE USER ${user?.userID ?? ''}`}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '10px',
                            fontWeight: 400,
                            lineHeight: '14px',
                            letterSpacing: '0.15em',
                            textTransform: 'uppercase',
                        }}
                    >
                        {user?.userTypeDisplay}
                    </Typography>
                </Stack>
                <UserAvatar profileUrl={user?.profileUrl} userName={user?.userName} />
                <IconButton
                    sx={{
                        fontSize: '1rem',
                    }}
                    onClick={event => setAnchorEl(event.currentTarget)}
                >
                    <i
                        className="fa-solid fa-ellipsis-vertical"
                        style={{
                            color: theme.palette.primary.main,
                        }}
                    ></i>
                </IconButton>
                <ProfileContextMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    handleClose={() => {
                        setAnchorEl(null);
                    }}
                />
            </Stack>
        </Stack>
    );
}
