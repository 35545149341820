import { Card, Stack, Typography, Box, CircularProgress } from '@mui/material';
import { ImageOperation } from 'src/contexts/MediaContext';
import { memo, useContext } from 'react';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { Color } from 'src/Color';
import { arePropertiesEqual } from 'src/util/objects';
import useBulkUploaderState from '../../../stores/useBulkUploaderState';

const AddingItem = memo(
    function AddingItem({ imageOperation, darkMode }: { imageOperation: ImageOperation<any>; darkMode: boolean }) {
        const imgSrc = imageOperation.cropped?.preview ?? imageOperation.original?.preview;
        const isUploaded = imageOperation.operation.Completed; //  === false;

        return (
            <Stack
                key={imageOperation.id}
                direction="row"
                spacing={0}
                sx={{
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                }}
            >
                <Box
                    component="img"
                    sx={{
                        flexBasis: 140,
                        width: 140,
                        height: 140,
                        borderTopLeftRadius: { xs: '8px', sm: '8px' },
                        borderTopRightRadius: { xs: '8px', sm: 0 },
                        borderBottomLeftRadius: { xs: 0, sm: '8px' },
                        borderBottomRightRadius: { xs: 0, sm: 0 },
                        // objectFit: 'cover',
                        objectFit: 'contain',
                        overflow: 'hidden',
                    }}
                    src={imgSrc}
                    alt=""
                />
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        flexGrow: 1,
                        alignItems: 'center',
                        p: 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Nocturne Serif',
                            fontSize: '24px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            letterSpacing: '-0.03em',
                            textAlign: 'left',
                            wordBreak: 'break-word',
                        }}
                    >
                        {imageOperation.operation.status}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {isUploaded ?
                        <i className="fa-solid fa-check fa-2xl" style={{ marginRight: '16px', color: '#B0DB48' }}></i>
                    :   <CircularProgress />}
                    {/* <IconButton
                                        onClick={ () => {
                                            // removeImageOperation(pendingIrcode);
                                        } }
                                    >
                                        <i className="fa-regular fa-xmark fa-xs"></i>
                                    </IconButton> */}
                </Stack>
            </Stack>
        );
    },
    (prev, next) =>
        prev.darkMode === next.darkMode &&
        arePropertiesEqual(prev.imageOperation, next.imageOperation, 'id', 'original', 'cropped') &&
        arePropertiesEqual(prev.imageOperation.operation, next.imageOperation.operation, 'status', 'Completed'),
);

const Adding = memo(function Adding() {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const imageOperations = useBulkUploaderState().use.addingIrcodes();

    if (imageOperations.length === 0) {
        return null;
    } else {
        return (
            <Card
                elevation={0}
                sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                }}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    // alignItems="flex-start"
                    // justifyContent="flex-start"
                >
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            // alignContent: 'center',
                            // justifyItems: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <i className="fa-solid fa-check fa-2xl" style={{ marginRight: '16px', color: '#B0DB48' }}></i>
                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                fontSize: '24px',
                                fontWeight: 400,
                                lineHeight: '26px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}
                        >
                            Saving
                        </Typography>
                    </Stack>
                    {imageOperations.map(imageOperation => (
                        <AddingItem key={imageOperation.id} darkMode={darkMode} imageOperation={imageOperation} />
                    ))}
                </Stack>
            </Card>
        );
    }
});

export default Adding;
