import { Box, BoxProps } from '@mui/material';
import { useContext } from 'react';
import { cls } from 'react-image-crop';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';

export interface DragDropHandleProps extends BoxProps {
    sm?: boolean;
}

function DragDropHandle({ sx, sm, ...rest }: DragDropHandleProps) {
    const { darkMode, isMobile } = useContext(ThemeContext) as TTheme;
    const isSm = sm || isMobile;
    return (
        <Box
            {...rest}
            sx={{
                m: '0 !important',
                px: 1,
                minWidth: isSm ? 10 : 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'background-color 0.2s',
                ...sx,
                '&:hover': {
                    backgroundColor: darkMode ? 'rgba(1, 1, 1, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                    ...(sx?.['&:hover' as keyof typeof sx] ?? {}),
                },
                '&:active': {
                    backgroundColor: darkMode ? 'rgba(1, 1, 1, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                    ...(sx?.['&:active' as keyof typeof sx] ?? {}),
                },
                '&:focus': {
                    backgroundColor: darkMode ? 'rgba(1, 1, 1, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                    ...(sx?.['&:focus' as keyof typeof sx] ?? {}),
                },
            }}
        >
            <i className={cls('fa-solid fa-grip-vertical', isSm ? 'fa-sm' : 'fa-lg')} />
        </Box>
    );
}

export default DragDropHandle;
