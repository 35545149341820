export const ircodeAccept = {
    'image/*': [],
    'video/*': [],
};

export const adminIrcodeAccept = {
    'text/csv': ['.csv'],
    'application/json': ['.json'],
    ...ircodeAccept,
};

export const imageAccept = {
    'image/*': ['.jpg', '.jpeg', '.png', '.bmp', '.webp'],
};
