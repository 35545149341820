import { Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import TextField from '../../../general/TextField';
import { TTitle } from '../../../../types/MetaTypes';

interface Props extends MetaFieldProps {
    showEditing?: boolean;
    onEdit?: () => void;
}

const Title = ({ metaField, showEditing, onEdit }: Props) => {
    const content = metaField?.metaContent as TTitle | undefined;

    return (
        <Typography
            sx={{
                fontFamily: 'Nocturne Serif',
                fontSize: '2em',
                fontWeight: 600,
                lineHeight: '1.25em',
                letterSpacing: '-0.03em',
                textAlign: 'left',
                wordBreak: 'break-word',
            }}
        >
            {content?.title ?? 'Untitled'}
        </Typography>
    );
};

const TitleForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TTitle | undefined;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack direction="column" spacing={2}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                }}
            >
                <TextField
                    label="Title"
                    value={content?.title ?? ''}
                    onChange={value => handleChange({ name: 'title', value })}
                    onCopy={
                        onCopy ?
                            () => {
                                onCopy(content);
                            }
                        :   undefined
                    }
                    wrapperSx={{
                        flexGrow: 1,
                    }}
                />
            </Stack>
        </Stack>
    );
};

export { Title, TitleForm };
