import { useEffect, useRef } from 'react';

const useSelfUpdatingRef = <T>(value: T) => {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref;
};

export default useSelfUpdatingRef;
