import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const useLinkableTabs = (tabs: string[]): [number, Dispatch<SetStateAction<number>>] => {
    const index = tabs.findIndex(
        tab =>
            tab.toLowerCase() ===
            window.location.hash
                .replace('#', '')
                .replace(/%[0-9A-Fa-f]{2}/g, match => decodeURIComponent(match))
                .toLowerCase(),
    );
    const [tab, setTab] = useState(index === -1 ? 0 : index);

    useEffect(() => {
        window.location.hash = tabs[tab]?.toLowerCase();
    }, [tab]);

    return [tab, setTab];
};

export default useLinkableTabs;
