import { useContext, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';
import UserContext, { TUser, UserNotification } from '../../../contexts/UserContext';
import { NotificationChip, ChipType } from '../../general/NotificationChip';
import { Chip, Type } from '../../general/Chip';
import { Color } from '../../../Color';
import PageHeader from './PageHeader';
import useAuth from '../../../hooks/useAuth';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import Pagination from '../../general/Pagination';
import MediaContext, { TMedia } from '../../../contexts/MediaContext';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import { useSearchParams } from 'react-router-dom';
import useOnScreen from 'src/hooks/useOnScreen';
import formatTime, { formatDate } from 'src/util/date';
import usePageTitle from 'src/hooks/usePageTitle';

export enum NotificationType {
    TransferToInProcess = 'TransferToInProcess',
    TransferFromInProcess = 'TransferFromInProcess',
    TransferAccepted = 'TransferAccepted',
    TransferCancelled = 'TransferCancelled',
    Save = 'Save',
    IrCodeReceived = 'IrCodeReceived',
    IrCodeTransferred = 'IrCodeTransferred',
    IRCodeRemoved = 'IRCodeRemoved',
    Payment = 'Payment',
    Query = 'Query',
    Search = 'Search',
    Share = 'Share',
    View = 'View',
    Similar = 'Similar',
    Sponsored = 'Sponsored',
}

interface NotificationProps {
    notification: UserNotification;
    onVisible: (notificationID: number) => void;
}

function Notification({ notification, onVisible }: NotificationProps) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { confirm, notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { user, requestTwoFactorAuth } = useContext(UserContext) as TUser;
    const {
        // initiateTransfer,
        respondToTransfer,
    } = useContext(MediaContext) as TMedia;

    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const handleTransfer = async (accept: boolean) => {
        try {
            if (accept) {
                // await confirm({
                //     title: 'Are you sure you want to accept this transfer?',
                //     message: 'You will not be able to undo this action.',
                // });
                const code = await requestTwoFactorAuth(
                    'Verify to accept IRCODE',
                    user?.phone ?? '',
                    'Complete transfer',
                );

                setShowLoading(true);
                await respondToTransfer(notification.imageID, true, code);
                setShowLoading(false);
            } else {
                // title: "Are you sure you want to cancel this transfer?",
                // description: "You will not be able to undo this action.",

                if (
                    await confirm({
                        title: 'Are you sure you want to decline this transfer?',
                        message: 'You will not be able to undo this action.',
                    })
                ) {
                    respondToTransfer(notification.imageID, false);
                }
            }
        } catch (error: any) {
            // console.error(error);
            notify('Error', error);
        }
    };

    useEffect(() => {
        if (isVisible) {
            onVisible(notification.notificationID);
        }
    }, [isVisible]);

    const formatNotification = (notification: UserNotification) => {
        const notificationType = notification.notificationType;
        const activityUser = notification.ActivityUser.userName ? notification.ActivityUser.userName : 'Anonymous';
        const ircodeTitle = notification.title ? notification.title : 'Untitled';

        switch (notificationType) {
            case NotificationType.Save:
                return {
                    title: 'IRCODE SAVED',
                    body: `${activityUser} saved your IRCODE '${ircodeTitle}'`,
                };
            case NotificationType.IrCodeReceived:
                return {
                    title: ' IRCODE RECEIVED',
                    body: `${activityUser} received your IRCODE '${ircodeTitle}'`,
                };
            case NotificationType.IrCodeTransferred:
                return {
                    title: 'IRCODE TRANSFERRED',
                    body: `You transferred IRCODE '${ircodeTitle}' to ${activityUser}`,
                };
            case NotificationType.IRCodeRemoved:
                return {
                    title: 'IRCODE REMOVED',
                    body: `IRCODE '${activityUser}' WAS REMOVED`,
                };
            case NotificationType.Payment:
                return {
                    title: 'Payment posted',
                    body: `Your payment posted`,
                };
            case NotificationType.Query:
                return {
                    title: 'IRCODE SCANNED',
                    body: `${activityUser} found '${ircodeTitle}' during a scan`,
                };
            case NotificationType.Search:
                return {
                    title: 'IRCODE SEARCHED',
                    body: `${activityUser} found '${ircodeTitle}' during a search`,
                };
            case NotificationType.Share:
                return {
                    title: 'IRCODE SHARED',
                    body: `${activityUser} shared '${ircodeTitle}'`,
                };
            case NotificationType.View:
                return {
                    title: 'IRCODE VIEWED',
                    body: `${activityUser} viewed '${ircodeTitle}'`,
                };
            case NotificationType.Similar:
                return {
                    title: 'IRCODE SEEN IN SIMILAR DISPLAY',
                    body: `${activityUser} saw your IRCODE '${ircodeTitle}' in a similar display`,
                };
            case NotificationType.Sponsored:
                return {
                    title: `IRCODE WAS SEEN IN SPONSORED DISPLAY`,
                    body: `${activityUser} saw ${ircodeTitle}in a sponsored display`,
                };
            default:
                return { title: 'Error', body: 'Error retrieving notification' };
        }
    };

    const accessoryForNotification = (notification: UserNotification) => {
        const notificationType = notification.notificationType;
        const activityUser = notification.ActivityUser.userName ? notification.ActivityUser.userName : 'Anonymous';
        const ircodeTitle = notification.title ? notification.title : 'Untitled';

        switch (notificationType) {
            case NotificationType.TransferToInProcess:
                return (
                    <>
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            spacing={1}
                            sx={{
                                alignItems: 'center',
                            }}
                        >
                            <Button variant="irdbGradient" onClick={() => handleTransfer(true)}>
                                Accept
                            </Button>
                            <Button variant="irdbRed" onClick={() => handleTransfer(false)}>
                                Decline
                            </Button>
                        </Stack>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Stack
            ref={ref}
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            sx={{
                display: 'flex',
                // flexDirection: 'column',
                padding: '24px 24px 24px 32px',
                borderRadius: '8px',
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
            }}
        >
            <Stack
                direction="column"
                spacing={0.5}
                sx={{
                    flexGrow: 1,
                }}
            >
                <Stack
                    direction="column"
                    spacing={0.5}
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <Stack direction="row" spacing={2}>
                        <Typography
                            variant="webTimeNotification"
                            sx={{
                                textTransform: 'uppercase',
                                color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            }}
                        >
                            {formatNotification(notification).title}
                            <Typography
                                component="span"
                                variant="webTimeNotification"
                                sx={{
                                    display: { xs: 'none', sm: 'inline' },
                                    color: 'inherit',
                                }}
                            >
                                {` • ${formatDate(notification.created)}, ${formatTime(notification.created)}`}
                            </Typography>
                            {!notification.readFlag && (
                                <Chip
                                    label="New"
                                    type={Type.New}
                                    sx={{
                                        marginLeft: 1,
                                    }}
                                />
                            )}
                        </Typography>
                    </Stack>
                    <Typography
                        variant="webTimeNotification"
                        sx={{
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            display: { xs: 'block', sm: 'none' },
                        }}
                    >
                        {`${formatDate(notification.created)}, ${formatTime(notification.created)}`}
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <Typography variant="webProfileName">
                        {notification.displayLine || formatNotification(notification).body}
                    </Typography>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    {notification.ActivityUser.profileUrl ?
                        <NotificationChip
                            label={notification.ActivityUser.userName || 'Anonymous'}
                            avatarUrl={notification.ActivityUser.profileUrl}
                            type={ChipType.ActivityUser}
                            url={
                                notification.ActivityUser.userName === '' ?
                                    undefined
                                :   `/dashboard/profile/${notification.ActivityUser.userID}`
                            }
                        />
                    :   <NotificationChip
                            label={notification.ActivityUser.userName || 'Anonymous'}
                            icon={<Avatar sx={{ width: '1.5rem', height: '1.5rem' }} />}
                            type={ChipType.ActivityUser}
                            url={
                                notification.ActivityUser.userName === '' ?
                                    undefined
                                :   `/dashboard/profile/${notification.ActivityUser.userID}`
                            }
                        />
                    }
                    <NotificationChip
                        label={notification.title || `untitled IRCODE`}
                        avatarUrl={notification.imageUrl}
                        type={ChipType.NotificationImage}
                        url={`/dashboard/ircodes/${notification.imageID}`}
                    />
                </Stack>
            </Stack>
            {accessoryForNotification(notification)}
        </Stack>
    );
}

const emptyNotifications = (darkMode: boolean) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 2,
                padding: '24px 24px 24px 32px',
                height: '120px',
                width: '100%',
                borderRadius: '8px',
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                filter: 'blur(4px)',
            }}
        >
            <Grid item xs={10}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}></Stack>
                </Stack>
            </Grid>
        </Box>
    );
};

export default function Notifications() {
    const [searchParams, setSearchParams] = useSearchParams();
    // console.log('searchParams', searchParams.get('page'));
    // searchParams['']
    // URLSearchParams

    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { user, signOut, fetchUserNotifications, notificationAllRead, notificationRead } = useContext(
        UserContext,
    ) as TUser;

    const [notifications, setNotifications] = useState<UserNotification[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsloading] = useState(false);

    const handleAllRead = () => {
        notificationAllRead(true);
    };

    useAuth(() => {
        // console.log('useAuth!', user, currentPage)

        if (user?.userID === undefined) {
            return;
        }
        setIsloading(true);
        fetchUserNotifications(currentPage - 1)
            .then(response => {
                setNotifications(response.Results.Notifications);
                setPageCount(Number(response.Pages));
            })
            .catch(error => console.error(error))
            .finally(() => setIsloading(false));
    }, [user, currentPage]);

    usePageTitle('Notifications');

    return (
        <Stack direction="column" spacing={2} sx={{ flexGrow: 1, p: 4 }}>
            <PageHeader title="Notifications" />

            {notifications.length > 0 ?
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <Stack direction="row" spacing={2}>
                        <Button variant="webNotificationClearAll" onClick={handleAllRead}>
                            <i className="fa-light fa-check" style={{ marginRight: 8 }}></i> MARK ALL READ
                        </Button>
                    </Stack> */}
                </Box>
            :   <Box>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', filter: 'blur(4px)' }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="webNotificationClearAll" onClick={handleAllRead}>
                                <i className="fa-light fa-check"></i> MARK ALL READ
                            </Button>
                        </Stack>
                    </Box> */}
                    {emptyNotifications(darkMode)}
                    {emptyNotifications(darkMode)}
                    <Box sx={{ position: 'relative', width: '100%', height: 'auto', zIndex: 2 }}>
                        <Stack
                            direction="row"
                            spacing={8}
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                justifyContent: 'center',
                                padding: '20px',
                                borderRadius: '4px',
                            }}
                        >
                            <Stack direction="column" spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography sx={{ fontSize: '40px', lineHeight: '32px', opacity: 0.6 }}>
                                    <i className="fa-light fa-bell"></i>
                                </Typography>
                            </Stack>
                            <Stack direction="column" spacing={2}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Nocturne Serif',
                                        fontSize: '24px',
                                        fontWeight: 400,
                                        lineHeight: '26px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                        opacity: 0.6,
                                    }}
                                >
                                    You have no notifications yet
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '20px',
                                        fontWeight: 400,
                                        lineHeight: '28px',
                                        letterSpacing: '0.01em',
                                        textAlign: 'left',
                                        opacity: 0.4,
                                    }}
                                >
                                    Check back later for updates
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    {emptyNotifications(darkMode)}
                    {emptyNotifications(darkMode)}
                    {emptyNotifications(darkMode)}
                    {emptyNotifications(darkMode)}
                </Box>
            }

            {notifications.map(notification => (
                <Notification
                    key={notification.notificationID}
                    notification={notification}
                    onVisible={async notificationID => {
                        setNotifications(notifications =>
                            notifications.map(notification => {
                                if (notification.notificationID === notificationID && !notification.readFlag) {
                                    notificationRead(true, notificationID).catch(error => console.error(error));
                                    return { ...notification, readFlag: true };
                                }
                                return notification;
                            }),
                        );
                    }}
                />
            ))}

            {pageCount > 1 && (
                <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(event, page) => {
                        // TODO: Add history
                        setCurrentPage(page);
                        setSearchParams({ page: page.toString() });
                        // window.location.hash = `page${page}`;
                        window.scrollTo(0, 0);
                    }}
                />
            )}
        </Stack>
    );
}
