import { useContext, useEffect, useState } from 'react';
import { Stack, Box, Typography, Dialog, Button } from '@mui/material';
import { Image } from '../../../../contexts/MediaContext';
import { nullUndefinedOrEmpty } from '../../../../util/string';
import UserContext, { TUser } from '../../../../contexts/UserContext';

export interface IPendingTransfer {
    // recipient?: string;
    // recipientConfirm?: string;
    // password?: string;
}

interface PendingTransferTopProps {
    image: Image;
    // PendingTransfer: IPendingTransfer | undefined;
    // setPendingTransfer: (PendingTransfer: IPendingTransfer) => void;
}

export function PendingTransferTop({}: PendingTransferTopProps) {
    const { user } = useContext(UserContext) as TUser;

    const [showTwoFactorAuthWarning, setShowTwoFactorAuthWarning] = useState(false);
    const [showEnableTwoFactorDialog, setShowEnableTwoFactorDialog] = useState(false);

    // TODO: Refactor out
    // const [phoneNumber, setPhoneNumber] = useState('');

    // const setAllToIsEditing = (meta: IMetaData, isEditing: boolean): IMetaData => {
    //     const newMeta = structuredClone(meta) as IMetaData;
    //     newMeta.Meta.forEach(m => m.metaIsEditing = isEditing);
    //     return newMeta;
    // }

    useEffect(() => {
        // try {
        //     user!.phone = undefined;
        // } catch (error) {
        // }

        // has a phone number?
        // is email verified?
        // has 2FA?

        if (nullUndefinedOrEmpty(user?.phone)) {
            setShowTwoFactorAuthWarning(true);
        }
    }, []);

    return (
        <>
            <Stack
                direction="column"
                sx={{
                    flexGrow: 1,
                    flexShrink: 0,
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Nocturne Serif',
                        fontSize: '24px',
                        fontWeight: 400,
                        lineHeight: '26px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    IRCODE pending transfer
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                    // component="spa"
                >
                    <Stack direction="column">
                        <Box>
                            <span style={{ width: 160 }}>Transfer to:</span>
                            <span>TBD</span>
                        </Box>
                        <Box>
                            <span style={{ width: 160 }}>Initiated on:</span>
                            <span>TBD</span>
                        </Box>
                        <Box>
                            <span style={{ width: 160 }}>Expires:</span>
                            <span>TBD</span>
                        </Box>
                    </Stack>
                </Typography>
            </Stack>

            <Dialog
                // anchor="bottom"
                open={showTwoFactorAuthWarning}
                // open={true}
                // disableSwipeToOpen={true}
                // ModalProps={{
                //     // This allows the changing of the image to be observed
                //     keepMounted: false,
                // }}
                // onClose={function (event: SyntheticEvent<{}, Event>): void {
                //     setImageOperation(undefined);
                // }}
                // onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                // sx={{
                //     '& .MuiDrawer-paper': {
                //         // transition: 'height 500ms',
                //         // height: availableDrawerHeight,
                //         // maxWidth: '100px',
                //     },
                // }}
                // style={{
                //     zIndex: ZIndex.BottomSheet,
                // }}
            >
                <Stack>
                    Two-factor authentication A phone number is required to transfer this IRCODE. Would you like add one
                    to your profile now?
                    <Button
                        variant="irdbGradient"
                        onClick={async () => {
                            // onSave();
                            // await saveMeta(image.imageID, meta.Meta);
                            // refresh();

                            setShowTwoFactorAuthWarning(false);
                            setShowEnableTwoFactorDialog(true);
                        }}
                    >
                        Add Phone
                    </Button>
                    <Button
                        variant="irdbGray"
                        onClick={async () => {
                            setShowTwoFactorAuthWarning(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Dialog>
            <Dialog
                // anchor="bottom"
                open={false}
                // open={true}
                // disableSwipeToOpen={true}
                // ModalProps={{
                //     // This allows the changing of the image to be observed
                //     keepMounted: false,
                // }}
                // onClose={function (event: SyntheticEvent<{}, Event>): void {
                //     setImageOperation(undefined);
                // }}
                // onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                // sx={{
                //     '& .MuiDrawer-paper': {
                //         // transition: 'height 500ms',
                //         // height: availableDrawerHeight,
                //         // maxWidth: '100px',
                //     },
                // }}
                // style={{
                //     zIndex: ZIndex.BottomSheet,
                // }}
            >
                <Stack>
                    Two-factor authentication Enter your phone number to activate two-factor authentication
                    <Button
                        variant="irdbGradient"
                        onClick={async () => {
                            // onSave();
                            // await saveMeta(image.imageID, meta.Meta);
                            // refresh();
                            // setShowTwoFactorAuthWarning(false);
                        }}
                    >
                        Activate
                    </Button>
                    <Button
                        variant="irdbGray"
                        onClick={async () => {
                            // setShowTwoFactorAuthWarning(false);
                        }}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Dialog>
        </>
    );
}
