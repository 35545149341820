export interface ILink {
    id: string;
    title: string;
    url: string;
    onScanDisplay: boolean;
}

function isILink(maybeLink: any): maybeLink is ILink {
    if (maybeLink === undefined) {
        return false;
    }

    // TODO: Maybe a build time list creation
    if (
        !['title', 'url'].every(key => {
            return maybeLink.hasOwnProperty(key);
        })
    ) {
        return false;
    }

    return true;
}

export type TLink = {
    links: ILink[];
};

export function isTLink(maybeLink: any | undefined): maybeLink is TLink {
    if (maybeLink === undefined) {
        return false;
    }

    if (
        !['links'].every(key => {
            return maybeLink.hasOwnProperty(key);
        })
    ) {
        return false;
    }

    for (const link of maybeLink.links) {
        if (!isILink(link)) {
            return false;
        }
    }

    return true;
}

export function createLink({ id, ...link }: Partial<ILink> = {}, reuseId?: boolean): ILink {
    return {
        id: !reuseId || !id ? crypto.randomUUID() : id,
        title: '',
        url: '',
        onScanDisplay: false,
        ...link,
    };
}
