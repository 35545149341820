import { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga4';

interface UsePageTitleOptions {
    sendPageview?: boolean;
    revertOnUnmount?: boolean;
}

const usePageTitle = (pageTitle: string, options: UsePageTitleOptions = {}) => {
    const updateTitle = useCallback(
        (pageTitle: string) => {
            const fullTitle = `${pageTitle} | IRCODE`;
            document.title = fullTitle;

            if (options.sendPageview) {
                const path = location.pathname + location.search;
                ReactGA.send({
                    hitType: 'pageview',
                    page: path,
                    title: fullTitle,
                });
            }
        },
        [options.sendPageview],
    );

    useEffect(() => {
        if (options.revertOnUnmount) {
            const originalTitle = document.title.split(' | IRCODE')[0];

            return () => {
                if (originalTitle) {
                    updateTitle(originalTitle);
                }
            };
        }
    }, [options.revertOnUnmount, updateTitle]);

    useEffect(() => {
        if (pageTitle) {
            updateTitle(pageTitle);
        }
    }, [pageTitle, updateTitle]);
};

export default usePageTitle;
