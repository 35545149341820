import { MetaErrors } from '../../types/MetaValidation';
import { Selector } from '../../util/zustand';
import { StoreApi } from 'zustand';
import { useCallback, useMemo } from 'react';
import useMetaErrors, { MetaErrorsStore } from './useMetaErrors';
import { MetaType } from '../../types/MetaTypes';
import useMetaErrorsContext from './useMetaErrorsContext';

interface ScopeMetaErrors extends MetaErrors {
    scopeId: string | null | undefined;
    hasErrors: boolean;
}

function useScopeMetaErrors(): ScopeMetaErrors;
function useScopeMetaErrors<R>(selector: Selector<StoreApi<ScopeMetaErrors>, R>): R;
function useScopeMetaErrors<R>(selector?: Selector<StoreApi<ScopeMetaErrors>, R>): unknown {
    const meContext = useMetaErrorsContext();
    const { scopeId } = meContext;

    const resultSelector = useCallback(
        (state: MetaErrorsStore) => {
            if (!scopeId) return undefined;
            const scopeErrors = state.errors[scopeId];
            const result = { ...scopeErrors, scopeId, hasErrors: state.hasErrors.includes(scopeId) };
            return selector ? selector(result) : result;
        },
        [scopeId, selector],
    );
    return useMetaErrors(resultSelector!);
}

export interface ScopeMetaErrorsActions {
    setScopeId: (id: string | null | undefined) => void;
    setErrors: (errors: MetaErrors) => void;
    setError: <T extends MetaType>(metaType: T, error: MetaErrors[T]) => void;
    removeError: <T extends MetaType>(metaType: T) => void;
    clearErrors: () => void;
}

function isValidScopeId(scopeId: string | null | undefined, action: string): scopeId is string {
    if (!scopeId) {
        console.warn(`Attempted to call ${action} before setting a scopeId`);
        return false;
    }
    return true;
}

function useScopeMetaErrorsActions(): ScopeMetaErrorsActions {
    const { scopeId, setScopeId } = useMetaErrorsContext();
    const setErrors = useMetaErrors.use.setErrors();
    const setError = useMetaErrors.use.setError();
    const removeError = useMetaErrors.use.removeError();
    const clearErrors = useMetaErrors.use.clearErrors();
    return useMemo(
        () => ({
            setScopeId,
            setErrors: (errors: MetaErrors) => isValidScopeId(scopeId, 'set meta errors') && setErrors(scopeId, errors),
            setError: <T extends MetaType>(metaType: T, error: MetaErrors[T]) =>
                isValidScopeId(scopeId, 'set meta error') && setError(scopeId, metaType, error),
            removeError: <T extends MetaType>(metaType: T) =>
                isValidScopeId(scopeId, 'remove meta error') && removeError(scopeId, metaType),
            clearErrors: () => isValidScopeId(scopeId, 'clear meta errors') && clearErrors(scopeId),
        }),
        [scopeId, setErrors, setError, removeError, clearErrors, setScopeId],
    );
}

export { useScopeMetaErrorsActions };
export default useScopeMetaErrors;
