import { useContext } from 'react';
import { MetaErrorsContext } from '../../contexts/specialized/MetaErrorsContext';

const useMetaErrorsContext = () => {
    const context = useContext(MetaErrorsContext);

    if (context === undefined) {
        throw new Error('useMetaErrorsContext must be used within a MetaErrorsProvider');
    }

    return context;
};

export default useMetaErrorsContext;
