import { Timespan } from 'src/util/timespan';
import { Color } from 'src/Color';
import { Box, Button, Card, LinearProgress, Stack, Typography } from '@mui/material';
import useBulkUploaderState from 'src/stores/useBulkUploaderState';
import { memo, useCallback, useContext } from 'react';

import { BulkUploaderStore } from 'src/stores/BulkUploader/store';
import ThemeContext from 'src/contexts/ThemeContext';

export interface UploaderProgressBarProps {
    label: string;
    hideCounts?: boolean;
    darkMode: boolean;
    onCancel?: () => void;
    cancelDisabled?: boolean;
}

const getTimespanString = (timespan: Timespan) => {
    let result = '';
    if (timespan.days > 0) {
        result += `${timespan.days}d `;
    }
    if (timespan.hours > 0) {
        result += `${timespan.hours}h `;
    }
    if (timespan.minutes > 0) {
        result += `${timespan.minutes}m `;
    }
    if (timespan.seconds > 0) {
        result += `${timespan.seconds}s `;
    } else {
        result += '1s ';
    }
    return result.trim();
};

const ProgressBar = ({ label, hideCounts, darkMode, onCancel, cancelDisabled }: UploaderProgressBarProps) => {
    const storeApi = useBulkUploaderState();
    const progress = storeApi.use.progress();
    const labelTextSelector = useCallback(
        (state: BulkUploaderStore) =>
            `${label}${hideCounts ? '...' : ` ${state.processedCount}/${state.processingCount}`}`,
        [label, hideCounts],
    );
    const labelText = storeApi.use(labelTextSelector);
    const remainingTime = storeApi.use.timeEstimationTimespan();
    const isEstimatingTime = !remainingTime;

    return (
        <Card
            sx={{
                p: 2,
                borderRadius: 2,
                backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
            }}
            elevation={0}
        >
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                <Stack sx={{ width: '12em' }} direction="column">
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '19px',
                            letterSpacing: '0.15em',
                            textAlign: 'left',
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            textTransform: 'uppercase',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {labelText}
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '13px',
                            fontWeight: 400,
                            lineHeight: '19px',
                            letterSpacing: '0.15em',
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            mt: 0.5,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {isEstimatingTime ?
                            'Estimating time...'
                        :   'About ' + getTimespanString(remainingTime) + ' left...'}
                    </Typography>
                </Stack>
                <LinearProgress
                    {...(progress !== null ?
                        {
                            variant: 'determinate',
                            value: progress,
                        }
                    :   { variant: 'indeterminate' })}
                    sx={{
                        width: '100%',
                        height: '4px',
                        borderRadius: '2px',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: Color.Purple,
                        },
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        backgroundColor: 'red',
                    }}
                />
                {onCancel && (
                    <Button disabled={cancelDisabled} variant="irdbText" onClick={() => onCancel()}>
                        Cancel
                        <i className="fa-light fa-x" style={{ marginLeft: 4 }} />
                    </Button>
                )}
            </Stack>
        </Card>
    );
};

const selectors = {
    isCancelProgressEnabled: (state: BulkUploaderStore) =>
        state.isCanceling &&
        state.addedIrcodes.length + state.unavailableIrcodes.length !== state.imageOperationsCount &&
        !state.hasUploading,
    isUploading: (state: BulkUploaderStore) =>
        !!state.files?.length && !state.hasPending && !state.hasAvailable && !state.hasUnavailable,
    showTopProgressBar: (state: BulkUploaderStore) =>
        selectors.isCancelProgressEnabled(state) || selectors.isUploading(state),
    showBottomProgressBar: (state: BulkUploaderStore) =>
        !state.isCanceling && state.hasImageOperations && state.processingCount > 0,
} as const;

const UploaderProgressBar = memo(function UploaderProgressBar() {
    const { darkMode } = useContext(ThemeContext)!;
    const storeApi = useBulkUploaderState();
    const useSelectors = storeApi.use;
    const hasPending = useSelectors.hasPending();
    const hasAdding = useSelectors.hasAdding();
    const hasUploading = useSelectors.hasUploading();
    const handleCancel = useSelectors.handleCancel();
    const isCancelProgressEnabled = useSelectors(selectors.isCancelProgressEnabled);
    const showTopProgressBar = useSelectors(selectors.showTopProgressBar);
    const showBottomProgressBar = useSelectors(selectors.showBottomProgressBar);
    return (
        <>
            {showTopProgressBar && (
                <ProgressBar
                    hideCounts
                    label={isCancelProgressEnabled ? 'Canceling' : 'Uploading'}
                    darkMode={darkMode}
                />
            )}
            {showBottomProgressBar && (
                <>
                    {hasPending && <ProgressBar label="Querying" darkMode={darkMode} onCancel={handleCancel} />}
                    {hasAdding && (
                        <ProgressBar
                            label="Saving"
                            darkMode={darkMode}
                            onCancel={handleCancel}
                            cancelDisabled={hasUploading}
                        />
                    )}
                </>
            )}
        </>
    );
});

export default UploaderProgressBar;
