import React, { createContext, FC } from 'react';
import useNotify, { NotifyCallback, NotifyDialog } from '../hooks/feedback/useNotify';
import useConfirm, { ConfirmCallback, ConfirmDialog } from '../hooks/feedback/useConfirm';
import { useSetShowLoading, LoadingDialog } from '../hooks/feedback/useLoading';
import useStatusMessages, { StatusMessagesSnackbar } from '../hooks/feedback/useStatusMessages';

export type TFeedback = {
    notify: NotifyCallback;
    confirm: ConfirmCallback;
    setShowLoading: (showLoading: boolean) => void;
    setSuccessMessage: (message?: string) => void;
    setErrorMessage: (message?: string) => void;
};

const FeedbackContext = createContext<TFeedback | undefined>(undefined);

interface Props {
    children: React.ReactNode;
}

export const FeedbackProvider: FC<Props> = ({ children }) => {
    const notify = useNotify();
    const confirm = useConfirm();
    const setShowLoading = useSetShowLoading();
    const { setSuccessMessage, setErrorMessage } = useStatusMessages();

    return (
        <FeedbackContext.Provider
            value={{
                notify,
                confirm,
                setShowLoading,
                setSuccessMessage,
                setErrorMessage,
            }}
        >
            {children}
            <LoadingDialog />
            <NotifyDialog />
            <ConfirmDialog />
            <StatusMessagesSnackbar />
        </FeedbackContext.Provider>
    );
};

export default FeedbackContext;
