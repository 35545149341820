import { Image } from '../../contexts/MediaContext';
import Typography from '@mui/material/Typography';
import { Box, Stack, Skeleton } from '@mui/material';

interface Props {
    isSimilarImagesLoading: boolean | undefined;
    similarImages: Image[];
    setImage: (image: Image) => void;
}

export default function SimilarImages({ isSimilarImagesLoading, similarImages, setImage }: Props) {
    if (!isSimilarImagesLoading && !similarImages.length) return null;

    return (
        <Stack direction="column" spacing={1}>
            <Typography
                sx={{
                    fontFamily: 'Nunito Sans',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    letterSpacing: '0.09375rem',
                    px: 3,
                }}
            >
                Similar Images
            </Typography>
            {isSimilarImagesLoading ?
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        overflow: 'scroll',
                        pl: 3,
                        pr: 2,
                        pb: 2,
                    }}
                >
                    {[1, 2, 3, 4, 5].map(index => {
                        return (
                            <Skeleton
                                key={index}
                                animation="wave"
                                sx={{
                                    display: 'flex',
                                    flexShrink: 0,
                                    width: 128,
                                    height: 128,
                                    borderRadius: 1,
                                    objectFit: 'cover',
                                    mr: 1,
                                }}
                                height={128}
                                width={128}
                            />
                        );
                    })}
                </Stack>
            :   <Stack
                    direction="row"
                    sx={{
                        overflow: 'scroll',
                        pl: 3,
                        pr: 2,
                        pb: 2,
                    }}
                >
                    {[...similarImages].map(similarImage => {
                        return (
                            <Box
                                key={similarImage.imageID}
                                sx={{
                                    width: 128,
                                    mr: 1,
                                }}
                                onClick={() => {
                                    document.getElementById('InfoBox')?.scrollTo(0, 0);
                                    setImage(similarImage);
                                }}
                            >
                                <Box
                                    component="img"
                                    src={similarImage.imageUrl}
                                    alt={similarImage.metaContent?.title || 'No title added'}
                                    sx={{
                                        borderRadius: 1,
                                        width: 128,
                                        height: 128,
                                        objectFit: 'cover',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '.7rem',
                                        fontWeight: 400,
                                        maxHeight: '2.8em',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {similarImage.metaContent?.title || <i style={{ opacity: 0.5 }}>No title added</i>}
                                </Typography>
                            </Box>
                        );
                    })}
                </Stack>
            }
        </Stack>
    );
}
