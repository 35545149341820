import useBulkUploaderState from 'src/stores/useBulkUploaderState';
import { Button } from '@mui/material';
import { BulkUploaderStore } from 'src/stores/BulkUploader/store';

const isAlternateCancelButtonSelector = (state: BulkUploaderStore) =>
    !state.hasAvailable && (state.hasAdded || state.hasUnavailable) && !state.hasUploading;

const UploaderCancelButton = () => {
    const useSelectors = useBulkUploaderState().use;
    const hasImageOperations = useSelectors.hasImageOperations();
    const hasUploading = useSelectors.hasUploading();
    const handleCancel = useSelectors.handleCancel();
    const isAlternateCancelButton = useSelectors(isAlternateCancelButtonSelector);
    if (!hasImageOperations) {
        return null;
    }
    const cancelButtonText = isAlternateCancelButton ? 'Return to My IRCODES' : 'Cancel';

    return (
        <Button disabled={hasUploading} variant="irdbText" onClick={() => handleCancel(true)}>
            {cancelButtonText}
            <i className="fa-light fa-x" style={{ marginLeft: 4 }}></i>
        </Button>
    );
};

export default UploaderCancelButton;
