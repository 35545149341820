import React from 'react';
import { BoxProps, Stack } from '@mui/material';
import ProfileEmailField from './ProfileEmailField';
import StatefulProfileTextField from './StatefulProfileTextField';

export interface ProfileInfoFormProps extends BoxProps<'form'> {
    mobileView?: boolean;
}

export default function ProfileInfoForm({ mobileView, ...props }: ProfileInfoFormProps) {
    return (
        <Stack component="form" rowGap={1} flexGrow={1} {...props}>
            <StatefulProfileTextField mobileView={mobileView} stateKey="name" label="Name" />
            <StatefulProfileTextField mobileView={mobileView} stateKey="userName" label="Username" />
            <ProfileEmailField mobileView={mobileView} type="email" label="Email" />
            <StatefulProfileTextField mobileView={mobileView} stateKey="phoneNumber" type="tel" label="Phone Number" />
        </Stack>
    );
}
