export interface IProductLink {
    id: string;
    imageUrl: string;
    title: string;
    linkToFollow: string;

    // Temporary, for upload only
    upload?: {
        file: File;
        preview: string;
    };
}

function isIProductLink(maybeProductLink: any): maybeProductLink is IProductLink {
    if (maybeProductLink === undefined || !maybeProductLink) {
        return false;
    }

    // TODO: Maybe a build time list creation
    if (
        !['imageUrl', 'title', 'linkToFollow'].every(key => {
            return maybeProductLink.hasOwnProperty(key);
        })
    ) {
        return false;
    }

    return true;
}

export type TProductLink = {
    links: IProductLink[];
};

export function isTProductLink(maybeProductLink: any | undefined): maybeProductLink is TProductLink {
    if (maybeProductLink === undefined) {
        return false;
    }

    if (
        !['links'].every(key => {
            return maybeProductLink.hasOwnProperty(key);
        })
    ) {
        return false;
    }

    for (const link of maybeProductLink.links) {
        if (!isIProductLink(link)) {
            return false;
        }
    }

    return true;
}

export function createProductLink({ id, ...link }: Partial<IProductLink> = {}, reuseId?: boolean): IProductLink {
    return {
        id: !reuseId || !id ? crypto.randomUUID() : id,
        imageUrl: '',
        title: '',
        linkToFollow: '',
        ...link,
    };
}
